import { Box, Button, OutlinedInput, Stack, Typography } from "@mui/material";
import { useState } from "react";

export const RewriteClientDescriptionModal = ({promptMessage, setPromptMessage, modalHandler, rewriteDescription}) => {

    const [expand, setExpand] = useState(false);

    const expandHandler = () => setExpand(!expand);

    return(
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            gap={'1.6rem'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Typography variant={'h3'} textAlign={'center'}>Rewrite Description</Typography>
            <Stack
                width={1}
                direction={'row'}
                spacing={'.8rem'}>
                <Button fullWidth variant={'primaryMain'} onClick={() => rewriteDescription()}>Rewrite for me</Button>
                <Button fullWidth variant={'secondaryMain'} onClick={expandHandler}>Rewrite Something Specific</Button>
            </Stack>
            <Stack
                mt={'2.4rem'}
                display={expand ? 'flex' : 'none'}
                direction={'column'}
                spacing={'1.2rem'}
                width={1}
                >
                <Typography variant={'s3'}>Write Something Specific</Typography>
                <OutlinedInput
                    multiline
                    rows={5}
                    value={promptMessage}
                    onChange={(event) => setPromptMessage(event.target.value)}
                    placeholder={'Example: Expand and add more about the process of seeding the farm.'} />
                <Button variant={'primaryMain'} onClick={() => rewriteDescription()}>
                    Execute
                </Button>
            </Stack>
            <Button fullWidth variant={'secondaryMain'} onClick={() => modalHandler()}>
                Cancel
            </Button>
        </Box>
    )
}