// GET APIs
export const GET_NAICS_CODES_API = `/util/naics-codes`;
export const GET_ALL_PROPOSALS_API = `/proposals/get-all-proposals?company_id=`;
export const GET_PROPOSAL_DETAIL_API = `/proposals/view-proposal-details?proposal_id=`;
export const GET_CLIENT_LIST_API = `/clients/get-clients?company_id=`;
export const GET_COMPANY_DETAIL_API = `/companies/view-company-details?company_id=`;
export const GET_REGENERATE_RESPONSE_AI_API = `${process.env.REACT_APP_REGENERATE_URL}/?task_id=`;
export const GET_RELEVANT_CAPABILITIES_LIST_API = `/companies/get-all-capabilities?company_id=`;
export const GET_CLIENT_COMPANY_LIST_API = `/companies/get-all-companies?account_sub=`;

// POST APIs
export const POST_ADD_COMPANY_API = `/companies/add-company`;
export const POST_CREATE_FULL_PROPOSAL_API = process.env.REACT_APP_PROPOSAL_URL;
export const POST_DELETE_PROPOSAL_API = `/proposals/del-proposal`;
export const POST_UPDATE_PROPOSAL_API = `${process.env.REACT_APP_SERVER_URL}/proposals/update-proposal`;
export const POST_UPDATE_PROPOSAL_NAME_API = `${process.env.REACT_APP_SERVER_URL}/proposals/update-proposal-name`;
export const POST_UPDATE_COMPANY_DATA_API = `/companies/update-company`;
export const POST_UPDATE_TASK_API = `/proposals/update-task`;
export const POST_ADD_SHREDDED_PROPOSAL = `/proposals/add-shredded-proposal`;
export const SHRED_DOCUMENT_API = `/shred/shred-document`;
export const SHRED_RFI_DOCUMENT_API = `/shred/shred-rfi-document`;
export const POST_DELETE_CLIENT_API = '/clients/del-client';
export const POST_UPDATE_CLIENT_API = '/clients/update-client';
export const POST_GENERATE_CLIENT_DESCRIPTION_API =
  '/clients/generate-client-description';
export const POST_REGENERATE_CLIENT_DESCRIPTION_API =
  '/clients/regenerate-client-description';
export const POST_ADD_NEW_CLIENT_API = '/clients/add-client';
