//REACT & MUI IMPORTS
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, IconButton, Modal, Pagination, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//COMPONENT IMPORTS
import { useUserStore } from '../../store/userStore';
import { API } from 'aws-amplify';
import { PastPerformanceListItem } from './components/PastPerformanceListItem';
import { GET_CLIENT_LIST_API } from '../../const';
import { CreateClientModal } from '../../components/modals/CreateClientModal';

export const MyPastPerformances = () => {
  const navigate = useNavigate();
  const [pastPerformancesList, setPastPerformancesList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const company = useUserStore(state => state.company);

  //FETCH CLIENTS
  const fetchPastPerformances = async () => {
    setLoading(true);
    let response = await API.get('pyBackend', `${GET_CLIENT_LIST_API}${company.company_id}`, {});
    setPastPerformancesList(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchPastPerformances()
  }, [company])

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;
  const totalPages = Math.ceil(pastPerformancesList.length / clientsPerPage);

  const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  //CREATE ENTRY
  const [createEntryModal, setCreateEntryModal] = useState(false);
  const CreateEntryModalHandler = () => {
    fetchPastPerformances()
    setCreateEntryModal(!createEntryModal);
  }

  return (
    <Box
      width={1}
      maxWidth={'127rem'}
      mt={{xs: 0, md: '-4rem'}}
      mb={totalPages > 1 ? 0 : '7.2rem'}
      px={{xs: '1.6rem', md: '4.8rem'}}
      display={'flex'}
      flexDirection={'column'}
      gap={'4.8rem'}>
      <Modal open={createEntryModal} onClose={CreateEntryModalHandler}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{xs: '1.6rem', md: 0}}>
          {!isLoading && (
            <CreateClientModal
              modalHandler={CreateEntryModalHandler}
              companyId={company.company_id}
              companyName={company.name}
              setPastPerformancesList={setPastPerformancesList}
            />
          )}
        </Box>
      </Modal>
      <Typography
        color={'common.black'}
        textAlign={{xs: 'center', md: 'left'}}
        variant={'h3'}>
        My Past Performances
      </Typography>
      <Button
        variant={'primaryThin'}
        onClick={() => navigate('/users/new-proposal')}
        sx={{
          display: {xs: 'block', md: 'none'},
        }}>
        Create proposal
      </Button>
      <Box
        width={1}>
        <Stack
          display={{xs: 'none', md: 'flex'}}
          width={1}
          padding={'1.2rem'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Box
            width={'70%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}>
            <Typography
              textAlign={'left'}
              color={'common.black'}
              variant={'s2'}>
              Name
            </Typography>
          </Box>
          <Box
            width={'30%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Button
              fullWidth
              variant={'primaryNavbar'}
              onClick={CreateEntryModalHandler}>
                Create Entry
            </Button>
          </Box>
        </Stack>
        <Divider flexItem sx={{display: {xs: 'none', md: 'flex'}}} />
        {isLoading && (<Box width={1} display={'flex'} justifyContent={'center'}><CircularProgress /></Box>)};

        <Box
          width={1}
          display={'flex'}
          flexDirection={'column'}
          gap={'1.2rem'}
          py={{xs: 0, md: '2.4rem'}}>
          {pastPerformancesList.length &&
            pastPerformancesList.slice((currentPage - 1) * clientsPerPage, (currentPage - 1) * clientsPerPage + clientsPerPage).map((client, index) => {
              return <PastPerformanceListItem
                key={index}
                companyId={company.company_id}
                clientId={client.client_id}
                clientName={client.client_name}
                roleOnContract={client.role_on_contract}
                aiSummarization={client.ai_summarization}
                contractName={client.contract_name}
                skillsUsed={client.skills_used}
                metricsUsed={client.metrics_used}
                setPastPerformancesList={setPastPerformancesList}
                refresh={fetchPastPerformances}
                />
            })}
        </Box>
      </Box>

      <Box
        width={1}
        display={totalPages > 1 ? 'flex' : 'none'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1.2rem'}
        p={'1.6rem'}
        mb={'5rem'}>
        <IconButton
          disabled={currentPage === 1 ? true : false}
          onClick={prevButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main',
          }}>
          <ArrowBackIosNewRounded />
        </IconButton>
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'small'}
          sx={{display: {xs: 'flex', md: 'none'}}}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'large'}
          sx={{display: {xs: 'none', md: 'flex'}}}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <IconButton
          disabled={currentPage === totalPages ? true : false}
          onClick={nextButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
          }}>
          <ArrowForwardIosRounded />
        </IconButton>
      </Box>
    </Box>
  );
};
