import { Box } from "@mui/system"
import { useEffect } from "react"
import { Outlet, useMatch, useNavigate } from "react-router-dom"
import { NBOnlyLogo } from "../components/navbar/NBOnlyLogo"

export const LegalLayout = () => {

    const rootDir = !!useMatch('/legal');
    const navigate = useNavigate();

    useEffect(() => {
        rootDir && navigate('/legal/terms-of-service')
    }, [navigate, rootDir])

    return(
        <>
            <Box
                width={'100vw'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                gap={{xs: '2.2rem', md: '8rem'}}>
                <NBOnlyLogo />
                <Outlet />
            </Box>
        </>
    )
}