//REACT IMPORTS
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {API} from 'aws-amplify';

import {ReactComponent as CloseIcon} from '../../assets/icons/close-icon.svg';
import {LoadingButton} from '@mui/lab';

//API IMPORTS

export const EditProposalCountModal = ({
                                           fetchData,
                                           setProposalsModal,
                                           companyId,
                                           proposalsLeft,
                                           regenerationsLeft
                                       }) => {

    const [newProposalsLeft, setNewProposalsLeft] = useState(proposalsLeft);
    const [newRegenerationsLeft, setNewRegenerationsLeft] = useState(regenerationsLeft);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useState(() => {
        setNewProposalsLeft(proposalsLeft)
        setNewRegenerationsLeft(regenerationsLeft)
    }, [companyId])

    const updateProposals = async () => {
        setIsLoading(true)
        try {
            await API.post('api2e29c07a', `/admin/companies/${companyId}/proposals`, {
                body: {
                    company_id: companyId,
                    proposals_left: newProposalsLeft,
                    rewrites_left: newRegenerationsLeft,
                },
            });
            fetchData()
            setProposalsModal(false)
            setShowErrorMessage(false)
        } catch {
            setShowErrorMessage(true)
        } finally {
            setIsLoading(false)
        }
    };


    return (
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            overflow={'hidden'}
            maxHeight={'90vh'}
            sx={{
                overflowY: 'scroll'
            }}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon/>}
                    onClick={() => setProposalsModal(false)}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Stack
                    width={1}
                    spacing={'.8rem'}>
                    <Typography variant={'s3'}>
                        Proposals
                    </Typography>
                    <TextField
                        value={newProposalsLeft}
                        onChange={(event) => {
                            setNewProposalsLeft(event.target.value);
                        }}
                        id='outlined-number'
                        type='number'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Stack>
                <Stack
                    width={1}
                    spacing={'.8rem'}>
                    <Typography variant={'s3'}>
                        Regenerations
                    </Typography>
                    <TextField
                        value={newRegenerationsLeft}
                        onChange={(event) => {
                            setNewRegenerationsLeft(event.target.value);
                        }}
                        id='outlined-number'
                        type='number'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Stack>
                {showErrorMessage && <Stack><Typography variant={'s3'} sx={{color: 'red'}}>An error occurred while updating your data</Typography> </Stack>}
                <Stack spacing={'.8rem'} width={1}>
                    <Stack
                        width={1}
                        spacing={'1.6rem'}
                        direction={{xs: 'column-reverse', md: 'row'}}>
                        <Button
                            fullWidth
                            variant={'secondaryMain'}
                            onClick={() => setProposalsModal(false)}>
                            Cancel
                        </Button>
                        <LoadingButton
                            fullWidth
                            variant={'primaryMain'}
                            onClick={updateProposals}
                            loading={isLoading}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}
