import { Box, Typography } from '@mui/material';
import { ShredDocumentsCard } from '../../../components/cards/ShredDocumentsCard';

export const ShredDocumentsStepOne = ({props}) => {
  return (
    <Box
      width={1}
      spacing={'4.8rem'}>
      <Typography variant={'h2'} textAlign={'center'} sx={{mb: '4.8rem'}}>Shred Documents</Typography>
      <ShredDocumentsCard props={props} />
    </Box>
  );
};
