import {Box, Menu, MenuItem, Modal, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {saveAs} from 'file-saver';
import {Packer} from 'docx';

import {ReactComponent as MoreIcon} from '../../../assets/icons/more-icon.svg';
import {DeleteProposalModal} from '../../../components/modals/DeleteProposalModal';
import {proposalDocGenerator} from '../../../utils/proposalDocGenerator';
import {GET_PROPOSAL_DETAIL_API} from '../../../const';
import {API} from 'aws-amplify';

export const ProposalListItem = ({
                                     proposalName,
                                     proposalType,
                                     proposalOwner,
                                     creationDate,
                                     proposalStatus,
                                     proposalId,
                                     fetchProposals,
                                     shredDocument
                                 }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    //const [loading, setLoading] = useState(false);
    const [proposalData, setProposalData] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setDeleteModal(false)
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteModalHandler = async (reload) => {
        if (reload) {
            await fetchProposals()
        }
        handleClose();
    };

    const handleDetails = () => {
        proposalStatus === 'Completed' && navigate(`/users/proposal-detail/${proposalId}`);
    };

    const fetchProposalData = async (id) => {
        //setLoading(true);

        if (!proposalData) {
            try {
                let response = await API.get('pyBackend', `${GET_PROPOSAL_DETAIL_API}${id}`, {});
                await setProposalData(response);
                return response;
            } catch (error) {
                console.log(error);
            }
        }

        //setLoading(false);
        return proposalData;
    };

    const exportAsDocx = async () => {
        const data = await fetchProposalData(proposalId);

        let name = proposalName.replaceAll(' ', '_').replace(/[^a-zA-Z0-9]/g, '');
        const doc = proposalDocGenerator({proposal: {proposalName, proposalOwner}, proposalDetails: data});

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `${name}.docx`);
        });
        handleClose();
    };

    const buttonContext = () => {
        if (shredDocument.shreded_status === 'Completed' && shredDocument.proposal_status === 'Draft') {
            return (
                <MenuItem onClick={handleClose}>
                    <Typography
                        sx={{
                            width: 1,
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(`/users/create-proposal-from-doc/${shredDocument.document_id}`)}
                        color={'common.black'}
                        variant={'body'}>
                        Write Proposal
                    </Typography>
                </MenuItem>
            );
        } else {
            return (<>
                <MenuItem onClick={handleClose}>
                    <Typography
                        sx={{
                            width: 1,
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(`/users/proposal-detail/${proposalId}`)}
                        color={'common.black'}
                        variant={'body'}>
                        Edit
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography
                        sx={{
                            width: 1,
                            cursor: 'pointer',
                        }}
                        onClick={exportAsDocx}
                        color={'common.black'}
                        variant={'body'}>
                        Export as Docx
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography
                        sx={{
                            width: 1,
                            cursor: 'pointer',
                        }}
                        onClick={deleteModalHandler}
                        color={'common.black'}
                        variant={'body'}>
                        Delete
                    </Typography>
                </MenuItem>
            </>)
        }
    }

    return (
        <Stack
            width={1}
            height={{xs: '8rem', md: '4.8rem'}}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            border={{xs: '.1rem solid #F4F4F4', md: 'none'}}
            borderRadius={'.8rem'}
        >
            <Modal open={deleteModal} onClose={handleClose}>
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    px={{xs: '1.6rem', md: 0}}>
                    <DeleteProposalModal
                        modalHandler={deleteModalHandler}
                        proposalId={proposalId}
                    />
                </Box>
            </Modal>

            <Box
                width={'15%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'left'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {proposalOwner ? proposalOwner : 'Proposal Owner'}
                </Typography>
            </Box>
            <Box
                width={'15%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {shredDocument.shreded_status === 'Error' || shredDocument.proposal_status === 'Error' ? <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={'Red'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    Error
                </Typography> : <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={proposalStatus === 'Completed' ? 'common.success' : proposalStatus === 'In Progress' && 'primary.main'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {proposalStatus ? proposalStatus : 'Pending'}
                </Typography>}
            </Box>
            <Box
                width={{xs: '18rem', md: '30%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                overflow={'hidden'}
                sx={{
                    cursor: proposalStatus === 'Completed' ? 'pointer' : 'default',
                    '&:hover': {textDecoration: proposalStatus === 'Completed' ? 'underline' : 'none'}
                }}
                onClick={handleDetails}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {proposalName ? proposalName : 'Proposal Name'}
                </Typography>
            </Box>
            <Box
                width={'10%'}
                height={1}
                display={{xs: 'none', md: 'flex'}}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}>
                    {proposalType ? proposalType : 'RFP'}
                </Typography>
            </Box>
            <Box
                width={{xs: '8.4rem', md: '20%'}}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={{xs: 'flex-end', md: 'center'}}
                justifyContent={'center'}>
                <Typography
                    display={{xs: 'flex', md: 'none'}}
                    px={'1.6rem'}
                    color={'grey.main'}
                    variant={'s4'}>
                    Created
                </Typography>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={{xs: 'right', md: 'center'}}
                    color={'common.black'}
                    sx={{
                        typography: {xs: 'bodySmall', md: 'body'},
                    }}>
                    {creationDate ? creationDate : '11/11/2011'}
                </Typography>
            </Box>
            <Box
                width={{xs: '4.4rem', md: '10%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                {shredDocument.shreded_status === 'Completed' && (shredDocument.proposal_status === 'Completed' || shredDocument.proposal_status === 'Draft') &&
                    <Box
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={handleClick}>
                        <MoreIcon/>
                    </Box>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {buttonContext()}
            </Menu>
        </Stack>
    );
};
