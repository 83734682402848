//REACT IMPORTS
import { Box, Button, Stack, Typography } from '@mui/material';

//STYLE IMPORT
import '../../assets/MainStyles.css';

export const FileInput = ({files, setFiles, placeholder, onClick}) => {
  const removeFile = (index) => setFiles(files.filter((el, i) => i !== index));

  const openDialog = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = '.pdf';

    input.onchange = () => {
      const files = input.files;
      const filesArray = Array.from(files);
      // extend files state, append the filesArray
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }

    input.click();
  }

  // TODO: add cursor pointer to the whole box - did it
  // TODO: do not open the dialog when clicking on the remove button on the file level - did it
  return (
    <Box
      width={1}
      borderColor={'grey.grey3'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      gap={'.8rem'}
      flexWrap={'wrap'}
    >
      <Button
        fullWidth
        variant={'primaryMain'}
        onClick={openDialog}>
        {placeholder}
      </Button>

      {files && files.map((file, index) => (
        <Stack
          key={index}
          direction={'row'}
          padding={'1.2rem 1.5rem'}
          bgcolor={'grey.grey1'}
          border={'.1rem solid'}
          borderColor={'grey.subtle'}
          borderRadius={'.8rem'}
          spacing={'1.4rem'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Typography variant={'bodySmall'}>{file.name}</Typography>
          <Typography
            fontSize={'2.4rem'}
            lineHeight={'2rem'}
            sx={{cursor: 'pointer'}}
            onClick={() => removeFile(index)}>
            &times;
          </Typography>
        </Stack>
      ))}

      {/* {!files.length && (
        <Typography
          color={'grey.grey3'}
          variant={'bodySmall'}>
          {placeholder}
        </Typography>
      )} */}
    </Box>
  );
};
