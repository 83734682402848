//REACT IMPORTS
import { Box, Popper, Stack, TextField, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { FieldHelper } from '../../../components/helpers/FieldHelper';
import { TagsInput } from '../../../components/input/TagsInput';

export const SettingsCode = ({
                               naicsCodes,
                               primaryNaicsCode,
                               secondaryNaicsCodes,
                               setPrimaryNaicsCode,
                               setSecondaryNaicsCodes
                             }) => {

  // const handleChange = (event) => {
  //     const { target: { value } } = event;
  //     setSecondaryNaicsCodes(typeof value === 'string' ? value.split(',') : value);
  // };

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      fontSize: '1.6rem',
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0
      }
    }
  });

  return (
    <Box
      width={1}
      maxWidth={'45rem'}
      display={'flex'}
      flexDirection={'column'}
      gap={'2.4rem'}>

      <Stack
        width={1}
        direction={'column'}
        spacing={'1.2rem'}
        justifyContent={'start'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Primary NAICS Code </Typography>
          <FieldHelper
            suggestion={'Primary NAICS you do business under'} />
        </Stack>

        <Autocomplete
          options={naicsCodes}
          getOptionLabel={(option) => option}
          value={primaryNaicsCode}
          PopperComponent={StyledPopper}
          onChange={(event, newValue) => setPrimaryNaicsCode(newValue)}
          renderInput={(params) => (
            <TextField variant={'outlined'} fullWidth {...params} />
          )}
        />
      </Stack>

      <Stack
        width={1}
        mt={'2.4rem'}
        direction={'column'}
        spacing={'1.2rem'}
        justifyContent={'start'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Secondary NAICS Code </Typography>
          <FieldHelper
            suggestion={'Secondary NAICS you do business under'} />
        </Stack>

        <Autocomplete
          multiple
          options={naicsCodes}
          getOptionLabel={(option) => option}
          value={secondaryNaicsCodes}
          PopperComponent={StyledPopper}
          sx={{ '& .MuiAutocomplete-tag': { fontSize: '1.6rem' } }}
          onChange={(event, newValue) => {
            if (newValue.length > 5) {
              return;
            }
            setSecondaryNaicsCodes(newValue)
          }}
          renderInput={(params) => (
            <TextField variant={'outlined'} fullWidth multiline {...params} />
          )}
        />

        <Typography color={'grey.grey3'} variant={'s4'}>
          {'Max 5 codes'}
        </Typography>
      </Stack>
    </Box>
  );
};
