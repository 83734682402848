import { Box, Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';

import { ProposalFromDocStepOne } from './components/ProposalFromDocStepOne';
import { ProposalFromDocStepThree } from './components/ProposalFromDocStepThree';
import { ProposalFromDocStepTwo } from './components/ProposalFromDocStepTwo';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { GET_CLIENT_LIST_API, POST_ADD_SHREDDED_PROPOSAL } from '../../const';
import { useUserStore } from '../../store/userStore';
import SpinningLoader from '../../components/helpers/SpinningLoader';
import { LoadingButton } from '@mui/lab';

const stepLabels = ['Selection', 'Custom Task', 'Confirm Inputs'];

export const CreateProposalFromDoc = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const company = useUserStore(state => state.company);

  const [step, setStep] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [proposalTasks, setProposalTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState({});
  const [clientList, setClientList] = useState([]);
  const [info, setInfo] = useState({});
  const [loadingClientList, setLoadingClientList] = useState(true);
  const [loadingShredDetails, setLoadingShredDetails] = useState(true);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const NextButton = async () => {
    // step < stepLabels.length && setStep(step + 1);
    if (step + 1 < stepLabels.length) {
      setStep(step + 1);
    } else {
      await generateProposal();
    }
  };

  const BackButton = () => {
    step > 0 && setStep(step - 1);
  };

  const fetchShredDetails = async () => {
    setLoadingShredDetails(true);
    try {
      const info = await API.get('pyBackend', `/shred/view-shred-document?shreded_doc_id=${id}`, {});
      const newTasks = info.subtitles_text.subtitles.map((item, index) => ({
        text: info.subtitles_text.text[index],
        subtitle: item,
        subsubtitle: info.subtitles_text.subsubtitles[index],
        index: index,
      }));
      setTasks(newTasks);
      setInfo(info);
    } catch (e) {
      console.log(e);
    }
    setLoadingShredDetails(false);
  };

  useEffect(() => {
    if (id) {
      fetchShredDetails();
    }
  }, [id]);

  const fetchClientList = async () => {
    setLoadingClientList(true);
    try {
      let response = await API.get('pyBackend', `${GET_CLIENT_LIST_API}${company.company_id}`);
      setClientList(response);
    } catch (error) {
      console.log(error);
    }
    setLoadingClientList(false);
  };

  useState(() => {
    if (company) {
      fetchClientList();
    }
  }, [company]);

  const getSelectedShreddedTasks = () => {
    console.log(selectedTasks)
    const tasksToReturn = [];

    Object.keys(selectedTasks).filter((taskIndex) => {
      return selectedTasks[taskIndex].checked;
    }).forEach((key) => {
      const client = clientList.find((client) => client.client_id === selectedTasks[key].pastPerformance);

      tasksToReturn.push({
        task_subtittle: tasks[key].subtitle,
        task_text: tasks[key].text,
        client_name: client?.client_name,
        ai_summarization: client?.ai_summarization,
        skills_used: client?.skills_used,
        metrics_used: client?.metrics_used,
      });
    });
    return tasksToReturn;
  };

  const generateProposal = async () => {
    setLoadingGenerate(true);
    try {
      API.post('pyBackend', POST_ADD_SHREDDED_PROPOSAL, {
        body: {
          proposal_id: info.proposal_id,
          shreaded_subtitles_text: getSelectedShreddedTasks(),
          proposal_tasks: proposalTasks,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      navigate('/users/proposal-detail');
    } catch (err) {
      console.log(err);
    }
    setLoadingGenerate(false);
  };

  if (loadingClientList || loadingShredDetails) {
    return (<SpinningLoader />);
  }

  return (
    <Box
      width={1}
      maxWidth={step === 0 ? '120rem' : '70rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={'4.8rem'}>
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          mb: '3.2rem',
          maxWidth: '60rem',
        }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {step === 0 && <ProposalFromDocStepOne
        tasks={tasks}
        clientList={clientList}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks} />}
      {step === 1 && <ProposalFromDocStepTwo clientList={clientList} proposalTasks={proposalTasks}
        setProposalTasks={setProposalTasks} />}
      {step === 2 && <ProposalFromDocStepThree selectedTasks={getSelectedShreddedTasks()} taskList={proposalTasks} step={1} setStep={setStep} />}

      <Stack width={1} spacing={'1.2rem'} alignItems={'center'}>
        <LoadingButton
          fullWidth
          variant={'primaryMain'}
          onClick={NextButton}
          loading={loadingGenerate}
          sx={{
            maxWidth: '70rem',
          }}>
          {step === 0 ? 'Confirm Selection' : step === 1 ? 'Next' : 'Generate Proposal'}
        </LoadingButton>
        <Button
          fullWidth
          variant={'secondaryMain'}
          onClick={BackButton}
          sx={{
            display: step === 0 ? 'none' : 'flex',
            maxWidth: '70rem',
          }}>
          Back
        </Button>
      </Stack>
    </Box>
  );
};
