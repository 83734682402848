import { Box, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { FieldHelper } from "../../../components/helpers/FieldHelper";
import IndustryList from "../../../assets/localData/IndustryList.json";

export const StepOne = ({
                        companyName,
                        setCompanyName,
                        companyPrimaryIndustry,
                        setCompanyPrimaryIndustry,
                        pointOfContact,
                        setPointOfContact,
                        businessSize,
                        setBusinessSize,
                        certifications,
                        setCertifications,
                        //facilityClearance,
                        //setFacilityClearance
                    }) => {

    return(
        <Box
            width={1}
            maxWidth={'50rem'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                direction={'column'}
                textAlign={'center'}
                mb={{xs: '3.2rem', md: '8rem'}}>
                <Typography variant={'body'} color={'grey.main'}>
                    Let’s start with the basics!
                </Typography>
                <Typography color={'common.black'} textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Let’s complete your company’s profile
                </Typography>
            </Stack>

            <Stack
                width={1}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Company Name <Typography color={'common.error'} variant={'s3'}>*</Typography>
                    </Typography>
                    <FieldHelper
                        suggestion={'Input full name of your company'} />
                </Stack>
                <OutlinedInput
                    value={companyName}
                    inputProps={{ maxLength: 32 }}
                    onChange={(event) => setCompanyName(event.target.value)}
                    fullWidth
                    placeholder={'Company Name'} />
            </Stack>

            <Stack
                width={1}
                mt={'2.4rem'}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Company Primary Industry <Typography color={'common.error'} variant={'s3'}>*</Typography>
                    </Typography>
                    <FieldHelper
                        suggestion={'Select the primary industry to sell to'} />
                </Stack>
                <Select
                    value={companyPrimaryIndustry}
                    onChange={(event) => setCompanyPrimaryIndustry(event.target.value)}
                    sx={{textTransform: 'capitalize'}}>
                    <MenuItem value={'placeholder'} disabled>Select</MenuItem>
                    {IndustryList && IndustryList.map((type, index) => (
                        <MenuItem key={index} value={type} sx={{textTransform: 'capitalize'}}>{type}</MenuItem>
                    ))}
                </Select>
            </Stack>

            <Stack
                width={1}
                mt={'2.4rem'}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Point of Contact <Typography color={'common.error'} variant={'s3'}>*</Typography>
                    </Typography>
                    <FieldHelper
                        suggestion={'Name of person completing this information'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={pointOfContact}
                    inputProps={{ maxLength: 32 }}
                    onChange={(event) => setPointOfContact(event.target.value)}
                    placeholder={'John Doe'} />
            </Stack>

            <Stack
                width={1}
                direction={'column'}
                mt={'2.4rem'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Business Set-Aside <Typography color={'common.error'} variant={'s3'}>*</Typography>
                    </Typography>
                    <FieldHelper
                        suggestion={'Provide current your business set-aside status'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={businessSize}
                    inputProps={{ maxLength: 32 }}
                    onChange={(event) => setBusinessSize(event.target.value)}
                    placeholder={'8(a), SB, WOSB, ...'} />
            </Stack>

            <Stack
                width={1}
                direction={'column'}
                mt={'2.4rem'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>  Certifications </Typography>
                    <FieldHelper
                        suggestion={'List all business certification ie ISO, CMMI etc'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={certifications}
                    inputProps={{ maxLength: 64 }}
                    onChange={(event) => setCertifications(event.target.value)}
                    placeholder={'ISO, CMMI, ...'} />
            </Stack>

            {/* <Stack
                width={1}
                mt={'2.4rem'}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Facility Clearance <Typography color={'common.error'} variant={'s3'}>*</Typography>
                    </Typography>
                    <FieldHelper
                        suggestion={'If applicable, select your company’s FCL level'} />
                </Stack>
                <Select
                    value={facilityClearance}
                    onChange={(event) => setFacilityClearance(event.target.value)}
                    sx={{textTransform: 'capitalize'}}>
                    <MenuItem value={'placeholder'} disabled>Select</MenuItem>
                    <MenuItem value={'None'} sx={{textTransform: 'capitalize'}}>{'N/A'}</MenuItem>
                    <MenuItem value={'Secret'} sx={{textTransform: 'capitalize'}}>{'yes secret'}</MenuItem>
                    <MenuItem value={'Top Secret'} sx={{textTransform: 'capitalize'}}>{'yes top secret'}</MenuItem>
                </Select>
            </Stack> */}
        </Box>
    )
}