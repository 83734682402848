import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useUserStore } from '../../store/userStore';

export const ForgotPassword = () => {
  const setLastUsername = useUserStore((state) => state.setLastUsername);
  const lastUsername = useUserStore((state) => state.lastUsername);

  const [username, setUsername] = useState(lastUsername ?? '');
  const [usernameError, setUsernameError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [validEmail, setValidEmail] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(username));
  }, [username])

  const forgotPassword = async () => {
    setError('');
    setUsernameError('');

    if (username === '') {
      setUsernameError('Please enter a username');
      return;
    }

    // username must be email, check with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setUsernameError('Please enter a valid email address');
      return;
    }

    try {
      setLoading(true);
      await Auth.forgotPassword(username);
      setLastUsername(username);
      navigate('/change-password');
    } catch (error) {
      setError(error.message);
      console.log('error sending code to reset password', error);
    }
    setLoading(false);
  };

  return (
    <Box
      maxWidth={'55rem'}
      px={{xs: '2.4rem', md: 0}}
      textAlign={'center'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={{xs: '1.6rem', md: '4.8rem'}}
    >
      <Typography
        color={'common.black'}
        sx={{
          typography: {xs: 'h3', md: 'h1'},
        }}
      >
        Forgot your password?
      </Typography>
      <Typography
        mt={{xs: '1.6rem', md: 0}}
        variant={'bodyLight'}
        color={'grey.main'}>
        Enter the email associated with your account and we’ll send a link to reset your password.
      </Typography>
      <TextField
        variant={'outlined'}
        fullWidth
        placeholder={'Email'}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        error={!!usernameError}
        helperText={usernameError}
      />
      <LoadingButton
        disabled={!validEmail}
        fullWidth
        variant={'primaryMain'}
        sx={{
          maxWidth: '46rem',
        }}
        onClick={forgotPassword}
        loading={loading}
      >
        Recover password
      </LoadingButton>

      <FormHelperText error>
        {error}
      </FormHelperText>
    </Box>
  );
};
