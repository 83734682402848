import create from 'zustand';

const useUserStore = create((set) => ({
  lastUsername: null,
  loggedIn: false,
  company: undefined,
  companyImage: '/placeholder.png',
  user: undefined,
  product: undefined,
  oauthError: undefined,
  group: undefined,
  setLastUsername: (username) => set(() => ({ lastUsername: username })),
  setLoggedIn: (loggedIn) => set(() => ({ loggedIn })),
  setCompany: (company) => set(() => ({ company })),
  setCompanyImage: (companyImage) => set(() => ({ companyImage })),
  setUser: (user) => set(() => ({ user })),
  setProduct: (product) => set(() => ({ product })),
  setOauthError: (oauthError) => set(() => ({ oauthError })),
  setGroup: (group) => set(() => ({ group })),
}));

export { useUserStore };
