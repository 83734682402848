import {Box, Button, Stack, Typography} from '@mui/material';
import {API} from 'aws-amplify';
import {ReactComponent as CloseIcon} from '../../assets/icons/close-icon.svg';
import {useState} from 'react';
import {LoadingButton} from '@mui/lab';

export const DeleteAccountModal = ({fetchData, setDeleteModal, accountId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const deleteUserFunction = async () => {
        try {
            setIsLoading(true)
            await API.del('api2e29c07a', `/admin/accounts/${accountId}`, {});
            fetchData()
            setDeleteModal(false)
            setShowErrorMessage(false)
        } catch {
            setShowErrorMessage(true)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon/>}
                    onClick={() => setDeleteModal(false)}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Typography textAlign={'center'} sx={{typography: {xs: 'h3', md: 'h1'}}}>
                    Are you sure you want to delete this Account?
                </Typography>
                {showErrorMessage && <Stack><Typography variant={'s3'} sx={{color: 'red'}}>An error occurred while trying to remove this account</Typography> </Stack>}
                <Stack
                    width={1}
                    spacing={'1.6rem'}
                    direction={{xs: 'column-reverse', md: 'row'}}>
                    <Button
                        fullWidth
                        variant={'secondaryMain'}
                        onClick={() => setDeleteModal(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        fullWidth
                        variant={'errorButton'}
                        onClick={deleteUserFunction}
                        loading={isLoading}>
                        Delete
                    </LoadingButton>
                </Stack>
            </Box>
        </Box>
    );
};
