//REACT & MUI IMPORTS
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';

//COMPONENT IMPORTS

import { CustomTaskCard } from '../../../components/cards/CustomTaskCard';

export const ProposalFromDocStepTwo = ({clientList, proposalTasks, setProposalTasks}) => {

  const [ taskName, setTaskName ] = useState('');
  const [ taskDescription, setTaskDescription ] = useState('');
  const [ client, setClient ] = useState('placeholder');
  const [ editIndex, setEditIndex ] = useState(undefined);


  const deleteItem = (index) => {
    setProposalTasks(proposalTasks.filter((task, i) => i !== index))
  }

  const editTask = (task, index) => {
    //Filling fields to edit
    setEditIndex(index)
    setTaskName(task.task_name);
    setTaskDescription(task.task_description);
    setClient(task.client_id);

    // scroll to #CustomTaskCard
    document.getElementById('CustomTaskCard').scrollIntoView({behavior: 'smooth'});
  }

  return (
    <Box
      width={1}
      spacing={'4.8rem'}
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography variant={'h2'} textAlign={'center'}>
        Custom Tasks
      </Typography>
        <Box
        width={1}
        mt={'4.8rem'}
        display={'flex'}
        flexDirection={'column'}
        gap={'2.4rem'}>
        {proposalTasks && proposalTasks.map((task, index) => (
            <Stack
                width={1}
                position={'relative'}
                spacing={'1.2rem'}
                borderRadius={'.8rem'}
                bgcolor={'grey.grey1'}
                border={'.1rem solid'}
                borderColor={'grey.subtle'}
                key={index}
                p={'2.4rem'}
                >
                <Stack
                    width={1}
                    spacing={'.8rem'}>
                    <Typography color={'common.black'} variant={'h5'} textTransform={'capitalize'}>{task.task_name}</Typography>
                    <Typography
                        color={'grey.main'}
                        variant={'body'}
                        sx={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2
                        }}>{task.task_description}</Typography>
                </Stack>
                <Stack
                  position={'absolute'}
                  direction={'row'}
                  right={'1.2rem'}
                  top={0}>
                  <IconButton
                      className={'edit-button'}
                      onClick={() => editTask(task, index)}
                      >
                      <EditOutlined fontSize={'large'} />
                  </IconButton>
                  <IconButton
                      className={'delete-button'}
                      onClick={() => deleteItem(index)}
                      >
                      <DeleteOutline fontSize={'large'} />
                  </IconButton>
                </Stack>
            </Stack>
        ))}
    </Box>
      <Typography variant={'h5'} id={'CustomTaskCard'} sx={{my: '4.8rem'}}>Performance and Task Objectives</Typography>
      <CustomTaskCard
        clientList={clientList}
        allTasks={proposalTasks}
        setAllTasks={setProposalTasks}
        client={client}
        setClient={setClient}
        taskName={taskName}
        setTaskName={setTaskName}
        taskDescription={taskDescription}
        setTaskDescription={setTaskDescription}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
      />
    </Box>
  );
};
