import { Box } from "@mui/material"
import { CompanyCard } from "../../../components/cards/CompanyCard"

export const SettingsCompanies = ({companyList, currentCompany}) => {
    return(
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'1.2rem'}>
            {companyList && companyList.map((company, index) => (
                <CompanyCard
                    key={index}
                    companyName={company.company_name}
                    companyId={company.company_id}
                    currentCompany={currentCompany} />
            ))}
        </Box>
    )
}