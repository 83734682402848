//REACT IMPORTS
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { API, Storage } from 'aws-amplify';

//DATA IMPORTS
import {
  GET_CLIENT_COMPANY_LIST_API,
  GET_COMPANY_DETAIL_API,
  GET_NAICS_CODES_API,
  POST_UPDATE_COMPANY_DATA_API
} from '../../const';

//COMPONENT IMPORTS
//import { SettingsClients } from './components/SettingsClients';
import { SettingsCode } from './components/SettingsCode';
import { SettingsCompany } from './components/SettingsCompany';
import { SettingsGeneral } from './components/SettingsGeneral';
import { SettingsKeywords } from './components/SettingsKeywords';
import { useUserStore } from '../../store/userStore';
import { LoadingButton } from '@mui/lab';
import { SettingsCompanies } from './components/SettingsCompanies';
import { useNavigate } from 'react-router-dom';

const tabs = [
  { label: 'General', value: 1 },
  { label: 'Company', value: 2 },
  { label: 'Past Performance', value: 3 },
  { label: 'Shredded Docs', value: 4 },
];

export const AccountSettings = () => {

  const navigate = useNavigate();

  const [allCompanyData, setAllCompanyData] = useState([]);

  //STATES
  const [activeTab, setActiveTab] = useState(1);
  const [naicsCodes, setNaicsCodes] = useState([]);

  //GENERAL TAB
  const [email, setEmail] = useState('');
  const [businessSize, setBusinessSize] = useState('');
  const [certifications, setCertifications] = useState('');
  const [facilityClearance, setFacilityClearance] = useState('placeholder');

  //COMPANY TAB
  const [companyImage, setCompanyImage] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('placeholder');
  const [pointOfContact, setPointOfContact] = useState('');

  //NAICS CODES TAB
  const [primaryNaicsCode, setPrimaryNaicsCode] = useState('placeholder');
  const [secondaryNaicsCodes, setSecondaryNaicsCodes] = useState([]);

  //CAPABILITIES TAB
  const [keywords, setKeywords] = useState([]);

  //CLIENTS TAB
  const [myClients, setMyClients] = useState([]);

  //COMPANIES TAB
  const [companyList, setCompanyList] = useState([]);
  const [accountType, setAccountType] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');

  const [isLoading, setLoading] = useState(false);

  const company = useUserStore(state => state.company);
  const user = useUserStore(state => state.user);
  const storeSetCompanyImage = useUserStore(state => state.setCompanyImage);

  const preloadFields = () => {
    setEmail(allCompanyData.email ?? '');
    setCompanyName(allCompanyData.company_name ?? '');
    setCompanyIndustry(allCompanyData.main_industry ? allCompanyData.main_industry : 'placeholder');
    setPointOfContact(allCompanyData.responsible_name ?? '');
    setKeywords(allCompanyData.keywords ?? []);
    setPrimaryNaicsCode((allCompanyData.primary_naics_id && allCompanyData.primary_naics_desc) ?
      `${allCompanyData.primary_naics_id} - ${allCompanyData.primary_naics_desc}` :
      'placeholder');
    setSecondaryNaicsCodes(allCompanyData.sec_naics ?
      allCompanyData.sec_naics.map(code => `${code.code} - ${code.description}`) :
      []);
    setMyClients(allCompanyData.clients ?? []);
    setBusinessSize(allCompanyData.business_size ? allCompanyData.business_size : '');
    setCertifications(allCompanyData.certifications ? allCompanyData.certifications : '');
    setFacilityClearance(allCompanyData.facility_clearance ? allCompanyData.facility_clearance : 'placeholder');
    setAccountType(allCompanyData.type_account ?? '');
    setCurrentCompany(company?.company_id);
  };

  useEffect(() => {
    preloadFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompanyData]);

  const fetchCompanyData = async () => {
    let response = await API.get('pyBackend', `${GET_COMPANY_DETAIL_API}${company.company_id}`, {});
    setAllCompanyData(response);
  };

  const fetchNaics = async () => {
    let response = await API.get('pyBackend', GET_NAICS_CODES_API, {});
    setNaicsCodes(response);
  };

  const fetchCompanyList = async () => {
    let response = await API.get('pyBackend', `${GET_CLIENT_COMPANY_LIST_API}${user.account_sub}`, {});
    setCompanyList(response);
  };

  useEffect(() => {
    if (!company) return;
    fetchCompanyData();
    fetchNaics();
    fetchCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const updateCompanyData = async () => {
    setLoading(true);
    try {
      const params = {
        company_id: company.company_id,
        account_sub: user.account_sub,
        company_name: companyName,
        main_industry: companyIndustry,
        point_contact: pointOfContact,
        primary_naics: primaryNaicsCode.slice(0, 6),
        secondary_naics: secondaryNaicsCodes.map(code => code.slice(0, 6)),
        keywords: keywords,
        clients: myClients,
        business_size: businessSize,
        certifications: certifications,
        facility_clearance: facilityClearance
      };

      await API.post('pyBackend', POST_UPDATE_COMPANY_DATA_API, { body: params });

      if (companyImage) {
        await Storage.put(`${company.company_id}/profileimage`, companyImage, {
          level: 'private',
          contentType: 'image/png'
        });

        const url = await Storage.get(`${company.company_id}/profileimage`, { level: 'private' });
        storeSetCompanyImage(url);
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    activeTab === 3 && navigate('/users/my-past-performances');
    activeTab === 4 && navigate('/users/my-shredded-documents');
  }, [activeTab]);

  return (
    <Box
      width={1}
      maxWidth={'127rem'}
      px={{ xs: '1.6rem', md: 0 }}>
      <Typography
        color={'common.black'}
        variant={'h1'}
        ml={{ xs: 0, md: '3.6rem' }}
        display={{ xs: 'none', md: 'block' }}>
        My Account
      </Typography>

      <Box width={1} mt={'3.2rem'}>
        <Box
          overflow={'hidden'}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            overflowX: 'scroll'
          }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            variant='scrollable'
            scrollButtons='auto'>
            {tabs && tabs.map((tab, index) => (
              tab.value !== 8
                ? <Tab key={index} label={tab.label} value={tab.value} />
                : accountType === 'company_holding'
                  ? <Tab key={index} label={tab.label} value={tab.value} />
                  : ''
            ))}
          </Tabs>
        </Box>
        <Box
          width={1}
          px={{ xs: 0, md: '3.6rem' }}
          minHeight={'30rem'}
          py={'6.4rem'}>
          {activeTab === 1 &&
            <SettingsGeneral
              email={email}
              allCompanyData={allCompanyData}
              setEmail={setEmail}
              businessSize={businessSize}
              setBusinessSize={setBusinessSize}
              certifications={certifications}
              setCertifications={setCertifications}
              facilityClearance={facilityClearance}
              setFacilityClearance={setFacilityClearance}
            />}
          {activeTab === 2 &&
            <Box display={'flex'} flexDirection={'column'} gap={'2.4rem'}>
              <SettingsCompany
                companyImage={companyImage}
                setCompanyImage={setCompanyImage}
                companyName={companyName}
                setCompanyName={setCompanyName}
                companyIndustry={companyIndustry}
                setCompanyIndustry={setCompanyIndustry}
                pointOfContact={pointOfContact}
                setPointOfContact={setPointOfContact} />
              <SettingsCode
                naicsCodes={naicsCodes}
                primaryNaicsCode={primaryNaicsCode}
                setPrimaryNaicsCode={setPrimaryNaicsCode}
                secondaryNaicsCodes={secondaryNaicsCodes}
                setSecondaryNaicsCodes={setSecondaryNaicsCodes} />
              <SettingsKeywords
                keywords={keywords}
                setKeywords={setKeywords} />
            </Box>
          }
          {/*<SettingsClients*/}
          {/*  myClients={myClients}*/}
          {/*  setMyClients={setMyClients} />*/}
          {/*{activeTab === 6 && navigate('/users/my-past-performance')}*/}
          {activeTab === 6 && <SettingsCompanies companyList={companyList} currentCompany={currentCompany} />}
        </Box>
        {activeTab < 6 && (
          <LoadingButton
            loading={isLoading}
            sx={{ ml: { xs: 0, md: '3.6rem' } }}
            onClick={updateCompanyData}
            variant={'primaryMain'}>
            Save Changes
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
