//REACT IMPORTS
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';

//API IMPORTS
import { GET_NAICS_CODES_API, POST_ADD_COMPANY_API } from '../../const';

//COMPONENT IMPORTS
import { StepFour } from './components/StepFour';
import { StepOne } from './components/StepOne';
import { StepThree } from './components/StepThree';
import { StepTwo } from './components/StepTwo';
import { useUserStore } from '../../store/userStore';
import { StepFive } from './components/StepFive';

const stepLabels = [
  'Company',
  'NAICS',
  'Capabilities',
  'History',
  'Confirm',
  'Plan'
];

export const CompleteProfile = () => {

  const navigate = useNavigate();

  const user = useUserStore(state => state.user);
  const company = useUserStore(state => state.company);
  //const setCompany = useUserStore(state => state.setCompany);

  const [step, setStep] = useState(0);
  const [naicsCodes, setNaicsCodes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchNaicCodes = async () => {
    let response = await API.get('pyBackend', GET_NAICS_CODES_API, {});
    setNaicsCodes(response);
  };

  useEffect(() => {
    if (naicsCodes.length === 0) {
      fetchNaicCodes();
    }
  }, [naicsCodes]);

  useEffect(() => {
    if (company) {
      navigate('/users/create-proposal');
    }
  }, []);

  //Input States
  //STEP 1
  const [companyName, setCompanyName] = useState('');
  const [companyPrimaryIndustry, setCompanyPrimaryIndustry] = useState('placeholder');
  const [pointOfContact, setPointOfContact] = useState('');
  const [businessSize, setBusinessSize] = useState('');
  const [certifications, setCertifications] = useState('');
  //const [facilityClearance, setFacilityClearance] = useState('placeholder');

  //STEP 2
  const [companyPrimaryNaicsCode, setCompanyPrimaryNaicsCode] = useState('');
  const [companySecondaryNaicsCode, setCompanySecondaryNaicsCode] = useState([]);
  const [selectedSecondaryCodes, setSelectedSecondaryCodes] = useState([]);

  //STEP 3
  const [capabilityTags, setCapabilityTags] = useState([]);

  //STEP 4
  const [myClients, setMyClients] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [overviewTags, setOverviewTags] = useState([]);

  //STEPS DONE
  const [stepOneDone, setStepOneDone] = useState(false);
  const [stepTwoDone, setStepTwoDone] = useState(false);
  const [stepThreeDone, setStepThreeDone] = useState(false);
  const [stepFourDone, setStepFourDone] = useState(false);

  useEffect(() => {
    if (
      companyName.length > 0 &&
      companyPrimaryIndustry !== 'placeholder' &&
      pointOfContact.length > 0 &&
      businessSize.length > 0
      //facilityClearance !== 'placeholder'
    ) {
      setStepOneDone(true);
    } else {
      setStepOneDone(false);
    }
  }, [businessSize.length, companyName.length, companyPrimaryIndustry,
    //facilityClearance,
    pointOfContact.length]);

  useEffect(() => {
    if (companyPrimaryNaicsCode !== '') {
      setStepTwoDone(true);
    } else {
      setStepTwoDone(false);
    }
  }, [companyPrimaryNaicsCode]);

  useEffect(() => {
    if (capabilityTags.length > 0) {
      setStepThreeDone(true);
    } else {
      setStepThreeDone(false);
    }
  }, [capabilityTags.length]);

  useEffect(() => {
    if (myClients.length > 0) {
      setStepFourDone(true);
    } else {
      setStepFourDone(false);
    }
  }, [myClients.length]);

  //SENDING DATA
  const cutSecondaryCodes = (code, setCode) => {
    setCode(code.map(code => code.slice(0, 6)));
  };

  useEffect(() => {
    cutSecondaryCodes(companySecondaryNaicsCode, setSelectedSecondaryCodes);
  }, [companySecondaryNaicsCode, companySecondaryNaicsCode.length, selectedSecondaryCodes.length]);


  const sendProfileInfo = async () => {
    setLoading(true);
    try {
      let response = await API.post('pyBackend', POST_ADD_COMPANY_API, {
        body: {
          account_sub: user.account_sub,
          company_name: companyName,
          main_industry: companyPrimaryIndustry,
          point_contact: pointOfContact,
          primary_naics: companyPrimaryNaicsCode.slice(0, 6),
          secondary_naics: selectedSecondaryCodes,
          keywords: capabilityTags,
          business_size: businessSize,
          certifications: certifications,
          //facility_clearance: facilityClearance,
          //facility_clearance: "",
          clients: myClients
        }
      });

      console.log(response);

      await API.post('api2e29c07a', '/company', {
        body: { company_id: response }
      });

      navigate('/users/my-proposals');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const nextButton = () => {
    step < 4 ? setStep(step + 1) : sendProfileInfo();
  };

  const backButton = () => {
    setStep(step - 1);
  };

  return (
    <Box
      width={1}
      mt={{ xs: 0, md: '-6rem' }}
      maxWidth={'65rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Stepper activeStep={step} alternativeLabel sx={{ mb: '3.2rem' }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step === 0 && <StepOne
        companyName={companyName}
        setCompanyName={setCompanyName}
        companyPrimaryIndustry={companyPrimaryIndustry}
        setCompanyPrimaryIndustry={setCompanyPrimaryIndustry}
        pointOfContact={pointOfContact}
        setPointOfContact={setPointOfContact}
        businessSize={businessSize}
        setBusinessSize={setBusinessSize}
        certifications={certifications}
        setCertifications={setCertifications}
        //facilityClearance={facilityClearance}
        //setFacilityClearance={setFacilityClearance}
      />}
      {step === 1 && <StepTwo
        naicsCodes={naicsCodes}
        companyPrimaryNaicsCode={companyPrimaryNaicsCode}
        setCompanyPrimaryNaicsCode={setCompanyPrimaryNaicsCode}
        companySecondaryNaicsCode={companySecondaryNaicsCode}
        setCompanySecondaryNaicsCode={setCompanySecondaryNaicsCode} />}
      {step === 2 && <StepThree
        capabilityTags={capabilityTags}
        setCapabilityTags={setCapabilityTags} />}
      {step === 3 && <StepFour
        myClients={myClients}
        setMyClients={setMyClients}
        metrics={metrics}
        setMetrics={setMetrics}
        overviewTags={overviewTags}
        setOverviewTags={setOverviewTags}
        companyName={companyName}
      />}
      {step === 4 && <StepFive
        setStep={setStep}
        companyName={companyName}
        primaryIndustry={companyPrimaryIndustry}
        pointOfContact={pointOfContact}
        primaryCode={companyPrimaryNaicsCode}
        secondaryCodes={companySecondaryNaicsCode}
        keywords={capabilityTags}
        pastPerformances={myClients}
      />}

      <Stack mt={'4.8rem'} maxWidth={'50rem'} mb={'7.2rem'} width={1} direction={'column'} spacing={'1.2rem'}
        alignItems={'center'}>
        <Typography
          color={'common.error'}
          variant={'s4'}
          display={step === 2 && capabilityTags.length < 1 ? 'flex' : 'none'}>
          at least one capability is required to continue
        </Typography>
        <Typography
          color={'common.error'}
          variant={'s4'}
          display={step === 3 && myClients.length < 1 ? 'flex' : 'none'}>
          at least one client is required to continue
        </Typography>
        <Button
          fullWidth
          variant={'primaryMain'}
          sx={{ display: step === 0 ? 'flex' : 'none' }}
          onClick={nextButton}
          disabled={!stepOneDone ? true : false}>
          Next
        </Button>
        <Button
          fullWidth
          variant={'primaryMain'}
          onClick={nextButton}
          sx={{ display: step === 1 ? 'flex' : 'none' }}
          disabled={!stepTwoDone ? true : false}>
          Next
        </Button>
        <Button
          fullWidth
          variant={'primaryMain'}
          onClick={nextButton}
          sx={{ display: step === 2 ? 'flex' : 'none' }}
          disabled={!stepThreeDone ? true : false}>
          Next
        </Button>
        <Button
          fullWidth
          variant={'primaryMain'}
          onClick={nextButton}
          sx={{ display: step === 3 ? 'flex' : 'none' }}
          //disabled={!stepThreeDone ? true : false}
        >
          Next
        </Button>
        <LoadingButton
          fullWidth
          loading={isLoading}
          disabled={!stepFourDone ? true : false}
          onClick={nextButton}
          sx={{ display: step === 4 ? 'flex' : 'none' }}
          variant={'primaryMain'}>
          {'Create Company'}
        </LoadingButton>
        <Button
          fullWidth
          onClick={backButton}
          sx={{ display: step === 0 ? 'none' : 'block' }}
          variant={'secondaryMain'}>
          {'Back'}
        </Button>
      </Stack>
    </Box>
  );
};
