import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useUserStore } from '../../store/userStore';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const EmailConfirmation = () => {
  const navigate = useNavigate();
  const lastUsername = useUserStore((state) => state.lastUsername);
  const setLastUsername = useUserStore((state) => state.setLastUsername);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const search = useLocation().search;

  const confirmUser = async () => {
    if (loading) {
      return;
    }
    const email = new URLSearchParams(search).get('email');
    const code = new URLSearchParams(search).get('code');

    if (email && code) {
      setLastUsername(email);
      try {
        setLoading(true);
        await Auth.confirmSignUp(email, code);

        navigate(`/sign-in`);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  const resendEmail = async () => {
    setError('');
    try {
      setLoading(true);
      await Auth.resendSignUp(lastUsername);
    } catch (error) {
      setError(error.message);
      console.log('error resending code: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    confirmUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width={1}
      height={'calc(80vh - 16rem)'}
      px={{xs: '2.4rem', md: 0}}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Stack
        maxWidth={'60rem'}
        spacing={'4rem'}>

        <Typography
          color={'common.black'}
          textAlign={'center'}
          sx={{
            typography: {xs: 'h3', md: 'h1'},
          }}>
          Confirm your email
        </Typography>

        <Typography
          variant={'bodyLight'}
          color={'grey.main'}
          textAlign={'center'}>
          {'We’ve sent an email to '}
          <Typography
            variant={'bodyLight'}
            color={'common.black'}
            textAlign={'center'}>{lastUsername ?? 'your address'}</Typography>
          {' with the instructions to complete signup.'}
        </Typography>

        {lastUsername && (
          <Typography
            variant={'bodyLight'}
            color={'grey.main'}
            textAlign={'center'}>
            {'Didn’t receive the email? '}
            <Typography
              onClick={resendEmail}
              variant={'bodyLight'}
              color={'common.black'}
              textAlign={'center'}
              sx={{cursor: 'pointer'}}
            >
              {'Resend'}
            </Typography>
          </Typography>
        )}

        <Box
          sx={{
            textAlign: 'center',
            display: loading ? 'block' : 'none',
          }}
        >
          <CircularProgress />
        </Box>
        <Typography
          variant={'bodyLight'}
          color={'red'}
          textAlign={'center'}
        >
          {error}
        </Typography>
      </Stack>
    </Box>
  );
};
