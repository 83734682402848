import { Box, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useUserStore } from '../../store/userStore';

export const ChangePassword = () => {
  const lastUsername = useUserStore((state) => state.lastUsername);

  const [username, setUsername] = useState(lastUsername ?? '');
  const [usernameError, setUsernameError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const changePassword = async () => {
    setError('');

    // username must set and a valid email
    if (username === '') {
      setUsernameError('Please enter a username');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setUsernameError('Please enter a valid email address');
      return;
    }

    if (code === '' || code.length !== 6) {
      setCodeError('Please enter a valid code');
      return;
    }

    // Password must have 8 characters, contain one symbol, one number, one uppercase and one lowercase letter
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setNewPasswordError('Password must have 8 characters, contain one symbol, one number, one uppercase and one lowercase letter');
      return;
    }

    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      navigate('/sign-in');
    } catch (error) {
      console.log('error changing password', error);
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <Box
      maxWidth={'56rem'}
      px={{xs: '2.4rem', md: 0}}
      textAlign={'center'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'4.8rem'}>
      <Typography
        color={'common.black'}
        sx={{
          typography: {xs: 'h3', md: 'h1'},
        }}>
        Change your password
      </Typography>

      <Stack width={1}>
        <TextField
          variant={'outlined'}
          fullWidth
          type={'text'}
          placeholder={'Email'}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={!!usernameError}
          helperText={usernameError}
        />
        <TextField
          sx={{mt: '1rem'}}
          variant={'outlined'}
          fullWidth
          type={'text'}
          placeholder={'Code'}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={!!codeError}
          helperText={codeError}
        />
        <TextField
          sx={{mt: '1rem'}}
          variant={'outlined'}
          fullWidth
          type={'password'}
          placeholder={'Enter new password'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={!!newPasswordError}
          helperText={newPasswordError}
        />
      </Stack>

      <LoadingButton
        fullWidth
        variant={'primaryMain'}
        sx={{
          maxWidth: '46rem',
        }}
        onClick={changePassword}
        loading={loading}
      >
        Change password
      </LoadingButton>

      <FormHelperText error>
        {error}
      </FormHelperText>
    </Box>
  );
};
