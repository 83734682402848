import { createTheme } from '@mui/material';
import defaultTheme from '@mui/material/styles/defaultTheme';

import {
  light,
  // dark
} from './Palette';

const palette = light;

export const MainTheme = createTheme({
  palette,
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    h1: {
      fontSize: '4.8rem',
      fontWeight: 600,
      lineHeight: '5.6rem',
      letterSpacing: '-.05rem',
    },
    h2: {
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '5.6rem',
      letterSpacing: '-.05rem',
    },
    h3: {
      fontSize: '3.4rem',
      fontWeight: 600,
      lineHeight: '4.4rem',
      letterSpacing: '-.05rem',
    },
    h4: {
      fontSize: '2.8rem',
      fontWeight: 600,
      lineHeight: '5.6rem',
      letterSpacing: '-.05rem',
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: 600,
      lineHeight: '3.2rem',
      letterSpacing: '-.05rem',
    },
    h6: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '-.05rem',
    },
    body: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '2.4rem',
      textDecoration: 'none',
    },
    bodyLight: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '2.4rem',
      textDecoration: 'none',
    },
    bodySmall: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem',
    },
    caption: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '2.2rem',
      letterSpacing: '.1rem',
      textTransform: 'uppercase',
    },
    s1: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '2.4rem',
      letterSpacing: '.2rem',
    },
    s2: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    s3: {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    s4: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '2rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        googleButton: {
          border: '.1rem solid #DDDDDD',
          borderRadius: '2rem',
          padding: '2.4rem',
          backgroundColor: '#fff',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: palette.common.black,
          textTransform: 'none',
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        primaryMain: {
          backgroundColor: palette.primary.main,
          // border: '.1rem solid #DDDDDD',
          borderRadius: '2rem',
          padding: '2.4rem',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        secondaryMain: {
          backgroundColor: '#FFF',
          border: `.1rem solid ${palette.grey.graphite}`,
          borderRadius: '2rem',
          padding: '2.4rem',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        primaryNavbar: {
          backgroundColor: palette.primary.main,
          borderRadius: '2rem',
          padding: '1.6rem 2.4rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        secondaryNavbar: {
          backgroundColor: '#FFF',
          border: `.1rem solid ${palette.grey.graphite}`,
          borderRadius: '2rem',
          padding: '1.6rem 2.4rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        primaryThin: {
          backgroundColor: palette.primary.main,
          borderRadius: '1.5rem',
          padding: '.8rem 1.6rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },
        secondaryThin: {
          backgroundColor: '#FFF',
          border: `.1rem solid ${palette.grey.graphite}`,
          borderRadius: '2rem',
          padding: '.8rem 1.6rem',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          color: palette.grey.graphite,
          textTransform: 'none',
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        errorButton: {
          backgroundColor: palette.common.error,
          borderRadius: '1.5rem',
          padding: '.8rem 1.6rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
          color: '#FFF',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: palette.common.errorLight,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '1.6rem',
          },
        },
        textButton: {
          color: palette.common.black,
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '6.4rem',
          backgroundColor: '#FFF',
          borderRadius: '1.6rem',
          fontSize: '1.6rem',
          padding: '2rem 2.4rem',
          [defaultTheme.breakpoints.down('sm')]: {
            height: '5.6rem',
          },
        },
        multiline: {
          height: 'auto',
          [defaultTheme.breakpoints.down('sm')]: {
            height: 'auto',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: 'none',
          maxHeight: '4.4rem',
          backgroundColor: 'transparent',
          padding: '1.2rem 1.5rem',
          fontSize: '1.4rem',
          fontWeight: 400,
          lineHeight: '2rem',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: palette.primary.main,
          },
          '&:hover': {
            backgroundColor: palette.primary.hover,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {},
        label: {
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '2.4rem',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '3.2rem',
          height: '3.2rem',
          color: '#FFF',
          border: '.1rem solid',
          borderColor: palette.common.black,
          borderRadius: '50%',
        },
        text: {
          fontSize: '1.4rem',
          fontWeight: 700,
          lineHeight: '2.4rem',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {},
        active: {},
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.4rem',
          fontWeight: 500,
          lineHeight: '2rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.4rem',
          fontWeight: 300,
          lineHeight: '2rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '2rem',
          fontWeight: 600,
          lineHeight: '2.6rem',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.4rem',
          fontWeight: 600,
          lineHeight: '2.6rem',
        },
      },
    },
  },
});
