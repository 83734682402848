//REACT IMPORTS
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API, Auth, Storage } from 'aws-amplify';

//COMPONENT IMPORTS
import { UserNavbar } from '../components/navbar/UserNavbar';
import { useUserStore } from '../store/userStore';
import SpinningLoader from '../components/helpers/SpinningLoader';

export const UserLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  const user = useUserStore((state) => state.user);
  const product = useUserStore((state) => state.product);

  const setGroup = useUserStore((state) => state.setGroup);
  const setCompany = useUserStore((state) => state.setCompany);
  const setUser = useUserStore((state) => state.setUser);
  const setProduct = useUserStore((state) => state.setProduct);
  const setCompanyImage = useUserStore((state) => state.setCompanyImage);

  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    if (companyId) {
      localStorage.setItem('localCompanyId', companyId);
    }
  }, [companyId]);

  const fetchCompanyImage = async (company) => {
    if (!company) return;

    try {
      const image = await Storage.get(company.company_id + '/profileimage', { level: 'private' });
      if (image) {
        setCompanyImage(image);
      }
    } catch {
    }
  };

  const fetchUserInfo = async () => {
    try {
      let infoUrl = '/info';
      const selectedCompany = localStorage.getItem('localCompanyId');

      if (selectedCompany) {
        infoUrl = `/info?company_id=${selectedCompany}`;
      }

      const res = await API.get('api2e29c07a', infoUrl);

      setUser(res.user);
      setProduct(res.product);

      if (res.company) {
        setCompany(res.company);
        setCompanyId(res.company.company_id);
        // TODO: don't fetch this on each page load
        await fetchCompanyImage(res.company);
      } else if (location.pathname !== '/users/complete-profile') {
        navigate({
          pathname: '/users/complete-profile',
          search: location.search
        });
      }

      setFirstLoad(false);
    } catch (e) {
      console.log(e);
      await Auth.signOut();
      navigate('/sign-in');
    }

  };

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        await setGroup(user.signInUserSession.accessToken.payload['cognito:groups']);

        // fetch user info here
        await fetchUserInfo();
      } catch (err) {
        navigate({
          pathname: '/sign-in',
          search: location.search
        });
      }
      setLoading(false);
    };
    checkUser();
  }, []);

  useEffect(() => {
    if (!firstLoad && !companyId) {
      if (location.pathname !== '/users/complete-profile') {
        navigate({ pathname: '/users/complete-profile', search: location.search });
        return;
      }
    }

    if (!loading) {
      fetchUserInfo();
    }
  }, [location]);

  if (loading || !user) {
    return <SpinningLoader />;
  }

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={{ xs: '2.2rem', md: '8rem' }}>
      <UserNavbar />
      <Outlet />
    </Box>
  );
};
