import { Box, FormControlLabel, FormHelperText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material"
import { FieldHelper } from "../../../components/helpers/FieldHelper"

export const ShredDocumentsFirstStep = ({
    organizationName, setOrganizationName,
    proposalName, setProposalName,
    proposalType, setProposalType,
    primaryPurpose, setPrimaryPurpose,
    proposalDescription, setProposalDescription
}) => {
    return(
        <Box
            width={1}
            bgcolor={'grey.grey1'}
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            borderRadius={'.8rem'}
            display={'flex'}
            flexDirection={'column'}
            gap={'1.6rem'}
            p={'2.4rem'}>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Organization Name: </Typography>
                    <FieldHelper
                        suggestion={'Provide the name of the organization that this proposal is for'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={organizationName}
                    inputProps={{ maxLength: 128 }}
                    onChange={(event) => setOrganizationName(event.target.value)}
                    placeholder={'Organization name'} />
            </Stack>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Proposal Name: </Typography>
                    <FieldHelper
                        suggestion={'Give the name of the proposal'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={proposalName}
                    inputProps={{ maxLength: 128 }}
                    onChange={(event) => setProposalName(event.target.value)}
                    placeholder={'Proposal name'} />
            </Stack>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Proposal Type: </Typography>
                    <FieldHelper
                        suggestion={'Give the type of the proposal'} />
                </Stack>
                <Select
                    value={proposalType}
                    onChange={(event) => setProposalType(event.target.value)}>
                    <MenuItem value={'placeholder'} disabled>Select</MenuItem>
                    <MenuItem value={'RFI'}>RFI Questionnaire / Capabilities</MenuItem>
                    <MenuItem value={'RFP'}>RFP</MenuItem>
                </Select>
            </Stack>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Primary Purpose: </Typography>
                    <FieldHelper
                        suggestion={'Give the purpose of the proposal'} />
                </Stack>
                <RadioGroup
                    value={primaryPurpose}
                    onChange={(event) => setPrimaryPurpose(event.target.value)}
                    defaultValue={primaryPurpose}>
                    <FormControlLabel value="Product" control={<Radio size={'medium'} />} label="Product Type" />
                    <FormControlLabel value="Service" control={<Radio size={'medium'} />} label="Service Type" />
                </RadioGroup>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Proposal Description: </Typography>
                    <FieldHelper
                        suggestion={'Give a brief description of the proposal.  This will help build the executive summary'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows={5}
                    value={proposalDescription}
                    inputProps={{ maxLength: 1500 }}
                    onChange={(event) => setProposalDescription(event.target.value)}
                    placeholder={'Proposal Description'} />
                <FormHelperText>{`${1500 - proposalDescription.length} Characters left`}</FormHelperText>
            </Stack>
        </Box>
    )
}
