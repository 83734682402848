import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { DeleteClientModal } from '../../../components/modals/DeleteClientModal';

import { EditClientModal } from '../../../components/modals/EditClientModal';

export const PastPerformanceListItem = ({
  companyId,
  clientId,
  clientName,
  roleOnContract,
  aiSummarization,
  contractName,
  skillsUsed,
  metricsUsed,
  setPastPerformancesList,
  refresh,
  }) => {

  const [updateModal, setUpdateModal] = useState(false);
  const UpdateModalHandler = () => {
    setUpdateModal(!updateModal);
    refresh();
  }

  const [deleteModal, setDeleteModal] = useState(false);
  const DeleteModalHandler = () => {
    setDeleteModal(!deleteModal);
    refresh();
  }

  return (
    <Stack
      width={1}
      padding={'1.2rem'}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
      border={{xs: '.1rem solid #F4F4F4', md: 'none'}}
      borderRadius={'.8rem'}
    >
      <Modal open={deleteModal}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{xs: '1.6rem', md: 0}}>
          <DeleteClientModal
            modalHandler={DeleteModalHandler}
            clientId={clientId}
            companyId={companyId}
            setPastPerformancesList={setPastPerformancesList}
             />
        </Box>
      </Modal>
      <Modal open={updateModal} onClose={UpdateModalHandler}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{xs: '1.6rem', md: 0}}>
          <EditClientModal
            deleteModalHandler={DeleteModalHandler}
            modalHandler={UpdateModalHandler}
            clientId={clientId}
            clientName={clientName}
            roleOnContract={roleOnContract}
            aiSummarization={aiSummarization}
            contractName={contractName}
            skillsUsed={skillsUsed}
            metricsUsed={metricsUsed}
            setPastPerformancesList={setPastPerformancesList}
             />
        </Box>
      </Modal>

      <Box
        width={'70%'}
        ml={{xs: '1rem', md: 0}}
        height={1}
        padding={'1.2rem'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Typography
          width={1}
          textAlign={'left'}
          color={'common.black'}
          variant={'body'}
          noWrap
          textOverflow={'ellipsis'}>
          {contractName ? contractName : 'Contract Name'}
        </Typography>
      </Box>
      <Box
        width={'30%'}
        height={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Button
          fullWidth
          variant={'primaryNavbar'}
          onClick={UpdateModalHandler}>
          Edit Entry
        </Button>
      </Box>
    </Stack>
  );
};
