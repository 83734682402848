//REACT IMPORTS
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

//STYLE IMPORT
import "../../assets/MainStyles.css";

export const InputWithBubbles = ({tags, setTags, maxTags, placeholder}) => {

    const [val, setVal] = useState('');

    const keydownHandler = (event) => {
            const value = event.target.value;
            if(!value.trim()) return
            if(event.key !== 'Enter') return
            setTags([...tags, value])
            event.target.value = ''
            setVal('');
    }

    const removeTag = (index) => setTags(tags.filter((el, i) => i !== index))

    return(
        <Box
            width={1}
            minHeight={'17rem'}
            padding={'1.6rem'}
            border={'.1rem solid'}
            borderColor={'grey.grey3'}
            bgcolor={'white'}
            borderRadius={'.8rem'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'.8rem'}
            flexWrap={'wrap'}>

            {tags && tags.map((tag, index) => (
                <Stack
                    key={index}
                    direction={'row'}
                    padding={'1.2rem 1.5rem'}
                    bgcolor={'grey.grey1'}
                    border={'.1rem solid'}
                    borderColor={'grey.subtle'}
                    borderRadius={'.8rem'}
                    spacing={'1.4rem'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Typography variant={'bodySmall'}>{tag}</Typography>
                    <Typography
                        fontSize={'2.4rem'}
                        lineHeight={'2rem'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => removeTag(index)}>
                            &times;
                    </Typography>
                </Stack>
            ))}

            <Box
                component={'input'}
                type={'text'}
                placeholder={placeholder}
                onKeyDown={keydownHandler}
                maxLength={64}
                fontSize={'1.4rem'}
                padding={'1.2rem 1.5rem'}
                value={val}
                onChange={(event) => setVal(event.target.value)}
                sx={{
                    display: tags.length < maxTags ? 'flex' : 'none',
                    border: 'none',
                    '&::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0
                        },
                    '&::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0
                        },
                    '&:focus': { outline: 'none' }
                }} />
        </Box>
    )
};
