import { Box, Button, Stack, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

export const ChangePasswordSuccess = () => {
    return(
        <Box
            width={1}
            height={'calc(80vh - 16rem)'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                spacing={'4rem'}>
                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{
                        typography: {xs: 'h3', md: 'h1'}
                    }}>
                    Change your password
                </Typography>

                <Box
                    display={'flex'}
                    flexDirection={{xs: 'column', md: 'row'}}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'1rem'}
                    color={'common.success'}>
                    <CheckCircleOutlineRoundedIcon fontSize="large" />
                    <Typography
                        variant={'bodyLight'}
                        textAlign={'center'}>
                        Your password has successfully been reset.
                    </Typography>
                </Box>
            </Stack>
            <Button
                fullWidth
                variant={'primaryMain'}
                sx={{
                    maxWidth: '46rem',
                    mt: '4.8rem'
                }}>
                Log In
            </Button>
        </Box>
    )
}