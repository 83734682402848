import {Box, Button, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {saveAs} from 'file-saver';
import {GET_PROPOSAL_DETAIL_API} from '../../../const';
import {API} from 'aws-amplify';
import {Alert, LoadingButton} from '@mui/lab';

export const ShreddedDocItem = ({
                                    proposalName,
                                    documentId,
                                    documentStatus,
                                    proposal
                                }) => {
    const [downloading, setDownloading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    //const [loading, setLoading] = useState(false);
    const [proposalData, setProposalData] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(false);
    const DeleteModalHandler = () => setDeleteModal(!deleteModal)

    const fetchProposalData = async (id) => {
        //setLoading(true);

        if (!proposalData) {
            try {
                let response = await fetch(`${GET_PROPOSAL_DETAIL_API}${id}`);
                response = await response.json();
                await setProposalData(response);
                return response;
            } catch (error) {
                console.log(error);
            }
        }

        //setLoading(false);
        return proposalData;
    };

    const downloadMatrix = async () => {
        setDownloading(true)
        const document = await API.get('pyBackend', `/shred/get-shreded-compliance-matrix?shreded_doc_id=${documentId}`, {});
        const binary = atob(document);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        const file = new Blob([new Uint8Array(array)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const fileName = `${proposalName}.xlsx`;
        saveAs(file, fileName);
        setDownloading(false)
    };

    const buttonContext = () => {
        switch (documentStatus) {
            case 'Error':
                return <Alert severity='error'><Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    There was an error during the shredding process
                </Typography></Alert>
            case 'In Progress' || 'Draft':
                return <>
                  <Button
                        onClick={() => navigate(`/users/create-proposal-from-doc/${documentId}`)}
                        disabled={true}
                        variant={'primaryNavbar'}
                    >
                        Write Proposal
                    </Button>
                    <LoadingButton
                        loading={downloading}
                        onClick={downloadMatrix}
                        variant={'primaryNavbar'}
                        disabled={true}
                    >
                        Download Matrix
                    </LoadingButton>
                </>
            default:
              switch (proposal.proposal_status){
                  case 'Draft':
                      return <>
                          <Button
                              onClick={() => navigate(`/users/create-proposal-from-doc/${documentId}`)}
                              variant={'primaryNavbar'}
                          >
                              Write Proposal
                          </Button>
                          <LoadingButton
                              loading={downloading}
                              onClick={downloadMatrix}
                              disabled={proposal.rfp_rfi === 'RFI'}
                              variant={'primaryNavbar'}
                          >
                              Download Matrix
                          </LoadingButton>
                      </>
                  case 'In Progress':
                      return <>
                          <Button
                              onClick={() => navigate(`/users/proposal-detail/${proposal.proposal_id}`)}
                              variant={'secondaryNavbar'}
                              disabled={true}
                          >
                              Open Proposal
                          </Button>
                          <LoadingButton
                              loading={downloading}
                              onClick={downloadMatrix}
                              disabled={proposal.rfp_rfi === 'RFI'}
                              variant={'primaryNavbar'}
                          >
                              Download Matrix
                          </LoadingButton>
                      </>
                  case 'Error':
                      return <Alert severity='error'><Typography
                          width={1}
                          px={'1.6rem'}
                          textAlign={'center'}
                          variant={'body'}
                          noWrap
                          textOverflow={'ellipsis'}>
                          There was an error while creating your proposal
                      </Typography></Alert>
                  default:
                      return <>
                          <Button
                              onClick={() => navigate(`/users/proposal-detail/${proposal.proposal_id}`)}
                              variant={'secondaryNavbar'}
                          >
                              Open Proposal
                          </Button>
                          <LoadingButton
                              loading={downloading}
                              onClick={downloadMatrix}
                              disabled={proposal.rfp_rfi === 'RFI'}
                              variant={'primaryNavbar'}
                          >
                              Download Matrix
                          </LoadingButton>
                      </>
              }
        }
    }

    return (
        <Stack
            width={1}
            height={{xs: '8rem', md: '4.8rem'}}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            border={{xs: '.1rem solid #F4F4F4', md: 'none'}}
            borderRadius={'.8rem'}
        >
            <Box
                width={'25%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {proposalName ? proposalName : 'Proposal Name'}
                </Typography>
            </Box>
            <Box
                width={'15%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={documentStatus === 'Completed' ? 'common.success' : documentStatus === 'In Progress' && 'primary.main'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {documentStatus ? documentStatus : 'Pending'}
                </Typography>
            </Box>
            <Box
                width={'60%'}
                height={1}
                display={'flex'}
                flexDirection={'row'}
                gap={'1.2rem'}
                alignItems={'center'}
                justifyContent={'flex-end'}>
                {buttonContext()}
            </Box>

            {/* <Button
          variant={'primaryNavbar'}>
          Download Template
        </Button> */}

        </Stack>
    );
};
