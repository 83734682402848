//REACT & MUI IMPORTS
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//COMPONENT IMPORTS
import { ProposalListItem } from './components/ProposalListItem';
import { useUserStore } from '../../store/userStore';
import { API } from 'aws-amplify';
import { GET_ALL_PROPOSALS_API } from '../../const';

export const MyProposals = () => {
  const navigate = useNavigate();
  const [proposalList, setProposalList] = useState([]);
  const [shreddedDocsList, setShreddedDocsList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const company = useUserStore(state => state.company);

  const fetchInfo = async () => {
    setLoading(true);
    const documents = await API.get('pyBackend', `/shred/get-all-shreded-docs?company_id=${company.company_id}`);
    setShreddedDocsList(documents);
    let proposals = await API.get('pyBackend', `${GET_ALL_PROPOSALS_API}${company.company_id}`, {});
    setProposalList(proposals);
    setLoading(false);
  };

  const getDocument = (proposal) => {
    const document = shreddedDocsList.find(doc => doc.proposal_id === proposal.proposal_id);
    if (!document) {
      return {
        company_id: proposal.company_id,
        shreded_status: proposal.proposal_status,
        created_at: proposal.created_at,
        updated_at: proposal.updated_at,
        proposal_id: proposal.proposal_id,
        proposal_owner: proposal.proposal_owner,
        proposal_name: proposal.proposal_name,
        proposal_status: proposal.proposal_status,
        rfp_rfi: proposal.rfp_rfi,
        primary_purpose: proposal.primary_purpose
      };
    }
    return document;
  };

  useEffect(() => {
    if (company && company !== {}) {
      fetchInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const proposalsPerPage = 10;
  const totalPages = Math.ceil(proposalList.length / proposalsPerPage);

  const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <Box
      width={1}
      maxWidth={'127rem'}
      mt={{ xs: 0, md: '-4rem' }}
      mb={totalPages > 1 ? 0 : '7.2rem'}
      px={{ xs: '1.6rem', md: '4.8rem' }}
      display={'flex'}
      flexDirection={'column'}
      gap={'4.8rem'}>
      <Typography
        color={'common.black'}
        textAlign={{ xs: 'center', md: 'left' }}
        variant={'h3'}>
        My proposals
      </Typography>
      <Button
        variant={'primaryThin'}
        onClick={() => navigate('/users/new-proposal')}
        sx={{
          display: { xs: 'block', md: 'none' }
        }}>
        Create proposal
      </Button>
      <Box
        width={1}>
        <Stack
          display={{ xs: 'none', md: 'flex' }}
          width={1}
          height={'4.8rem'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Box
            width={'15%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Company
            </Typography>
          </Box>
          <Box
            width={'15%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography
              textAlign={'left'}
              color={'common.black'}
              variant={'s2'}>
              Status
            </Typography>
          </Box>
          <Box
            width={'30%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Proposal
            </Typography>
          </Box>
          <Box
            width={'10%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              RFP
            </Typography>
          </Box>
          <Box
            width={'20%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Created Date
            </Typography>
          </Box>
          <Box
            width={'10%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              More
            </Typography>
          </Box>
        </Stack>
        <Divider flexItem sx={{ display: { xs: 'none', md: 'flex' } }} />
        {isLoading && (<Box width={1} display={'flex'} justifyContent={'center'}><CircularProgress /></Box>)};

        <Box
          width={1}
          display={'flex'}
          flexDirection={'column'}
          gap={'1.2rem'}
          py={{ xs: 0, md: '2.4rem' }}>
          {proposalList.length &&
            proposalList.slice((currentPage - 1) * proposalsPerPage,
              (currentPage - 1) * proposalsPerPage + proposalsPerPage).map((proposal, index) => {
              return <ProposalListItem
                key={index}
                proposalId={proposal.proposal_id}
                proposalName={proposal.proposal_name}
                proposalOwner={proposal.proposal_owner}
                proposalType={proposal.rfp_rfi}
                proposalStatus={proposal.proposal_status}
                creationDate={proposal.created_at.slice(0, 10)}
                fetchProposals={fetchInfo}
                shredDocument={getDocument(proposal)}
              />;
            })}
        </Box>
      </Box>

      <Box
        width={1}
        display={totalPages > 1 ? 'flex' : 'none'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1.2rem'}
        p={'1.6rem'}
        mb={'5rem'}>
        <IconButton
          disabled={currentPage === 1 ? true : false}
          onClick={prevButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main'
          }}>
          <ArrowBackIosNewRounded />
        </IconButton>
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'small'}
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'large'}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <IconButton
          disabled={currentPage === totalPages ? true : false}
          onClick={nextButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main'
          }}>
          <ArrowForwardIosRounded />
        </IconButton>
      </Box>
    </Box>
  );
};
