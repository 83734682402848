import {Box, IconButton, Modal, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {DeleteAccountModal} from '../../../components/modals/DeleteAccountModal';

export const AccountListItem = ({
                                    accountId,
                                    name,
                                    email,
                                    accountType,
                                    status,
                                    fetchData
                                }) => {

    const [deleteModal, setDeleteModal] = useState(false);

    return (
        <Stack
            width={1}
            height={{xs: '8rem', md: '4.8rem'}}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            border={{xs: '.1rem solid #F4F4F4', md: 'none'}}
            borderRadius={'.8rem'}
        >
            <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    px={{xs: '1.6rem', md: 0}}>
                    <DeleteAccountModal
                        fetchData={fetchData}
                        setDeleteModal={setDeleteModal}
                        accountId={accountId}
                    />
                </Box>
            </Modal>
            <Box
                width={'35%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'left'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {accountId}
                </Typography>
            </Box>
            <Box
                width={'15%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {name}
                </Typography>
            </Box>
            <Box
                width={{xs: '18rem', md: '25%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                overflow={'hidden'}
                sx={{cursor: 'pointer'}}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {email}
                </Typography>
            </Box>
            <Box
                width={'10%'}
                height={1}
                display={{xs: 'none', md: 'flex'}}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}>
                    {accountType ? accountType : ''}
                </Typography>
            </Box>
            <Box
                width={{xs: '8.4rem', md: '10%'}}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={{xs: 'flex-end', md: 'center'}}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={{xs: 'right', md: 'center'}}
                    color={'common.black'}
                    sx={{
                        typography: {xs: 'bodySmall', md: 'body'},
                    }}>
                    {status ? status : ''}
                </Typography>
            </Box>
            <Box
                width={{xs: '4.4rem', md: '5%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                {status === 'Active' && (<Box
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <IconButton onClick={() => setDeleteModal(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>)}
            </Box>
        </Stack>
    );
};
