//REACT IMPORTS
import { Box, Button, Modal, OutlinedInput, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { API } from 'aws-amplify';

import { InputWithBubbles } from "../input/InputWithBubbles";

import { POST_UPDATE_CLIENT_API } from '../../const';

import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { FieldHelper } from "../helpers/FieldHelper";
import { RewriteClientDescriptionModal } from "./RewriteClientDescriptionModal";
import { LoadingButton } from "@mui/lab";
import { useUserStore } from '../../store/userStore';

//API IMPORTS

export const EditClientModal = ({
    deleteModalHandler,
    modalHandler,
    clientId,
    clientName,
    roleOnContract,
    aiSummarization,
    contractName,
    skillsUsed,
    metricsUsed,
    setPastPerformancesList
}) => {

    const company = useUserStore(state => state.company);

    const [newClientName, setNewClientName] = useState('');
    const [newRoleOnContract, setNewRoleOnContract] = useState('');
    const [newContractName, setNewContractName] = useState('');
    const [newSkillsUsed, setNewSkillsUsed] = useState([]);
    const [newClientDescription, setNewClientDescription] = useState('');
    const [newMetricsUsed, setNewMetricsUsed] = useState([]);
    const [promptMessage, setPromptMessage] = useState('')

    const preloadFields = () => {
        setNewClientName(clientName ?? '');
        setNewRoleOnContract(roleOnContract ?? '');
        setNewContractName(contractName ?? '');
        setNewClientDescription(aiSummarization ?? '')
        setNewSkillsUsed(skillsUsed?.split(',') ?? []);
        setNewMetricsUsed(metricsUsed?.split(',') ?? [])
    }

    useState(() => {
        preloadFields()
    }, [clientId])

    const UpdateClientInfo = async () => {
        await API.post('pyBackend', POST_UPDATE_CLIENT_API, {
            body: {
                client_id: clientId,
                client_name: newClientName,
                client_industry: '',
                role_on_contract: newRoleOnContract,
                ai_summarization: newClientDescription,
                contract_name: newContractName,
                skills_used: newSkillsUsed,
                metrics_used: newMetricsUsed.toString(),
            },
        });
        modalHandler();
    };

    const DeleteHandler = () => {
        modalHandler();
        deleteModalHandler();
    }

    //rewrite
    const [rewriting, setRewriting] = useState(false)
    const RewriteFunction = async () => {
        setRewriteModal(false);
        setRewriting(true);
        const result = await API.post('regenerateClientDescription', '', {
            body: {
                prompt: promptMessage,
                company_name: company.name,
                client_name: newClientName,
                role_on_contract: newRoleOnContract,
                contract_name: newContractName,
                skills_used: newSkillsUsed,
                metrics_used: newMetricsUsed.toString(),
            }
        });
        setNewClientDescription(result);
        setRewriting(false);
        setPromptMessage('')
    };

    const [rewriteModal, setRewriteModal] = useState(false);
    const RewriteHandler = () => setRewriteModal(!rewriteModal);

    return(
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            overflow={'hidden'}
            maxHeight={'90vh'}
            sx={{
                overflowY: 'scroll'
            }}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Modal open={rewriteModal} onClose={RewriteHandler}>
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    px={{xs: '1.6rem', md: 0}}>
                    <RewriteClientDescriptionModal
                        promptMessage={promptMessage}
                        setPromptMessage={setPromptMessage}
                        modalHandler={RewriteHandler}
                        rewriteDescription={RewriteFunction} />
                </Box>
            </Modal>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon />}
                    onClick={() => modalHandler()}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Typography textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Edit Client Entry
                </Typography>

                <Stack width={1} spacing={'1.2rem'}>
                    <Stack
                        width={1}
                        spacing={'.8rem'}>
                        <Typography variant={'s3'}>
                            Client Name
                        </Typography>
                        <OutlinedInput
                            value={newClientName}
                            placeholder={'John Doe'}
                            onChange={(event) => setNewClientName(event.target.value)} />
                    </Stack>
                    <Stack
                        width={1}
                        spacing={'.8rem'}>
                        <Typography variant={'s3'}>
                            Contract Name
                        </Typography>
                        <OutlinedInput
                            value={newContractName}
                            placeholder={'John Doe'}
                            onChange={(event) => setNewContractName(event.target.value)} />
                    </Stack>
                    <Stack
                        width={1}
                        spacing={'.8rem'}>
                        <Typography variant={'s3'}>
                            Skills Used
                        </Typography>
                        <InputWithBubbles
                            tags={newSkillsUsed}
                            setTags={setNewSkillsUsed}
                            maxTags={1000}
                            placeholder={'Add Skill'} />
                    </Stack>
                    <Stack
                        width={1}
                        spacing={'.8rem'}>
                        <Typography variant={'s3'}>
                            Metrics Used
                        </Typography>
                        <InputWithBubbles
                            tags={newMetricsUsed}
                            setTags={setNewMetricsUsed}
                            maxTags={1000}
                            placeholder={'Add Another'} />
                    </Stack>
                    <Stack width={1} spacing={'1.2rem'}>
                        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                            <Typography color={'common.black'} variant={'s3'}> Past Performance Description </Typography>
                            <FieldHelper
                                suggestion={'Input your contract name'} />
                        </Stack>
                        <Typography
                            bgcolor={'common.white'}
                            borderRadius={'1.5rem'}
                            padding={'1.2rem'}
                            border={'.1rem solid'}
                            borderColor={'grey.subtle'}
                            variant={'s3'}
                            fontWeight={300}
                            whiteSpace={'pre-word'}>{newClientDescription}</Typography>
                    </Stack>
                    <LoadingButton loading={rewriting} variant={'secondaryMain'} onClick={RewriteHandler}>
                        Rewrite
                    </LoadingButton>
                </Stack>

                <Stack spacing={'.8rem'} width={1}>
                    <Stack
                        width={1}
                        spacing={'1.6rem'}
                        direction={{xs: 'column-reverse', md: 'row'}}>
                        <Button
                            fullWidth
                            variant={'secondaryMain'}
                            onClick={() => modalHandler()}>
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant={'primaryMain'}
                            onClick={UpdateClientInfo}>
                            Update
                        </Button>
                    </Stack>
                    <Button
                        fullWidth
                        sx={{
                            height: '6.4rem'
                        }}
                        variant={'errorButton'}
                        onClick={DeleteHandler}>
                        Delete Client
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
