//REACT IMPORTS
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";

//COMPONENT IMPORTS
import { ContractCard } from "../../../components/cards/ContractCard";

export const StepFour = ({ myClients, setMyClients, metrics, setMetrics, overviewTags, setOverviewTags, companyName }) => {

    const deleteClient = (index) => { setMyClients(myClients.filter((client, i) => i !== index)) }

    const [ roleOnContract, setRoleOnContact ] = useState('');
    const [ organizationName, setOrganizationName ] = useState('');
    const [ contractName, setContractName ] = useState('');
    const [ skillsUsed, setSkillsUsed ] = useState([]);
    const [ metricsUsed, setMetricsUsed ] = useState([]);
    const [ clientDescription, setClientDescription ] = useState('');

    const [ showDescription, setShowDescription ] = useState(false);

    //const [ updateTask, setUpdateTask ] = useState('')
    //const [ clientToEdit, setClientToEdit ] = useState({})

    const editClient = (client, index) => {
        setShowDescription(true);
        //Filling fields to edit
        setRoleOnContact(client.role_on_contract);
        setOrganizationName(client.client_name);
        setContractName(client.contract_name);
        setSkillsUsed(client.skills_used.split(','));
        setMetricsUsed(client.metrics_used.split(','));
        setClientDescription(client.client_description);

        //Deleting Client from List
        setMyClients(myClients.filter((client, i) => i !== index));
    }

    return(
        <Box
            width={1}
            maxWidth={'50rem'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                direction={'column'}
                textAlign={'center'}
                mb={{xs: '3.2rem', md: '8rem'}}>
                <Typography variant={'body'} color={'grey.main'}>
                    List up to 50 of your previous clients and industries.
                </Typography>
                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Tell us about your past performances
                </Typography>
            </Stack>

            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'1.2rem'}
                mb={'2.4rem'}>
                {myClients && myClients.map((client, index) => (
                    <Stack
                        width={1}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        p={'1.2rem'}
                        border={'.1rem solid'}
                        borderColor={'grey.subtle'}
                        bgcolor={'grey.grey1'}
                        borderRadius={'.8rem'}
                        key={index}>
                            <Stack direction={'column'}>
                                <Typography variant={'body'}>{client.client_name}</Typography>
                                <Typography variant={'bodyLight'} textTransform={'capitalize'} color={'grey.main'}>{client.contract_name}</Typography>
                            </Stack>
                            <Stack direction={'row'} spacing={'.8rem'}>
                                <IconButton onClick={() => editClient(client, index)}>
                                    <EditOutlined fontSize={'large'} />
                                </IconButton>
                                <IconButton onClick={() => deleteClient(index)}>
                                    <DeleteOutline fontSize={'large'} />
                                </IconButton>
                            </Stack>
                    </Stack>
                ))}
            </Box>

            <ContractCard
                myClients={myClients}
                setMyClients={setMyClients}
                companyName={companyName}
                roleOnContract={roleOnContract}
                setRoleOnContact={setRoleOnContact}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                contractName={contractName}
                setContractName={setContractName}
                skillsUsed={skillsUsed}
                setSkillsUsed={setSkillsUsed}
                metricsUsed={metricsUsed}
                setMetricsUsed={setMetricsUsed}
                clientDescription={clientDescription}
                setClientDescription={setClientDescription}
                showDescription={showDescription}
                setShowDescription={setShowDescription}
                 />
        </Box>
    )
}