import { Box, Stack, Typography } from "@mui/material"

export const PrivacyPolicy = () => {
    return(
        <Box
            width={1}
            maxWidth={'70rem'}
            textAlign={'justify'}
            display={'flex'}
            gap={'2.4rem'}
            mb={'7rem'}
            flexDirection={'column'}>
            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Privacy Policy</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`At proposalwriter.ai, we are committed to protecting the privacy of our users and handling their personal information with care. This Privacy Policy explains how we collect, use, and protect personal information when you use our products and services, including our websites, apps, and software.

                    We do not share any personal information that you provide us with any third party. This includes your name, email address, payment information, and any other details you may provide to us.

                    When you create an account with us and use our products, we collect certain personal information to provide and improve our products and services, as well as to communicate with you about your account and any updates or changes to our terms of service. To provide you with written suggestions and optimize our products, we may also process your text and collect certain additional information, such as the type of device you are using.

                    At proposalwriter.ai, the security and protection of your personal information is of the utmost importance to us. We understand that you trust us with your information and take that responsibility seriously.

                    To ensure the security of your personal information, we have implemented various measures such as encryption, secure servers, and firewalls to protect against unauthorized access, use, or disclosure. We also regularly review and update our security practices to stay ahead of potential threats.

                    While we take every precaution to protect your personal information, it is not possible to guarantee the absolute security of data transmission or storage systems. Therefore, we cannot guarantee the complete security of your personal information. However, we will continue to work diligently to maintain the security and privacy of your information.

                    We encourage you to review this Privacy Policy regularly to stay informed about our collection and use of personal information and the choices available to you. If you have any questions or concerns about this policy, please don't hesitate to contact us. We are committed to protecting your privacy and ensuring that you have a positive experience with our products and services`}
                </Typography>
            </Stack>
        </Box>
    )
}