//REACT IMPORTS
import { Box, Button, FormHelperText, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldHelper } from '../helpers/FieldHelper';

export const CustomTaskCard = ({
                                 allTasks, setAllTasks,
                                 clientList,
                                 taskName, setTaskName,
                                 taskDescription, setTaskDescription,
                                 client, setClient, editIndex, setEditIndex,
                               }) => {
  const [taskToAdd, setTaskToAdd] = useState({});

  useEffect(() => {
    const newTask = {
      task_name: taskName,
      task_description: taskDescription,
      task_capabilities: '',
      task_tools: '',
    };

    if (client !== 'placeholder') {
      const selectedClient = clientList.find((client) => client.id === newTask.client_id);
      if (selectedClient === undefined) {
        return;
      }

      newTask.client_id = client;
      newTask.client_name = selectedClient.client_name;
      newTask.ai_summarization = selectedClient.ai_summarization;
    }

    setTaskToAdd(newTask);
  }, [taskName, taskDescription, client, clientList]);

  // useEffect(() => {
  //     console.log(allTasks)
  // }, [client])

  const pushToArray = () => {
    if (taskName.length > 0 && taskDescription.length > 0) {
      setAllTasks([...allTasks, taskToAdd]);
      setTaskName('');
      setTaskDescription('');
      setClient('placeholder');
      setEditIndex(undefined);
    }
  };

  const editArray = () => {
    let newArray = allTasks;
    newArray[editIndex] = taskToAdd;
    setAllTasks(newArray);
    setTaskName('');
    setTaskDescription('');
    setClient('placeholder');
    setEditIndex(undefined);
  };

  const cancel = () => {
    setTaskName('');
    setTaskDescription('');
    setClient('placeholder');
    setEditIndex(undefined);
  };

  return (
    <Box
      width={1}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      p={'2.4rem 1.6rem'}
      gap={'2.4rem'}
      borderRadius={'.8rem'}
      bgcolor={'grey.grey1'}>
      <Stack width={1} direction={'column'} spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Task </Typography>
          <FieldHelper
            suggestion={'Input the name of the task you want to generate content for'} />
        </Stack>
        <OutlinedInput
          fullWidth
          value={taskName}
          inputProps={{maxLength: 256}}
          onChange={(event) => setTaskName(event.target.value)}
          placeholder={'Enter task'} />
      </Stack>
      <Stack
        width={1}
        mt={'2,4rem'}
        direction={'column'}
        spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Task Description </Typography>
          <FieldHelper
            suggestion={'Input a description of the services to be performed under this task, process/methodologies to be used as well as any end users to be supported'} />
        </Stack>
        <OutlinedInput
          fullWidth
          multiline
          rows={5}
          value={taskDescription}
          inputProps={{minLength: 400, maxLength: 1500}}
          onChange={(event) => setTaskDescription(event.target.value)}
          placeholder={'Enter description about task'} />
        <FormHelperText>{`Min ${taskDescription.length < 400 ?
          400 - taskDescription.length :
          0} characters, max ${1500 - taskDescription.length} characters`}</FormHelperText>
      </Stack>

      <Stack
        width={1}
        mt={'2,4rem'}
        direction={'column'}
        spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Relevant Past Performance(s) </Typography>
          <FieldHelper
            suggestion={'Select the past performance that is relevant to this task area from the drop down'} />
        </Stack>
        <Select value={client} onChange={(event) => setClient(event.target.value)}>
          <MenuItem value={'placeholder'} disabled>Select</MenuItem>
          {clientList && clientList.map((client, index) => (
            <MenuItem key={index} value={client.client_id}>{client.client_name}</MenuItem>
          ))}
        </Select>
      </Stack>

      <Button
        fullWidth
        onClick={editIndex !== undefined ? editArray : pushToArray}
        variant={'primaryNavbar'}>
        {editIndex ? 'Save changes' : 'Save task'}
      </Button>
      {editIndex !== undefined && (
        <Button
          fullWidth
          onClick={cancel}
          variant={'secondaryNavbar'}>
          Cancel
        </Button>
      )}
    </Box>
  );
};
