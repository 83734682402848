//REACT IMPORTS
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { API } from 'aws-amplify';
import { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { POST_ADD_NEW_CLIENT_API } from '../../const';
import { FieldHelper } from '../helpers/FieldHelper';
import { InputWithBubbles } from '../input/InputWithBubbles';
import { RewriteClientDescriptionModal } from './RewriteClientDescriptionModal';

//API IMPORTS

export const CreateClientModal = ({ modalHandler, companyName, companyId, setPastPerformancesList }) => {

  //STATES
  const [roleOnContract, setRoleOnContract] = useState('');
  const [clientName, setClientName] = useState('');
  const [contractName, setContractName] = useState('');
  const [skillsUsed, setSkillsUsed] = useState([]);
  const [metricsUsed, setMetricsUsed] = useState([]);
  const [clientDescription, setClientDescription] = useState('');

  const [showDescription, setShowDescription] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [rewriting, setRewriting] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');

  //GENERATE DESCRIPTION FUNCTION
  const GenerateDescription = async () => {
    setLoadingDescription(true);

    let response = await API.post('generateClientDescription', '', {
      body: {
        company_name: companyName,
        client_name: clientName,
        role_on_contract: roleOnContract,
        contract_name: contractName,
        skills_used: skillsUsed.toString(),
        metrics_used: metricsUsed.toString()
      }
    });
    setClientDescription(response);
    setLoadingDescription(false);
    setShowDescription(true);
  };

  //RE-GENERATE DESCRIPTION FUNCTION
  const rewrite = async () => {
    setOpenRewriteModal(false);
    setRewriting(true);
    const result = await API.post('regenerateClientDescription', '', {
      body: {
        prompt: promptMessage,
        company_name: companyName,
        client_name: clientName,
        role_on_contract: roleOnContract,
        contract_name: contractName,
        skills_used: skillsUsed.toString(),
        metrics_used: metricsUsed.toString()
      }
    });
    setClientDescription(result);
    setRewriting(false);
  };

  const [openRewriteModal, setOpenRewriteModal] = useState(false);
  const RewriteHandler = () => setOpenRewriteModal(!openRewriteModal);

  //CREATE CLIENT FUNCTION
  const CreateNewClient = async () => {
    await API.post('pyBackend', POST_ADD_NEW_CLIENT_API, {
      body: {
        company_id: companyId,
        client_name: clientName,
        role_on_contract: roleOnContract,
        ai_summarization: clientDescription,
        contract_name: contractName,
        skills_used: skillsUsed.toString(),
        metrics_used: metricsUsed.toString()
      }
    });
    modalHandler();
  };

  return (
    <Box
      width={1}
      maxWidth={'66rem'}
      bgcolor={'#FFF'}
      borderRadius={'2rem'}
      gap={'1.6rem'}
      padding={'2.4rem 2.4rem 4.8rem'}
      overflow={'hidden'}
      maxHeight={'80vh'}
      sx={{
        overflowY: 'scroll'
      }}>
      <Modal open={openRewriteModal} onClose={RewriteHandler}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{ xs: '1.6rem', md: 0 }}>
          <RewriteClientDescriptionModal
            promptMessage={promptMessage}
            setPromptMessage={setPromptMessage}
            modalHandler={RewriteHandler}
            rewriteDescription={rewrite} />
        </Box>
      </Modal>
      <Box width={1} display={'flex'} justifyContent={'flex-end'}>
        <Button
          variant={'textButton'}
          endIcon={<CloseIcon />}
          onClick={() => modalHandler()}>
          Close
        </Button>
      </Box>
      <Box
        width={1}
        display={'flex'}
        flexDirection={'column'}
        gap={'4.8rem'}
        alignItems={'center'}
        px={{ xs: 0, md: '2.4rem' }}>
        <Typography textAlign={'center'} sx={{ typography: { xs: 'h3', md: 'h1' } }}>
          Add a new Client Entry
        </Typography>

        <Stack width={1} p={'1.6rem'} spacing={'1.2rem'}>
          <Stack width={1} spacing={'1.2rem'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}> Role on Contract </Typography>
              <FieldHelper
                suggestion={'Input your client’s name'} />
            </Stack>
            <RadioGroup
              value={roleOnContract}
              onChange={(event) => setRoleOnContract(event.target.value)}
              defaultValue={roleOnContract}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 1,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <FormControlLabel value='Prime' control={<Radio size={'medium'} />} label='Prime' />
              <FormControlLabel value='Subcontractor' control={<Radio size={'medium'} />} label='Subcontractor' />
              <FormControlLabel value='Corporate Experience' control={<Radio size={'medium'} />}
                label='Corp Experience' />
            </RadioGroup>
          </Stack>

          <Stack width={1} spacing={'1.2rem'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}> Customer Organization </Typography>
              <FieldHelper
                suggestion={'Input your customer organization'} />
            </Stack>
            <OutlinedInput
              fullWidth
              value={clientName}
              inputProps={{ maxLength: 256 }}
              onChange={(event) => setClientName(event.target.value)}
              placeholder={'Customer Organization'} />
          </Stack>

          <Stack width={1} spacing={'1.2rem'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}> Contract Name </Typography>
              <FieldHelper
                suggestion={'Input your contract name'} />
            </Stack>
            <OutlinedInput
              fullWidth
              value={contractName}
              inputProps={{ maxLength: 256 }}
              onChange={(event) => setContractName(event.target.value)}
              placeholder={'Jon Doe'} />
          </Stack>

          <Stack width={1} spacing={'1.2rem'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}> Insert Hardware, Tools, Skilled Used </Typography>
              <FieldHelper
                suggestion={'Input your contract name'} />
            </Stack>
            <InputWithBubbles
              placeholder={'Add Skill'}
              maxTags={1000}
              tags={skillsUsed}
              setTags={setSkillsUsed} />
            <Typography color={'grey.grey3'} variant={'s4'}>
              {'<Enter> to confirm skills, minimum one skill'}
            </Typography>
          </Stack>

          <Stack
            width={1}
            mt={'6.4rem'}
            direction={'column'}
            spacing={'1.2rem'}
            justifyContent={'start'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}>
                Provide Metrics
              </Typography>
              <FieldHelper
                suggestion={'Input corporate capabilities such as Help Desk, Software Development, Cybersecurity, etc. Hit enter after each capability. '} />
            </Stack>
            <InputWithBubbles
              placeholder={'Add metric'}
              maxTags={20}
              tags={metricsUsed}
              setTags={setMetricsUsed} />
            <Typography color={'grey.grey3'} variant={'s4'}>
              {'<Enter> to confirm metrics, minimum one metric'}
            </Typography>
          </Stack>

          <Stack width={1} spacing={'1.2rem'} display={showDescription ? 'flex' : 'none'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography color={'common.black'} variant={'s3'}> Past Performance Description </Typography>
              <FieldHelper
                suggestion={'Input your contract name'} />
            </Stack>
            <Typography
              bgcolor={'common.white'}
              borderRadius={'1.5rem'}
              padding={'1.2rem'}
              border={'.1rem solid'}
              borderColor={'grey.subtle'}
              variant={'s3'}
              fontWeight={300}
              whiteSpace={'pre-word'}>{clientDescription}</Typography>
          </Stack>

          <LoadingButton
            fullWidth
            loading={rewriting}
            variant={'secondaryMain'}
            onClick={RewriteHandler}
            disabled={metricsUsed.length === 0 || skillsUsed.length === 0}
            sx={{
              display: !showDescription ? 'none' : 'flex'
            }}
          >
            Rewrite
          </LoadingButton>
          <LoadingButton
            fullWidth
            loading={loadingDescription}
            variant={'primaryMain'}
            disabled={metricsUsed.length === 0 || skillsUsed.length === 0}
            onClick={!showDescription ? GenerateDescription : CreateNewClient}>
            {!showDescription ? 'Write Description' : 'Create'}
          </LoadingButton>
        </Stack>

        <Stack
          width={1}
          spacing={'1.6rem'}
          direction={{ xs: 'column-reverse', md: 'row' }}>
          <Button
            fullWidth
            variant={'secondaryMain'}
            onClick={() => modalHandler()}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
