import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ShreddingConfirmation = () => {

    const navigate = useNavigate();
    const [ countdown, setCountdown ] = useState(15);

    useEffect(() => {
        if(countdown === 0){
            setCountdown(null);
        }

        if(!countdown) return

        const intervalNumber = setInterval(() => {
            setCountdown(countdown - 1)
        }, 1000)

        return () => clearInterval(intervalNumber)
    }, [countdown])

    useEffect(() => {
        countdown === 0 && navigate('/users/my-shredded-documents')
    }, [countdown, navigate])
    return(
        <Box
            width={1}
            maxWidth={'75rem'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
                color={'common.black'}
                sx={{
                typography: {xs: 'h3', md: 'h2'},
                }}>
                Confirmation of Shredding Initiated
            </Typography>
            <Typography mt={'4.8rem'} textAlign={'left'} variant={'bodyLight'} whiteSpace={'pre-line'}>
                {`We have received your document and have initiated the shredding process. This phase is designed to help you organize and streamline your proposal content.

                Please be aware that this process may take some time, depending on the size and complexity of your document. Rest assured that we are working diligently to complete this phase as quickly as possible.

                Once the shredding is complete, you will receive an email notification. At that time, you can proceed to the next phase of the proposal-writing process.

                Thank you for choosing Proposalwriter.ai. We look forward to assisting you in creating a winning proposal.`}
            </Typography>

            <Typography mt={'2.4rem'} textAlign={'center'} variant={'bodyLight'}>
                Redirect to <Typography variant={'bodyLight'} color={'common.link'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/users/my-shredded-documents')}>My Shredded Documents</Typography> in {countdown}
            </Typography>
        </Box>
    )
}