import { EditRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EditProposalModal } from '../../components/modals/EditProposalModal';
import {
  GET_CLIENT_LIST_API,
  GET_PROPOSAL_DETAIL_API
} from '../../const';
import { ProposalTaskParagraph } from './components/ProposalTaskParagraph';

export const ProposalDetailPage = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [proposalData, setProposalData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const [editingProposalName, setEditingProposalName] = useState(false);
  const [proposalName, setProposalName] = useState('');

  const fetchProposalData = async () => {
    setLoading(true);
    try {
      let response = await API.get('pyBackend', `${GET_PROPOSAL_DETAIL_API}${id}`, {})
      setProposalData(response);
      console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClientList = async () => {
    try {
      let response = await API.get('pyBackend', `${GET_CLIENT_LIST_API}${id}`, {})
      setClientList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await fetchProposalData();
    await fetchClientList();
    setLoading(false);
  };

  const editTitleHandler = async () => {
    //setUpdateLoading(true);
    if (editingProposalName) {
      await API.post('pyBackend', '/proposals/update-proposal-name', {
        body: {
          proposal_id: id,
          proposal_name: proposalName,
        },
      });
    }

    setEditingProposalName(!editingProposalName);
    //setUpdateLoading(false);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (proposalData.length > 0) {
      setProposalName(proposalData[0].proposal_name);
    }
  }, [proposalData]);

  const EditModalHandler = () => setEditModal(!editModal)

  if (isLoading) {
    return (
      <Box
        width={1}
        height={1}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      width={1}
      maxWidth={'65rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Box
        width={1}
        border={{xs: 'none', md: '.1rem solid #B8BAC6'}}
        borderColor={'grey.grey3'}
        borderRadius={'.8rem'}
        p={{xs: 0, md: '2.4rem 3.2rem'}}
        px={{xs: '1.6rem'}}
        mb={'3.2rem'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}>
        {!editingProposalName
          ?
          <Typography
            color={'common.black'}
            textAlign={'center'}
            sx={{
              typography: {xs: 'h4', md: 'h2'},
            }}>
            {proposalName}
          </Typography>
          :
          <OutlinedInput
            fullWidth
            value={proposalName}
            onChange={(event) => setProposalName(event.target.value)} />
        }
        <IconButton
          onClick={() => editTitleHandler()}>
          <EditRounded />
        </IconButton>
      </Box>

      <Box
        width={1}
        maxWidth={'65rem'}
        display={'flex'}
        flexDirection={'column'}
        gap={'2.8rem'}
        mb={'6.4rem'}
        overflow={'hidden'}
        px={{xs: '1.6rem', md: 0}}>
        {proposalData && proposalData.map((aiTask, index) => (
          <ProposalTaskParagraph
            key={index}
            aiTask={aiTask}
            clientList={clientList}
            modalHandler={EditModalHandler} />
        ))}
      </Box>

      <Stack
        width={1}
        mt={'6.4rem'}
        mb={{xs: '4.8rem', md: '16rem'}}
        direction={'column'}
        spacing={'1.6rem'}>
        <Button
          fullWidth
          variant={'primaryMain'}
          onClick={() => navigate('/users/my-proposals')}>
          My Proposals
        </Button>
      </Stack>
    </Box>
  );
};
