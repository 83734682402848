//REACT IMPORTS
import { TaskSharp } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { ConfirmationCard } from "../../../components/cards/ConfirmationCard";

//COMPONENT IMPORTS

export const StepFive = ({companyName, primaryIndustry, pointOfContact, primaryCode, secondaryCodes, keywords, setStep, pastPerformances}) => {
    return(
        <Box
            width={1}
            maxWidth={'50rem'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            gap={'2.4rem'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                direction={'column'}
                textAlign={'center'}
                mb={{xs: '3.2rem', md: '8rem'}}>
                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Confirm your inputs
                </Typography>
            </Stack>

            <ConfirmationCard
                sectionTitle={'Company Information'}
                step={0}
                setStep={setStep}
                contentList={[
                    {
                        title: 'Company Name',
                        subtitle: companyName ? companyName : 'Company Name'
                    },
                    {
                        title: 'Company Primary Industry',
                        subtitle: primaryIndustry ? primaryIndustry : 'Primary Industry'
                    },
                    {
                        title: 'Point Of Contact',
                        subtitle: pointOfContact ? pointOfContact : 'John Doe'
                    }
                ]} />
                <ConfirmationCard
                    sectionTitle={'NAICS Codes'}
                    step={1}
                    setStep={setStep}
                    contentList={[
                        {
                            title: 'Primary NAICS Code',
                            subtitle: primaryCode ? primaryCode : 'Primary Code'
                        },
                        {
                            title: 'Secondary NAICS Codes',
                            subtitle: secondaryCodes ? secondaryCodes.join(', ') : 'Secondary Codes'
                        },
                    ]} />
                <ConfirmationCard
                    sectionTitle={'Skills'}
                    step={2}
                    setStep={setStep}
                    contentList={[
                        {
                            title: 'Keywords',
                            subtitle: keywords ? keywords.join(', ') : 'Keywords'
                        },
                    ]} />

                <ConfirmationCard
                    sectionTitle={'Past Performances'}
                    step={3}
                    setStep={setStep}
                    contentList={pastPerformances} />
        </Box>
    )
}
