import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SpinningLoader from '../components/helpers/SpinningLoader';

const NotFound = () => {
  const navigate = useNavigate();

  const redirect = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      navigate('/users/my-proposals');
    } catch {
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    redirect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinningLoader />
  );
};

export default NotFound;
