import { Box, CircularProgress } from '@mui/material';

import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useUserStore } from '@/store/userStore';

export const LoadingPayment = () => {
  const [timeoutID, setTimeoutID] = useState(0);

  const setProduct = useUserStore((state) => state.setProduct);

  const loadPayment = async () => {
    try {
      let infoUrl = '/info';
      const selectedCompany = localStorage.getItem('localCompanyId');

      if (selectedCompany) {
        infoUrl = `/info?company_id=${selectedCompany}`;
      }

      const res = await API.get('api2e29c07a', infoUrl);
      if (res.product && Object.keys(res.product).length > 0) {
        await setProduct(res.product);
        console.log('product found, redirecting');
        window.location.href = '/users/my-proposals';
      } else {
        const tid = setTimeout(loadPayment, 5000)
        setTimeoutID(tid);
      }
    } catch (e) {
      console.log(e);
    }

  };

  useEffect(() => {
    loadPayment();

    return () => {
      clearTimeout(timeoutID);
    }
  }, []);

  return (
    <Box
      width={1}
      mt={{ xs: 0, md: '-6rem' }}
      maxWidth={'65rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Box
        sx={{
          textAlign: 'center',
          display: 'block'
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};
