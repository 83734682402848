import { Box, Divider, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { FieldHelper } from "../helpers/FieldHelper"
import { FileInput } from '../input/FileInput';
import { InputWithBubbles } from '../input/InputWithBubbles'

export const ShredDocumentsCard = ({props}) => {
  const {
    files, setFiles,
    sectionLTitle, setSectionLTitle,
    sectionLTitleFile, setSectionLTitleFile,
    sectionLPage, setSectionLPage,
    sectionMTitle, setSectionMTitle,
    sectionMTitleFile, setSectionMTitleFile,
    sectionMPage, setSectionMPage,
    sectionCTitle, setSectionCTitle,
    sectionCTitleFile, setSectionCTitleFile,
    sectionCPage, setSectionCPage,
    primaryKeywords, setPrimaryKeywords,
    secondaryKeywords, setSecondaryKeywords,
    additionalKeywords, setAdditionalKeywords,
  } = props;
    return(
        <Box
            width={1}
            bgcolor={'grey.grey1'}
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            borderRadius={'.8rem'}
            display={'flex'}
            flexDirection={'column'}
            gap={'1.6rem'}
            p={'2.4rem'}>
            <Stack width={1} spacing={'2.4rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'h6'}> Upload Documents (ie RFP, RFQ, SOW) </Typography>
                    <FieldHelper
                        suggestion={'Click the upload and all the documents need to plan and write the proposal .pdf only!'} />
                </Stack>
                <FileInput
                  placeholder={'Upload Documents (.pdf only)'}
                  files={files}
                  setFiles={setFiles}
                />
            </Stack>
            <Typography variant={'s3'}>
                We will use the following keywords to scan the document. Remove or Add as needed:
            </Typography>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Primary: </Typography>
                    <FieldHelper
                        suggestion={'The primary words the tool will be looking for'} />
                </Stack>
                <InputWithBubbles
                  tags={primaryKeywords}
                  setTags={setPrimaryKeywords}
                  maxTags={1000}
                  placeholder={'Add other'} />
            </Stack>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Secondary: </Typography>
                    <FieldHelper
                        suggestion={'The second words the tool will be looking for'} />
                </Stack>
                <InputWithBubbles
                  tags={secondaryKeywords}
                  setTags={setSecondaryKeywords}
                  maxTags={1000}
                  placeholder={'Add other'} />
            </Stack>
            <Stack width={1} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Additional words to add: </Typography>
                    <FieldHelper
                        suggestion={'Additional words the tool will be looking for'} />
                </Stack>
                <InputWithBubbles
                  tags={additionalKeywords}
                  setTags={setAdditionalKeywords}
                  maxTags={1000}
                  placeholder={'Add other'} />
            </Stack>
            <Typography variant={'s3'}>
                Compliance Matrix and Proposal Template (Shedding) Data:
            </Typography>
            <Typography variant={'h6'}>
                Section L&M
            </Typography>
            <Stack spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> What is the Title for Section L or the Instructions: </Typography>
                    <FieldHelper
                        suggestion={'Give the title of the section that provides instructions and select the document where the information can be found'} />
                </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                  <OutlinedInput
                    fullWidth
                    placeholder={''}
                    value={sectionLTitle}
                    onChange={(e) => setSectionLTitle(e.target.value)}
                  />
                  <Select
                    fullWidth
                    sx={{
                      maxWidth: '50%',
                      height: '6.4rem',
                      borderRadius: '1.5rem',
                      borderColor: 'grey.grey3',
                      backgroundColor: 'common.white',
                      color: 'grey.main',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.03)'
                      }
                    }}
                    value={sectionLTitleFile}
                    onChange={(event) => setSectionLTitleFile(event.target.value)}
                  >
                    <MenuItem disabled>Select Doc</MenuItem>
                    {files && files.map((file, index) => (
                      <MenuItem key={index} value={file.name}>{file.name}</MenuItem>
                    ))}
                  </Select>
                </Stack>
            </Stack>
            <Stack spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> What is the Page(s) is Section L or the Instructions: </Typography>
                    <FieldHelper
                        suggestion={'give the page number(s) that give all the instructions. Example: 15-16'} />
                </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                  <OutlinedInput
                    fullWidth
                    placeholder={''}
                    value={sectionLPage}
                    onChange={(e) => setSectionLPage(e.target.value)}
                  />
                </Stack>
            </Stack>
            <Divider flexItem />
            <Stack spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> What is the Title for Section M or the Evaluation: </Typography>
                    <FieldHelper
                        suggestion={'Give the title of the section that provides instructions and select the document where the information can be found'} />
                </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                  <OutlinedInput
                    fullWidth
                    placeholder={''}
                    value={sectionMTitle}
                    onChange={(e) => setSectionMTitle(e.target.value)}
                  />
                  <Select
                    fullWidth
                    sx={{
                      maxWidth: '50%',
                      height: '6.4rem',
                      borderRadius: '1.5rem',
                      borderColor: 'grey.grey3',
                      backgroundColor: 'common.white',
                      color: 'grey.main',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.03)'
                      }
                    }}
                    value={sectionMTitleFile}
                    onChange={(event) => setSectionMTitleFile(event.target.value)}
                  >
                    <MenuItem disabled>Select Doc</MenuItem>
                    {files && files.map((file, index) => (
                      <MenuItem key={index} value={file.name}>{file.name}</MenuItem>
                    ))}
                  </Select>
                </Stack>
            </Stack>
            <Stack spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> What is the Page(s) is Section M or the Instructions: </Typography>
                    <FieldHelper
                        suggestion={'give the page number(s) that give all the instructions. Example: 15-16'} />
                </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                  <OutlinedInput
                    fullWidth
                    placeholder={''}
                    value={sectionMPage}
                    onChange={(e) => setSectionMPage(e.target.value)}
                  />
                </Stack>
            </Stack>
            <Stack spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography variant={'h6'}>
                  Section C - <Typography variant={'s3'}>What Section Title for Section C or the SOW:</Typography>
              </Typography>
              <FieldHelper
                    suggestion={'Give the title of the section that provides the statement of work or tasks and select the document where the information can be found'} />
            </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                  <OutlinedInput
                    fullWidth
                    placeholder={''}
                    value={sectionCTitle}
                    onChange={(e) => setSectionCTitle(e.target.value)}
                  />
                  <Select
                    fullWidth
                    sx={{
                      maxWidth: '50%',
                      height: '6.4rem',
                      borderRadius: '1.5rem',
                      borderColor: 'grey.grey3',
                      backgroundColor: 'common.white',
                      color: 'grey.main',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.03)'
                      }
                    }}
                    value={sectionCTitleFile}
                    onChange={(event) => setSectionCTitleFile(event.target.value)}
                  >
                    <MenuItem disabled>Select Doc</MenuItem>
                    {files && files.map((file, index) => (
                      <MenuItem key={index} value={file.name}>{file.name}</MenuItem>
                    ))}
                  </Select>
                </Stack>
            </Stack>
            <Stack spacing={'1.2rem'}>
              <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
              <Typography variant={'s3'}>What is the Page(s) for Section C or the SOW:</Typography>
                  <FieldHelper
                      suggestion={'Give the page number(s) that provides the statement of work or tasks. Example: 15-16'} />
              </Stack>
              <Stack direction={'row'} spacing={'1.2rem'}>
                <OutlinedInput
                  fullWidth
                  placeholder={''}
                  value={sectionCPage}
                  onChange={(e) => setSectionCPage(e.target.value)}
                />
              </Stack>
            </Stack>
        </Box>
    )
}
