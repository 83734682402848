import { Box, Stack, Typography } from "@mui/material"
import { FieldHelper } from "../../../components/helpers/FieldHelper";
import { TagsInput } from "../../../components/input/TagsInput"

export const StepThree = ({capabilityTags, setCapabilityTags}) => {

    return(
        <Box
            width={1}
            maxWidth={'50rem'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography color={'common.black'} textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                List your core capabilities
            </Typography>

            <Stack
                width={1}
                mt={'6.4rem'}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}>
                        Corporate Capabilities (Up to 20)
                    </Typography>
                    <FieldHelper
                        suggestion={'Input corporate capabilities such as Help Desk, Software Development, Cybersecurity, etc. Hit enter after each capability. '} />
                </Stack>
                <TagsInput
                    placeholder={'Add Skill'}
                    maxTags={20}
                    inputType={'text'}
                    tags={capabilityTags}
                    setTags={setCapabilityTags} />
                <Typography color={'grey.grey3'} variant={'s4'}>
                    {'<Enter> to confirm Capabilities'}
                </Typography>
            </Stack>
        </Box>
    )
}