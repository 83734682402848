import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const ProposalPreparation = () => {

    const navigate = useNavigate();
    return(
        <Box
            width={1}
            maxWidth={'65rem'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
                color={'common.black'}
                sx={{
                typography: {xs: 'h3', md: 'h1'},
                }}>
                Proposal Preparation
            </Typography>
            <Typography mt={'4.8rem'} textAlign={'left'} variant={'bodyLight'} whiteSpace={'pre-line'}>
                Before continuing, please note the following steps are based in best practices for proposal writing. The proposal preparation process is divided into three phases: Planing & Shredding, Technical Volume Development and Proposal Generation. These steps will help you organize and compose each section of your proposal, but it is important that you review and approve all content before submitting your final proposal.
                Keep in mind that you will need to purchase another proposal token to generate additional proposals.
            </Typography>
            <Typography mt={'2.4rem'} textAlign={'left'} variant={'bodyLight'} whiteSpace={'pre-line'}>
                {`To begin your project, you will need:

                1. The RFP or RFI document.
                2. The page numbers for the major section (such as Section L, M and C), or any others areas you want to highlight.
                3. The subheaders or titles or titles for the technical volume task areas.`}
            </Typography>
            <Button fullWidth variant={'primaryMain'} sx={{ my: '4.8rem', maxWidth: '30rem'}} onClick={() => navigate('/users/shred-document')}>
                Create Proposal
            </Button>
        </Box>
    )
}