import { Box, Typography } from "@mui/material"
import { CustomTaskConfirmCard } from "../../../components/cards/CustomTaskConfirmCard"

export const ProposalFromDocStepThree = ({taskList, setStep, selectedTasks}) => {
    return(
        <Box
            width={1}
            display={'flex'}
            flexDirection={'column'}
            gap={'1.2rem'}>
            <Typography variant={'h2'} textAlign={'center'}>Confirm your inputs</Typography>

            <CustomTaskConfirmCard
                selectedTasks={selectedTasks}
                setStep={setStep}
                step={0} />
            <CustomTaskConfirmCard
                taskList={taskList}
                setStep={setStep}
                step={1} />
        </Box>
    )
}