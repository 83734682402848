//REACT IMPORTS
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate, useLocation } from 'react-router-dom';

//COMPONENT IMPORTS
import { NBOnlyLogo } from '../components/navbar/NBOnlyLogo';
import SpinningLoader from '../components/helpers/SpinningLoader';

export const AuthLayout = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const isHomePage = !!useMatch('/');
  const loadingAnim = require('../assets/images/loading-animation.gif');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/users/my-proposals' + search);
      } catch (err) {
        console.log(err);
        isHomePage && navigate('/sign-in' + search);
      }
      setLoading(false);
    };
    checkUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <SpinningLoader />;
  }

  return (
    <>
      <Box
        width={'100vw'}
        display={isHomePage ? 'none' : 'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={{xs: '2.2rem', md: '8rem'}}>
        <NBOnlyLogo />
        <Outlet />
      </Box>
      <Box
        width={'100vw'}
        height={'100vh'}
        display={isHomePage ? 'flex' : 'none'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={{xs: '2.2rem', md: '8rem'}}>
        <Box
          component={'img'}
          width={'5.6rem'}
          src={loadingAnim} />
      </Box>
    </>
  );
};
