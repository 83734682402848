//REACT IMPORTS
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ProposalDetail = () => {

    const navigate = useNavigate();
    const [ countdown, setCountdown ] = useState(15);

    useEffect(() => {
        if(countdown === 0){
            setCountdown(null);
        }

        if(!countdown) return

        const intervalNumber = setInterval(() => {
            setCountdown(countdown - 1)
        }, 1000)

        return () => clearInterval(intervalNumber)
    }, [countdown])

    useEffect(() => {
        countdown === 0 && navigate('/users/my-proposals')
    }, [countdown, navigate])



    return(
        <Box
            width={1}
            maxWidth={'65rem'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>

                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{
                        typography: {xs: 'h3', md: 'h1'}
                    }}>
                    Disclaimer
                </Typography>

                <Typography mt={'10rem'} textAlign={'center'} variant={'bodyLight'} whiteSpace={'pre-line'}>
                    Depending on the number of tasks your proposal has, generation time can exceed 10 minutes in time. An email will be sent once it is complete. Additionally, <Typography component={'a'} variant={'bodyLight'} color={'common.link'} sx={{ textDecoration: 'none' }} href={'https://www.proposalwriter.ai/'}>ProposalWriter.ai</Typography> makes no guarantees nor does it bear any responsibility for wins or losses of contracts. All proposals should be reviewed for accuracy and compliance. <Typography component={'a'} variant={'bodyLight'} color={'common.link'} sx={{ textDecoration: 'none' }} href={'https://www.proposalwriter.ai/'}>ProposalWriter.ai</Typography>, should be used as a tool to support your current proposal development process.
                </Typography>

                <Typography mt={'2.4rem'} textAlign={'center'} variant={'bodyLight'}>
                    Redirect to <Typography variant={'bodyLight'} color={'common.link'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/users/my-proposals')}>My Proposals</Typography> in {countdown}
                </Typography>

        </Box>
    )
}