import { useRoutes } from 'react-router-dom';
import { AuthLayout } from './layout/AuthLayout';
import { UserLayout } from './layout/UserLayout';
import { AccountSettings } from './views/account/AccountSettings';
import { Accounts } from './views/admin/Accounts';
import { ChangePassword } from './views/auth/ChangePassword';
import { ChangePasswordSuccess } from './views/auth/ChangePasswordSuccess';
import { EmailConfirmation } from './views/auth/EmailConfirmation';
import { ForgotPassword } from './views/auth/ForgotPassword';
import { SignIn } from './views/auth/SignIn';
import { VerifyEmail } from './views/auth/VerifyEmail';
import { CompleteProfile } from './views/completeProfile/CompleteProfile';
import { CreateProposal } from './views/users/CreateProposal';
import { MyProposals } from './views/users/MyProposals';
import { ProposalDetailPage } from './views/users/ProposalDetailPage';
import NotFound from './views/NotFound';
import { LegalLayout } from './layout/LegalLayout';
import { TermsOfService } from './views/legalSection/TermsOfService';
import { AcceptableUsePolicy } from './views/legalSection/AcceptableUsePolicy';
import { PrivacyPolicy } from './views/legalSection/PrivacyPolicy';
import { ProposalPreparation } from './views/users/ProposalPreparation';
import { ShreddingConfirmation } from './views/users/ShreddingConfirmation';
import { MyShreddedDocuments } from './views/users/MyShreddedDocuments';
import { ShredDocuments } from './views/users/ShredDocuments';
import { CreateProposalFromDoc } from './views/users/CreateProposalFromDoc';
import { MyPastPerformances } from './views/users/MyPastPerformances';
import { AffiliatesPage } from './extras/AffiliatesPage';
import { ProposalDetail } from './views/users/ProposalDetail';
import { AdminLayout } from './layout/AdminLayout';
import { Companies } from './views/admin/Companies';
import { Demo } from '@/views/demo/Demo';
import { LoadingPayment } from '@/views/completeProfile/LoadingPayment';

export default function Routes() {
  return useRoutes([
    {
      path: '/demo',
      element: <Demo />
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: 'sign-in',
          element: <SignIn />,
        },
        {
          path: 'email-confirmation',
          element: <EmailConfirmation />,
        },
        {
          path: 'verify-email',
          element: <VerifyEmail />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'change-password',
          element: <ChangePassword />,
        },
        {
          path: 'change-password-success',
          element: <ChangePasswordSuccess />,
        },
      ],
    },
    {
      path: 'users',
      element: <UserLayout />,
      children: [
        {
          index: true,
          element: <MyProposals />,
        },
        {
          path: 'my-proposals',
          element: <MyProposals />,
        },
        {
          path: 'create-proposal',
          element: <CreateProposal />,
        },
        {
          path: 'proposal-detail',
          element: <ProposalDetail />,
        },
        {
          path: 'proposal-detail/:id',
          element: <ProposalDetailPage />,
        },
        {
          path: 'account-settings',
          element: <AccountSettings />,
        },
        {
          path: 'complete-profile',
          element: <CompleteProfile />,
        },
        {
          path: 'process-payment',
          element: <LoadingPayment />,
        },
        {
          path: 'my-past-performances',
          element: <MyPastPerformances />,
        },
        {
          path: 'affiliates',
          element: <AffiliatesPage />,
        },
        {
          path: 'proposal-preparation',
          element: <ProposalPreparation />,
        },
        {
          path: 'shred-document',
          element: <ShredDocuments />,
        },
        {
          path: 'shredding-confirmation',
          element: <ShreddingConfirmation />,
        },
        {
          path: 'my-shredded-documents',
          element: <MyShreddedDocuments />,
        },
        {
          path: 'shredding-confirmation',
          element: <ShreddingConfirmation />,
        },
        {
          path: 'my-shredded-documents',
          element: <MyShreddedDocuments />,
        },
        {
          path: 'create-proposal-from-doc/:id',
          element: <CreateProposalFromDoc />,
        },
        {
          path: 'admin',
          element: <AdminLayout />,
          children: [
            {
              index: true,
              element: <Accounts />,
            },
            {
              path: 'accounts',
              element: <Accounts />,
            },
            {
              path: 'companies',
              element: <Companies />,
            },
          ],
        },
      ],
    },
    {
      path: 'legal',
      element: <LegalLayout />,
      children: [
        {
          path: 'terms-of-service',
          element: <TermsOfService />,
        },
        {
          path: 'acceptable-use-policy',
          element: <AcceptableUsePolicy />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
};
