export const light = {
  mode: 'light',
  common: {
    black: '#222222',
    white: '#FFF',
    link: '#3872E0',
    success: '#34A853',
    error: '#DA3448',
    errorLight: '#DC4053',
  },
  grey: {
    main: '#8F909D',
    graphite: '#272526',
    grey1: '#F4F4F4',
    grey2: '#5A5B62',
    grey3: '#B8BAC6',
    subtle: '#DDDDDD',
    offWhite: '#F7F8FA',
  },
  primary: {
    main: '#E5B540',
    light: '#F4BA5D',
    hover: '#ffe39e',
  },
};

export const dark = {
  mode: 'dark',
};
