import {Box, IconButton, Menu, MenuItem, Modal, Stack, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useState} from 'react';
import {EditTimeUsedModal} from '../../../components/modals/EditTimeUsedModal';
import {EditProposalCountModal} from '../../../components/modals/EditProposalCountModal';

export const CompanyListItem = ({
                                    businessSize,
                                    companyId,
                                    email,
                                    name,
                                    proposalsLeft,
                                    regenerationsLeft,
                                    typeAccount,
                                    timeUsed,
                                    fetchData
                                }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [timeUsedModal, setTimeUsedModal] = useState(false);
    const [proposalsModal, setProposalsModal] = useState(false);


    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack
            width={1}
            height={{xs: '8rem', md: '4.8rem'}}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            border={{xs: '.1rem solid #F4F4F4', md: 'none'}}
            borderRadius={'.8rem'}
        >
            <Modal open={timeUsedModal} onClose={() => setTimeUsedModal(false)}>
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    px={{xs: '1.6rem', md: 0}}>
                    <EditTimeUsedModal
                        fetchData={fetchData}
                        setTimeUsedModal={setTimeUsedModal}
                        timeUsed={timeUsed}
                        companyId={companyId}
                    />

                </Box>
            </Modal>
            <Modal open={proposalsModal} onClose={() => setProposalsModal(false)}>
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    px={{xs: '1.6rem', md: 0}}>
                    <EditProposalCountModal
                        fetchData={fetchData}
                        setProposalsModal={setProposalsModal}
                        proposalsLeft={proposalsLeft}
                        regenerationsLeft={regenerationsLeft}
                        companyId={companyId}
                    />

                </Box>
            </Modal>


            <Box
                width={'15%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'left'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {companyId}
                </Typography>
            </Box>
            <Box
                width={'25%'}
                ml={{xs: '1rem', md: 0}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {email}
                </Typography>
            </Box>
            <Box
                width={{xs: '18rem', md: '10%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                overflow={'hidden'}
                sx={{cursor: 'pointer'}}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}
                    noWrap
                    textOverflow={'ellipsis'}>
                    {name}
                </Typography>
            </Box>
            <Box
                width={'15%'}
                height={1}
                display={{xs: 'none', md: 'flex'}}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    color={'common.black'}
                    variant={'body'}>
                    {businessSize}
                </Typography>
            </Box>
            <Box
                width={{xs: '8.4rem', md: '10%'}}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={{xs: 'flex-end', md: 'center'}}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={{xs: 'right', md: 'center'}}
                    color={'common.black'}
                    sx={{
                        typography: {xs: 'bodySmall', md: 'body'},
                    }}>
                    {proposalsLeft}
                </Typography>
            </Box>
            <Box
                width={{xs: '8.4rem', md: '10%'}}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={{xs: 'flex-end', md: 'center'}}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={{xs: 'right', md: 'center'}}
                    color={'common.black'}
                    sx={{
                        typography: {xs: 'bodySmall', md: 'body'},
                    }}>
                    {regenerationsLeft}
                </Typography>
            </Box>
            <Box
                width={{xs: '8.4rem', md: '10%'}}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                alignItems={{xs: 'flex-end', md: 'center'}}
                justifyContent={'center'}>
                <Typography
                    width={1}
                    px={'1.6rem'}
                    textAlign={{xs: 'right', md: 'center'}}
                    color={'common.black'}
                    sx={{
                        typography: {xs: 'bodySmall', md: 'body'},
                    }}>
                    {typeAccount}
                </Typography>
            </Box>
            <Box
                width={{xs: '4.4rem', md: '5%'}}
                height={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Box
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>
                        <Typography
                            sx={{
                                width: 1,
                                cursor: 'pointer',
                            }}
                            onClick={() => setProposalsModal(true)}
                            color={'common.black'}
                            variant={'body'}>
                            Edit Proposal Count
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Typography
                            sx={{
                                width: 1,
                                cursor: 'pointer',
                            }}
                            onClick={() => setTimeUsedModal(true)}
                            color={'common.black'}
                            variant={'body'}>
                            Edit Time Used
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Stack>
    );
};
