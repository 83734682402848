import { Box, Stack, Typography } from "@mui/material"

export const AcceptableUsePolicy = () => {
    return(
        <Box
            width={1}
            maxWidth={'70rem'}
            textAlign={'justify'}
            display={'flex'}
            gap={'2.4rem'}
            mb={'7rem'}
            flexDirection={'column'}>
            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>ProposalWriter.AI Acceptable Use Policy</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Proposal Writer AI’s services are used by millions of people. For those services to work for everyone, we trust our users to use our services responsibly. (Note: When we refer to our “services,” we mean all of our products and services, including our apps, and websites)

                    When you use our services, you are agreeing not to misuse them or help anyone else to do so. For example, you must not do, and not attempt or permit anyone else to do, any of the following in connection with our services:`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Disruption and Circumvention</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`  • Do not probe, breach, circumvent, scan, or test the vulnerability of our services. You may only do so if it is part of an authorized bug bounty program. If you are blocked by ProposalWriter.AI from accessing our services (for example, by blocking your IP address), you may not circumvent or attempt to circumvent such blocking (for example, by masking your IP address or using a proxy IP address).
                        • Do not interfere or attempt to interfere with service to any user, host, or network, including, without limitation, by means of submitting malicious software or computer code, load testing, overloading, “flooding,” “spamming,” “mail bombing,” or “crashing.”
                        • Do not access or derive the source code or architecture of any of our services.
                        • Do not attempt to derive any underlying components of ProposalWriter.AI’s models, algorithms, or systems.
                        • Do not access content or data not intended for you, log onto a server or account that you are not authorized to access, or otherwise violate or attempt to violate any security or authentication feature or measures of our services.
                        • Do not access, search, or create accounts for our services by any means other than our publicly supported interfaces. For example, you may not scrape or create accounts in bulk.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Wrongful Activities</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`  • Do not use our services for any illegal purpose, or in violation of any laws, including, without limitation, the US Foreign Corrupt Practices Act of 1977, EU, UK, and Swiss Data Protection Laws as amended, the UK Bribery Act of 2010, or any additional applicable anti-bribery, anti-corruption, anti-money laundering law, or other law.
                        • Do not misuse our intellectual property. You may not, for example, copy, modify, or create derivative works based on our intellectual property or distribute, transmit, publish, or otherwise disseminate any of our intellectual property. You also may not download or store any of our intellectual property except to the extent we have permitted it.
                        • Do not access or use our services for the benefit of any third party unless you are an authorized reseller and are doing so for a legitimate business purpose.
                        • Do not impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.
                        • Do not sell, resell, or lease our services or purport to grant any rights under our terms of service to third parties.
                        • Do not use or access any of our services in order to build a competitive product, service, or solution.
                        • Do not harass or abuse ProposalWriter.AI personnel or representatives or agents performing services on behalf of ProposalWriter.AI.
                        • Do not abuse referrals or promotions.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Inappropriate Content</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`  • Do not transmit or upload any content that: (i) infringes any intellectual property or other legal rights of any party; (ii) you do not have the right to upload under any law; (iii) contains any malicious software or computer code; (iv) poses or creates a privacy or security risk to any person or entity; (v) constitutes unsolicited or unauthorized materials; or (vi) is otherwise objectionable.
                        • Do not submit offensive content to our expert writing service. Our writing experts may reject any order that contains offensive language or subject matter. While we recognize that views differ regarding what may be considered offensive, we do not recommend submitting content that may offend a general audience.
                        • Do not transmit or upload classified information to our services. Classified information is strictly prohibited, and violators will be denied further access to our app.
                        • We reserve the right to terminate or restrict your access to our services if you violate these terms. If you have any questions or concerns about our acceptable use policy, please contact us. By using our services, you agree to be bound by this policy.`}
                </Typography>
            </Stack>
        </Box>
    )
}