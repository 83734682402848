import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

export const CreateProposalItem = ({
                                     taskIndex,
                                     taskSubTitle,
                                     taskSubSubTitle,
                                     taskActivity,
                                     clientList,
                                     selectedTasks,
                                     setSelectedTasks
                                   }) => {
  const [pastPerformance, setPastPerformance] = useState('placeholder');
  const [checked, setChecked] = useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    setSelectedTasks(prevState => ({
      ...prevState,
      [taskIndex]: {
        ...prevState[taskIndex],
        checked: event.target.checked,
        pastPerformance
      }
    }));
  };

  const handlePastPerformance = (event) => {
    setPastPerformance(event.target.value);
    setSelectedTasks(prevState => ({
      ...prevState,
      [taskIndex]: {
        ...prevState[taskIndex],
        checked,
        pastPerformance: event.target.value
      }
    }));
  };

  useEffect(() => {
    if (selectedTasks[taskIndex]) {
      setChecked(selectedTasks[taskIndex].checked);
      setPastPerformance(selectedTasks[taskIndex].pastPerformance);
    }
  }, [selectedTasks]);

  return (
    <>
      <Stack
        width={1}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'relative'}
        my={'1.2rem'}
      >
        <Box
          width={'10%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}>
          <FormControlLabel control={<Checkbox checked={checked} onChange={handleChecked} />} />
        </Box>
        <Box
          width={'15%'}
          ml={{ xs: '1rem', md: 0 }}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            width={1}
            px={'1.6rem'}
            textAlign={'center'}
            variant={'body'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical'
            }}
            title={taskSubTitle ? taskSubTitle : 'Task Sub-Title'}
          >
            {taskSubTitle ? taskSubTitle : 'Task Sub-Title'}
          </Typography>
        </Box>
        <Box
          width={'15%'}
          ml={{ xs: '1rem', md: 0 }}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            width={1}
            px={'1.6rem'}
            textAlign={'center'}
            variant={'body'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical'
            }}
            title={taskSubSubTitle ? taskSubSubTitle : 'Task Sub-Sub-Title'}
          >
            {taskSubSubTitle ? taskSubSubTitle : 'Task Sub-Sub-Title'}
          </Typography>
        </Box>
        <Box
          width={'30%'}
          ml={{ xs: '1rem', md: 0 }}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            width={1}
            px={'1.6rem'}
            textAlign={'left'}
            variant={'body'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical'
            }}
            title={taskActivity ? taskActivity : 'Task Activity'}
          >
            {taskActivity ? taskActivity : 'Task Activity'}
          </Typography>
        </Box>
        <Box
          width={'30%'}
          ml={{ xs: '1rem', md: 0 }}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <FormControl
            sx={{ width: '80%' }}
          >
            <Select
              id={'pastPerformance'}
              value={pastPerformance}
              onChange={handlePastPerformance}
            >
              <MenuItem value='placeholder' sx={{
                color: '#999999',
                '&.Mui-selected': {
                  color: '#ffffff'
                },
                '&:hover': {
                  color: '#999999'
                }
              }}>
                Select
              </MenuItem>
              {clientList && clientList.map((client, index) => (
                <MenuItem key={index} value={client.client_id}>{client.client_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Divider flexItem />
    </>
  );
};
