import { Box, Stack, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

export const VerifyEmail = () => {
    return(
        <Box
            width={1}
            height={'calc(80vh - 16rem)'}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                spacing={'4rem'}>
                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{
                        typography: {xs: 'h3', md: 'h1'}
                    }}>
                    Verify email
                </Typography>

                <Box
                    display={'flex'}
                    flexDirection={{xs: 'column', md: 'row'}}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'1rem'}
                    color={'common.success'}>
                    <CheckCircleOutlineRoundedIcon fontSize="large" />
                    <Typography
                        variant={'bodyLight'}
                        textAlign={'center'}>
                        Check your email. We’ve sent a link so you can change your password.
                    </Typography>
                </Box>
            </Stack>
        </Box>
    )
}
