//REACT IMPORTS
import { Box, Typography } from '@mui/material';

//ICON IMPORTS
import { ReactComponent as BrandLogo } from '../../assets/icons/brand-logo.svg';
import { Link } from 'react-router-dom';

export const NBOnlyLogo = () => {
  return (
    <Box
      width={1}
      height={{xs: '6rem', md: '8rem'}}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      borderBottom={'.1rem solid #D4D4D4'}>
      <a
        href={'https://www.proposalwriter.ai/'}
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'common.black',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'1rem'}
          sx={{}}
        >
          <Box
            overflow={'hidden'}
            width={{xs: '3rem', md: '3.5rem'}}
            height={{xs: '3.5rem', md: '4rem'}}>
            <BrandLogo style={{width: '100%', height: '100%'}} />
          </Box>
          <Typography
            fontWeight={600}
            color={'common.black'}
            fontSize={{xs: '1.2rem', md: '1.4rem'}}
            lineHeight={{xs: '1.8rem', md: '2rem'}}>
            Proposal Writer Ai
          </Typography>
        </Box>
      </a>
    </Box>
  );
};
