import { Box, Stack, Typography } from "@mui/material"

export const CustomTaskConfirmCard = ({setStep, step, selectedTasks, taskList}) => {

    return(
        <Box
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            borderRadius={'1.5rem'}
            padding={'2.4rem'}>
            <Stack
                width={1}
                direction={'row'}
                justifyContent={'space-between'}>
            <Typography variant={'h5'}>{selectedTasks ? 'Selected Tasks' : 'Custom Tasks'}</Typography>
            <Typography
                bgcolor={'primary.main'}
                p={'.8rem 1.6rem'}
                borderRadius={'.8rem'}
                variant={'s3'}
                fontWeight={700}
                sx={{
                    cursor: 'pointer'
                }}
                onClick={() => setStep(step)}
                >EDIT</Typography>
            </Stack>

            {selectedTasks && selectedTasks.map((item, index) => (
                <Stack
                    key={index}
                    bgcolor={'grey.offWhite'}
                    border={'.1rem solid'}
                    borderColor={'grey.subtle'}
                    padding={'1.2rem'}
                    borderRadius={'.8rem'}
                    mt={'2.4rem'}
                    spacing={'.8rem'}>
                    <Typography color={'grey.main'} variant={'body'}>{'Task Subtitle'}</Typography>
                    <Typography
                        variant={'bodyLight'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                          }}
                        >{item.task_subtittle || 'Empty'}
                    </Typography>
                    <Typography color={'grey.main'} variant={'body'}>{'Task Text'}</Typography>
                    <Typography
                        variant={'bodyLight'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical"
                          }}
                        >{item.task_text || 'Empty'}
                    </Typography>
                </Stack>
            ))}
            {taskList && taskList.map((item, index) => (
                <Stack
                    key={index}
                    bgcolor={'grey.offWhite'}
                    border={'.1rem solid'}
                    borderColor={'grey.subtle'}
                    padding={'1.2rem'}
                    borderRadius={'.8rem'}
                    mt={'2.4rem'}
                    spacing={'.8rem'}>
                    <Typography color={'grey.main'} variant={'body'}>{'Task Title'}</Typography>
                    <Typography
                        variant={'bodyLight'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                        }}
                        >{item.task_name || 'Empty'}
                    </Typography>
                    <Typography color={'grey.main'} variant={'body'}>{'Task Description'}</Typography>
                    <Typography
                        variant={'bodyLight'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                        }}
                        >{item.task_description || 'Empty'}
                    </Typography>
                    <Typography color={'grey.main'} variant={'body'}>{'Past Performance'}</Typography>
                    <Typography
                        variant={'bodyLight'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                        }}
                        >{item.client_name || 'Empty'}
                    </Typography>
                </Stack>
            ))}
        </Box>
    )
}
