import { Box, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const TermsOfService = () => {

    const navigate = useNavigate();

    return(
        <Box
            width={1}
            maxWidth={'70rem'}
            textAlign={'justify'}
            display={'flex'}
            gap={'2.4rem'}
            mb={'7rem'}
            flexDirection={'column'}>
            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Terms of Service</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Welcome to ProposalWriter.Ai, a product of AIM Innovations, LLC! By accessing or using our products, you agree to be bound by these terms of service and our Privacy Policy. If you do not agree to these terms and the Privacy Policy, please do not use our products.

                    Our products are intended for use by business professionals and organizations. If you are using our products on behalf of an organization, you represent and warrant that you are authorized to accept these terms on behalf of the organization and bind the organization to these terms.

                    We reserve the right to modify these terms at any time. If we make material changes to these terms, we will provide notice through our products or by other means. Your continued use of our products after any such changes constitutes your acceptance of the revised terms.

                    Please read these terms carefully. They contain important information about your legal rights and obligations. If you have any questions about these terms, please contact us atsupport@proposalwriter.ai`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Our Mission and Business Model</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`At ProposalWriter.Ai, our mission is to provide business professionals and organizations with the tools and resources they need to create high-quality, compelling proposals. We believe that effective proposal writing is crucial to the success of any business, and our goal is to make the process as efficient and streamlined as possible.

                    Our business model is based on providing access to our proprietary proposal writing software and services on a subscription basis. Customers can choose from various subscription plans that offer different levels of access and features. In addition to our subscription fees, we may also generate revenue through the sale of additional services, such as proposal review and consultation.

                    We are committed to continuously improving and expanding our products and services, and to providing exceptional customer service to our users. Our goal is to become the go-to resource for businesses seeking to elevate their proposal writing process and win more business.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Our Intellectual Property Rights and License to You</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Some of our products allow you to download client software. So long as you comply with these terms, we give you a limited, non-exclusive, non-transferable, revocable license to use our client software solely to access the products.

                    Our products are protected by copyright, trademark, and other laws. Except for this limited license, we reserve all right, title, and interest in our products, trademarks, logos, and other brand features. We welcome feedback, but you agree that we can use that feedback without restriction or any obligation to you.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>ProposalWriter.Ai Custom Users</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Business terms. When you sign up for Proposal Writer Custom, your organization’s terms will govern your use of your ProposalWriter.Ai team account.

                    If you join a ProposalWriter.Ai Custom account, you must use the products in compliance with your organization’s terms and policies. Please note that ProposalWriter.Ai team accounts are subject to your organization’s control. Your administrators can downgrade, restrict, or terminate your access to a ProposalWriter.Ai team account and view information about it.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Limitations on Use and Responsibilities</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Acceptable Use Policy. You must comply with our `}
                    <Typography
                        variant={'body'}
                        color={'common.link'}
                        onClick={() => navigate('/legal/acceptable-use-policy')}
                        sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>Acceptable Use Policy
                    </Typography>
                    {` when you use our products.

                    Keeping your account information up to date and safe. You are responsible for safeguarding your password to our products. Don’t share your account credentials or give others access to your account. We will use your account email address to contact you about our products, so you must ensure that your account information stays current.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Termination</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`We reserve the right to suspend or terminate your access to our products with notice to you if:

                    (a) you have breached these terms or our `}
                    <Typography
                        variant={'body'}
                        color={'common.link'}
                        onClick={() => navigate('/legal/acceptable-use-policy')}
                        sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>Acceptable Use Policy
                    </Typography>
                    {`,
                    (b) you use the products in a manner that would cause a real risk of harm or loss to other ProposalWriter.Ai users or us, or
                    (c) you don’t have a paid account and haven’t accessed our products for 12 consecutive months.

                    All content and materials included on our products, such as text, graphics, logos, images, software, and data (collectively, the "Content"), are the property of AIM Innovations, LLC or its licensors and are protected by copyright and other intellectual property laws. The Content is provided for your personal, non-commercial use only, and you may not use the Content for any other purpose without our express written consent.

                    We grant you a limited, non-exclusive, revocable, non-transferable license to access and use our products for your personal, non-commercial use, subject to these terms of service. This license does not include any resale or commercial use of our products or the Content; any collection and use of any product listings, descriptions, or prices; any derivative use of our products or the Content; or any use of data mining, robots, or similar data gathering and extraction tools.

                    You may not use any Content without the express written consent of AIM Innovations, LLC. You may not sell, reproduce, distribute, modify, display, publicly perform, prepare derivative works based on, repost, or otherwise use any Content in any way for any public or commercial purpose without the written permission of AIM Innovations, LLC.

                    You may not use any trademark, logo, or other proprietary information, including images and text, contained on our products without the express written consent of AIM Innovations, LLC.

                    If you violate any of these terms, your permission to use the Content will automatically terminate, and you must immediately destroy any copies you have made of the Content. 
                    Additionally, before suspending or terminating your account, we will provide you with reasonable advance notice via the email address associated with your account so you can try to remedy the activity that prompted us to contact you and allow you to export your documents from our products. This notice requirement does not apply if we believe that:

                    (a) you are in material breach of these terms or our Acceptable Use Policy,
                    (b) providing notice would cause us legal liability or compromise our ability to provide our products to our other users, or
                    (c) the law prohibits us from providing notice.

                    If you fail to take the steps we ask of you after such notice, we reserve the right to terminate or suspend your access to our products.

                    Upon the termination of this agreement for any reason, certain sections will survive, including, but not limited to: "Warranty Disclaimers," "Limitation of Liability," "Resolving Disputes," "Miscellaneous Legal Terms," "Our Intellectual Property Rights and License," and "Paid Accounts." The termination of this agreement will not affect any amounts owed prior to the termination.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Discontinuation and Modification of Products</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`We are continually changing and improving our products, and we may add or remove features or functionality. If we discontinue a product, where reasonably possible we will give you reasonable advance notice and a chance to download your stored User Content.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Third-Party Websites</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Our products may contain links to third-party websites or apps that are not owned or controlled by AIM Innovations, LLC. These links are provided for your convenience only and are not an endorsement of the third-party websites or apps.

                    We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or apps. You acknowledge and agree that AIM Innovations, LLC is not responsible or liable for any loss or damage of any sort incurred as a result of your use of any third-party website or app. You are solely responsible for reading and complying with the terms of service and privacy policies of any third-party website or app that you access.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Beta Products</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`From time to time, we may release products or features that are still in the testing and evaluation phase. These products will be marked with the words "beta," "preview," "early access," or "evaluation" (or with words or phrases with similar meanings).

                    If you choose to use beta products, please be aware that they may not be as reliable or as thoroughly tested as our other products. In order to improve and evaluate these products, we may collect and analyze information about your usage and interactions with them.

                    By using beta products, you acknowledge and agree that you are participating in the testing and evaluation process, and that you understand the potential risks and limitations associated with such products.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Warranty Disclaimers</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PROPOSALWRITER.AI, A PRODUCT OF AIM INNOVATIONS, LLC, AND ITS AFFILIATES, AND ITS AND THEIR SUPPLIERS AND DISTRIBUTORS (COLLECTIVELY, THE "PROPOSALWRITER.AI ENTITIES") HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, REGARDING OUR PRODUCTS, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, SATISFACTORY QUALITY, OR ARISING FROM A COURSE OF DEALING, LAW, USAGE, OR TRADE PRACTICE.

                    YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF OUR PRODUCTS IS AT YOUR OWN SOLE RISK AND THAT OUR PRODUCTS ARE PROVIDED ON AN "AS IS," "WHERE IS," "AS AVAILABLE," "WITH ALL FAULTS" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, THE PROPOSALWRITER.AI ENTITIES DO NOT WARRANT THAT THE OPERATION OF OUR PRODUCTS WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED OR ERROR-FREE.

                    THE AFFILIATES OF PROPOSALWRITER.AI AND THE SUPPLIERS, LICENSORS, AND DISTRIBUTORS OF THE PROPOSALWRITER.AI ENTITIES ARE INTENDED THIRD-PARTY BENEFICIARIES OF THIS SECTION.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Limitation of Liability</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`To the maximum extent permitted by applicable law, in no event will ProposalWriter.Ai, a product of AIM Innovations, LLC, or its affiliates, or any of its or their suppliers or distributors, be liable for:

                    (i) any amount in the aggregate in excess of the fees you have actually paid to ProposalWriter.Ai during the twelve (12) months immediately preceding the event(s) giving rise to such liability;
                    (ii) any lost profits, lost or damaged content or other data, or failure to meet any duty, including without limitation good faith and reasonable care; or
                    (iii) any indirect, incidental, punitive, special, exemplary, or consequential damages of any kind whatsoever.

                    You agree that this limitation of liability represents a reasonable allocation of risk and is a fundamental element of the basis of the bargain between ProposalWriter.Ai and you. You understand that our products would not be provided without such limitations.

                    In certain countries and states, the limitation of certain damages is not allowed, so some or all of this limitation of liability may not apply to you and you may have additional rights. In such cases, you agree that the liability of the ProposalWriter.Ai Entities is limited to the maximum extent permissible in your country of residence.

                    If any portion of these sections is held to be invalid, the invalidity of such portion shall not affect the validity of the remaining portions of these terms. The affiliates of ProposalWriter.Ai and the suppliers, licensors, and distributors of the ProposalWriter.Ai Entities are intended third-party beneficiaries of this section.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Resolving Disputes</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`In an effort to resolve disputes in a manner that is effective and efficient for all parties involved, we ask that you first contact us at support@proposalwriter.ai to try to resolve any issues informally. We will make every effort to resolve the dispute through email communication with you.

                    If the dispute cannot be resolved informally, a party seeking to bring a formal proceeding must first send a written Notice of Dispute ("Notice") to the other party via certified mail. The Notice to ProposalWriter.Ai should be sent to 8201 Corporate Dr Suite 640 Landover MD, 20785, Attn: Legal Department, and must include a description of the nature and basis of the claim or dispute, as well as the specific relief sought.

                    If ProposalWriter.Ai and you do not resolve the claim within sixty (60) calendar days after receiving the Notice, either party may commence a formal proceeding.

                    You and ProposalWriter.Ai agree that any judicial proceeding to resolve claims relating to these terms or our products will be brought in the federal or state courts of Prince Georges County, Maryland, subject to the mandatory arbitration provisions below. Both you and ProposalWriter.Ai consent to venue and personal jurisdiction in such courts.

                    If you reside in a country with laws that give consumers the right to bring disputes in their local courts (for example, European Union member states), this provision does not affect those requirements.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>IF YOU’RE A U.S. RESIDENT, YOU ALSO AGREE TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`We both agree to arbitrate. You and ProposalWriter.Ai, a product of AIM Innovations, LLC, agree to resolve any claims relating to these terms or our products through final and binding arbitration by a single arbitrator, except as set forth in the "Exceptions to Agreement to Arbitrate" section below. This includes disputes arising out of or relating to the interpretation or application of this "Mandatory Arbitration" section, including its enforceability, revocability, or validity.

                    Opt-out of Agreement to Arbitrate:
                    You have the right to opt out of these arbitration provisions (and any future changes to arbitration provisions) by emailing support@proposalwriter.ai within 30 days of agreeing to a version of these terms containing arbitration terms (however, if you agreed to a previous version of these terms of service that contain an arbitration provision, you are still bound by those arbitration provisions).

                    Arbitration Procedures:
                    The American Arbitration Association (AAA) will administer the arbitration under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes. The arbitration will be held in Prince Georges County, Maryland, or any other location agreed upon by both parties. During the arbitration, the amount of any settlement offer made by ProposalWriter.Ai or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or ProposalWriter.Ai is entitled.

                    Arbitration Fees and Incentives:
                    The AAA rules will govern the payment of all arbitration fees. ProposalWriter.Ai will pay all arbitration fees for individual arbitration for non-frivolous claims less than $75,000. ProposalWriter.Ai will not seek its attorneys' fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.

                    Exceptions to Agreement to Arbitrate:
                    Either you or ProposalWriter.Ai may assert claims, if they qualify, in small claims court in Prince Georges County, Maryland, or any United States county where you live or work. Either party may bring a lawsuit solely for injunctive relief to stop unauthorized use or abuse of the products or intellectual property infringement (for example, trademark, trade secret, copyright, or patent rights) without first engaging in arbitration or the informal dispute-resolution process described above. If the agreement to arbitrate is found not to apply to you or your claim, you agree to the exclusive jurisdiction of the state and federal courts in Prince Georges County, Maryland, to resolve your claim.

                    NO CLASS ACTIONS:
                    You may only resolve disputes with us individually. That is, you may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations are not allowed.

                    Severability:
                    If the "NO CLASS ACTIONS" paragraph is held unenforceable, then the entirety of this "Mandatory Arbitration" section will be deemed void. If you are found to have a non-waivable right to bring a specific claim or request a specific form of relief that an arbitrator lacks the authority to redress or award under this "Mandatory Arbitration" section, including public injunctive relief, then only that particular claim or request may be brought in court, and you and we agree that litigation of this claim or request will be stayed pending the resolution of any other claims or requests for relief in arbitration.

                    Controlling Law:
                    Maryland law will govern these terms except for its conflicts of laws principles. However, some countries (including those in the European Union) have laws that require agreements to be governed by the local laws of the consumer's country. This paragraph does not override those laws.`}
                </Typography>
            </Stack>

            <Stack width={1} spacing={'1.2rem'}>
                <Typography variant={'h3'}>Miscellaneous Legal Terms</Typography>
                <Typography variant={'body'} whiteSpace={'pre-line'}>
                    {`Entire Agreement

                    Entire agreement. These terms, together with the ProposalWriter.Ai Privacy Policy and any additional terms to which you agree when you use certain features of our products, make up the entire agreement between you and ProposalWriter.Ai. They supersede any prior agreements.

                    Waiver, Severability, and Assignment

                    Waiver, severability, and assignment. If we don't act to enforce a breach of these terms, that doesn't mean we have waived our right to enforce these terms. If a term is not enforceable, that will not affect any other terms. You may not assign or transfer these terms or your rights to use our products, in whole or in part, without our written consent. We may assign these terms or transfer our rights to all or part of our products without your consent.


                    Modifications to These Terms

                    From time to time, we may revise these terms to, for example, reflect changes to the law, new regulations, and changes to our products.

                    If an update significantly negatively affects your use of our products or your legal rights as a user of our products, we’ll notify you before the update’s effective date by sending an email to the email address associated with your account or via an in-product notification. We will give you at least 30 days after that notice before the update takes effect. 

                    We may also update these terms in ways that won’t significantly negatively affect your rights. For example, we may change the feature or product names or change the email address you can use to contact us. In those cases, we will post the change to our website and link to the previous version. 

                    If you don’t agree to the updates we make, you must cancel your account before they become effective. Where required, we’ll offer you a prorated refund based on the amounts you have prepaid for our products and your account cancellation date. By using or accessing the products after the updates come into effect, you agree to be bound by the revised terms.`}
                </Typography>
            </Stack>
        </Box>
    )
}