import { Box, Button, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { EditProposalModal } from '../../../components/modals/EditProposalModal';
import { GET_REGENERATE_RESPONSE_AI_API, POST_UPDATE_TASK_API } from '../../../const';
import { useUserStore } from '../../../store/userStore';

export const ProposalTaskParagraph = ({aiTask}) => {

  const loadingImage = require('../../../assets/images/building-new-ai-task.gif');

  const company = useUserStore((state) => state.company);
  const setCompany = useUserStore((state) => state.setCompany);

  const [editTask, setEditTask] = useState(false);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskParagraph, setTaskParagraph] = useState('');
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [promptMessage, setPromptMessage] = useState('')

  useEffect(() => {
    if (taskTitle?.length === 0 && taskParagraph?.length === 0) {
      setTaskTitle(aiTask.task_name);
      setTaskParagraph(aiTask.ai_response);
    }
  }, [aiTask.ai_response, aiTask.task_name, taskParagraph, taskTitle]);

  const SendTaskEdited = () => {
    API.post('pyBackend', POST_UPDATE_TASK_API, {
      body: {
        task_id: aiTask.task_id,
        task_name: taskTitle,
        new_response: taskParagraph
      }
    })
    setEditTask(false);
  };

  const [showRewriteDialog, setShowRewriteDialog] = useState(false);
  const RewriteHandler = () => setShowRewriteDialog(!showRewriteDialog);

  const [rewriting, setRewriting] = useState(false);

  const regenerateAiResponse = async () => {
    const oldTaskParagraph = taskParagraph;
    const oldRegenerationsLeft = company.regenerations_left;
    setIsRegenerating(true);
    setTaskParagraph('');
    try {
      setCompany({...company, regenerations_left: Math.max(0, oldRegenerationsLeft - 1)});
      const response = await fetch(`${GET_REGENERATE_RESPONSE_AI_API}${aiTask.task_id}`);
      const data = await response.json();
      setTaskParagraph(data.ai_response);
    } catch (e) {
      // reset value if error
      setCompany({...company, regenerations_left: oldRegenerationsLeft});
      setTaskParagraph(oldTaskParagraph);
      // TODO show error message
    }
    setIsRegenerating(false);
  };

  const RewriteFunction = async () => {
    setRewriting(true);
    const result = await API.post('pyBackendRegenerate', '', {
      body: {
        'task_id': aiTask.task_id,
        'prompt': promptMessage,
      },
    });

    console.log(result);
    setTaskParagraph(result.ai_response);
    setPromptMessage('');
    setRewriting(false);
    setShowRewriteDialog(false);
  };

  return (
    <Stack
      width={1}
      boxShadow={editTask ? '0rem 0rem 2rem 0rem #DDD' : 'none'}
      borderRadius={'1.2rem'}
      overflow={'hidden'}
      p={'1.6rem'}>
      <Modal open={showRewriteDialog} onClose={RewriteHandler}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{xs: '1.6rem', md: 0}}>
          <EditProposalModal
            rewriting={rewriting}
            modalHandler={RewriteHandler}
            promptMessage={promptMessage}
            setPromptMessage={setPromptMessage}
            rewriteFunction={RewriteFunction}
             />
        </Box>
      </Modal>
      <Stack
        width={1}
        direction={'row'}
        spacing={'2.4rem'}
        justifyContent={'space-between'}>
        {!editTask
          ?
          <Typography color={'common.black'} variant={'h5'}>
            {taskTitle}
          </Typography>
          :
          <OutlinedInput
            fullWidth
            value={taskTitle}
            onChange={(event) => setTaskTitle(event.target.value)} />
        }
          <Button
            variant={'primaryNavbar'}
            onClick={RewriteHandler}>
            Re-Write
          </Button>
      </Stack>
      {isRegenerating
        ?
        <Stack
          width={1}
          alignItems={'center'}
          mt={'5.6rem'}>
          <Box
            component={'img'}
            width={'20%'}
            src={loadingImage} />
        </Stack>
        :
        <Typography
          color={'common.black'}
          variant={'bodyLight'}
          whiteSpace={'pre-line'}
          sx={{
            mt: '1.2rem',
          }}>
          {taskParagraph}
        </Typography>
      }
      <Button
        fullWidth
        variant={'primaryNavbar'}
        onClick={SendTaskEdited}
        sx={{
          mt: '2.4rem',
          display: editTask ? 'block' : 'none',
        }}>
        Save Changes
      </Button>
    </Stack>
  );
};
