import {Box, CircularProgress, Divider, IconButton, Pagination, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {ArrowBackIosNewRounded, ArrowForwardIosRounded} from '@mui/icons-material';
import {AccountListItem} from './components/AccountListItem';

export const Accounts = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);

    const rowsPerPage = 10;
    const totalPages = Math.ceil(accounts.length / rowsPerPage);


    const fetchAccounts = async () => {
        setLoading(true)
        const response = await API.get('api2e29c07a', '/admin/accounts', {});
        setAccounts(response.data);
        setLoading(false)
    };


    useEffect(() => {
        fetchAccounts();
    }, []);

    const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
    const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    return (
        <Box
            width={1}
            maxWidth={'127rem'}
            mt={{xs: 0, md: '-4rem'}}
            mb={totalPages > 1 ? 0 : '7.2rem'}
            px={{xs: '1.6rem', md: '4.8rem'}}
            display={'flex'}
            flexDirection={'column'}
            gap={'4.8rem'}>
            <Typography
                color={'common.black'}
                textAlign={{xs: 'center', md: 'left'}}
                variant={'h3'}>
                Accounts
            </Typography>
            <Box
                width={1}>
                <Stack
                    display={{xs: 'none', md: 'flex'}}
                    width={1}
                    height={'4.8rem'}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Box
                        width={'35%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            ID
                        </Typography>
                    </Box>
                    <Box
                        width={'15%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            textAlign={'left'}
                            color={'common.black'}
                            variant={'s2'}>
                            Name
                        </Typography>
                    </Box>
                    <Box
                        width={'25%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Email
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Type
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Deleted
                        </Typography>
                    </Box>
                    <Box
                        width={'5%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                        </Typography>
                    </Box>
                </Stack>
                <Divider flexItem sx={{display: {xs: 'none', md: 'flex'}}}/>
                {isLoading ? (
                        <Box sx={{m: 4}} width={1} display={'flex'} justifyContent={'center'}><CircularProgress/></Box>) :
                    <Box
                        width={1}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'1.2rem'}
                        py={{xs: 0, md: '2.4rem'}}>
                        {accounts.length &&
                            accounts.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
                                return <AccountListItem
                                    key={index}
                                    accountId={row.account_sub}
                                    name={row.name}
                                    email={row.email}
                                    accountType={row.type_account}
                                    status={!row.deleted_at ? 'Active' : 'Deleted'}
                                    fetchData={fetchAccounts}
                                />;
                            })}
                    </Box>
                }
            </Box>
            <Box
                width={1}
                display={totalPages > 1 ? 'flex' : 'none'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'1.2rem'}
                p={'1.6rem'}
                mb={'5rem'}>
                <IconButton
                    disabled={currentPage === 1 ? true : false}
                    onClick={prevButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main',
                    }}>
                    <ArrowBackIosNewRounded/>
                </IconButton>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'small'}
                    sx={{display: {xs: 'flex', md: 'none'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'large'}
                    sx={{display: {xs: 'none', md: 'flex'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <IconButton
                    disabled={currentPage === totalPages ? true : false}
                    onClick={nextButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
                    }}>
                    <ArrowForwardIosRounded/>
                </IconButton>
            </Box>
        </Box>
    );
};
