import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Typography, Zoom } from '@mui/material';

export const FieldHelper = ({suggestion}) => {
    return(
        <Tooltip
            arrow
            placement={'top'}
            TransitionComponent={Zoom}
            title={<Typography variant={'s4'}>{suggestion}</Typography>}
            sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 500,
                },
        }}>
            <InfoRoundedIcon sx={{ color: 'primary.main' }} />
        </Tooltip>
    )
}