import { CssBaseline, ThemeProvider } from '@mui/material';
import Navigation from './routes';
import { MainTheme } from './theme/MainTheme';
import './assets/MainStyles.css';
import PageLayout from './layout/PageLayout';

function App() {
  return (
    <ThemeProvider theme={MainTheme}>
      <CssBaseline />
      <PageLayout>
        <Navigation />
      </PageLayout>
    </ThemeProvider>
  );
}

export default App;
