//REACT IMPORTS
import { Box, Button, OutlinedInput, Stack, Typography } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

export const EditProposalModal = ({ modalHandler, rewriting, rewriteFunction, promptMessage, setPromptMessage }) => {

    const [showPrompt, setShowPrompt] = useState(false);
    const ShowPromptField = () => setShowPrompt(!showPrompt)

    return(
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            overflow={'hidden'}
            maxHeight={'90vh'}
            sx={{
                overflowY: 'scroll'
            }}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon />}
                    onClick={() => modalHandler()}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Typography textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Edit Proposal
                </Typography>

                <Stack width={1} spacing={'1.2rem'} direction={'row'}>
                    <LoadingButton fullWidth loading={rewriting} variant={'secondaryMain'} onClick={rewriteFunction}>
                        Rewrite for me
                    </LoadingButton>
                    <Button fullWidth variant={'primaryMain'} onClick={ShowPromptField}>
                        Rewrite Something Specific
                    </Button>
                </Stack>

                {showPrompt &&
                    <Stack
                        width={1}
                        spacing={'1.2rem'}>
                        <Typography variant={'s3'}>
                            Write Something Specific
                        </Typography>
                        <OutlinedInput
                            multiline
                            rows={5}
                            value={promptMessage}
                            onChange={(event) => setPromptMessage(event.target.value)}
                            placeholder={'Example: Expand and add more about the process of seeding the farm.'} />
                        <LoadingButton
                            fullWidth
                            loading={rewriting}
                            variant={'primaryMain'}
                            onClick={rewriteFunction}>
                            Execute
                        </LoadingButton>
                    </Stack>
                }

                <Button
                    fullWidth
                    variant={'secondaryMain'}
                    onClick={() => modalHandler()}>
                    Cancel
                </Button>
            </Box>
        </Box>
    )
}
