//REACT IMPORTS
import { Avatar, Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//ICON IMPORTS
import { ReactComponent as BrandLogo } from '../../assets/icons/brand-logo.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';

//HOOK IMPORTS
import { useUserStore } from '../../store/userStore';

export const UserNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const navigate = useNavigate();

  const company = useUserStore((state) => state.company);
  const companyImage = useUserStore((state) => state.companyImage);

  const navigateTo = (path) => {
    navigate(path);
    handleClose();
  };

  const logout = async () => {
    handleClose();
    await Auth.signOut();
    navigate('/sign-in');
  };

  return (
    <Box
      width={1}
      height={{xs: '6rem', md: '8rem'}}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      borderBottom={'.1rem solid #D4D4D4'}
      position={'relative'}>
      <Box
        width={1}
        maxWidth={'127rem'}
        px={'4.8rem'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={{xs: 'center', md: 'space-between'}}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'6.4rem'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'1rem'}
            component={'a'}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: 'common.black',
            }}
            href={'https://www.proposalwriter.ai/'}>
            <Box
              overflow={'hidden'}
              width={{xs: '3rem', md: '3.5rem'}}
              height={{xs: '3.5rem', md: '4rem'}}>
              <BrandLogo style={{width: '100%', height: '100%'}} />
            </Box>
            <Typography
              fontWeight={600}
              fontSize={{xs: '1.2rem', md: '1.4rem'}}
              lineHeight={{xs: '1.8rem', md: '2rem'}}>
              Proposal Writer Ai
            </Typography>
          </Box>
          <Link
            color={'grey.main'}
            style={{textDecoration: 'none', cursor: 'pointer'}}
            to={'/users/my-proposals'}>
            <Typography
              display={{xs: 'none', md: 'flex'}}
              color={'grey.main'}
              variant={'bodyLight'}>
              {'My Proposals'}
            </Typography>
          </Link>
          <Link
            color={'grey.main'}
            style={{textDecoration: 'none', cursor: 'pointer'}}
            to={'/users/my-shredded-documents'}>
            <Typography
              display={{xs: 'none', md: 'flex'}}
              color={'grey.main'}
              variant={'bodyLight'}>
              {'My Shredded Documents'}
            </Typography>
          </Link>
        </Stack>

        <Box
          display={{xs: 'none', md: 'flex'}}
          flexDirection={'row'}
          gap={'2rem'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Stack
            direction={'row'}
            spacing={'1.2rem'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
              direction={'column'}>
            </Stack>
            <Button
              onClick={() => navigate('/users/proposal-preparation')}
              sx={{display: {xs: 'none', md: 'block'}}}
              variant={'primaryNavbar'}
              disabled={company?.proposals_left === 0}
            >
              Create proposal
            </Button>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={'.8rem'}
            color={'grey.main'}
            onClick={handleClick}
            sx={{cursor: 'pointer'}}>
            <Avatar
              src={companyImage}
              width={'4.6rem'}
              height={'4.6rem'}
              aria-describedby={id}
            />
            <DownIcon />
          </Stack>
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={'2.4rem'}
          minWidth={'22rem'}
          borderRadius={'1.6rem'}
          p={'2.4rem'}
          bgcolor={'#FFF'}
          boxShadow={'0rem .4rem 3rem rgba(0, 0, 0, 0.1)'}
          right={'14rem'}
          top={'8rem'}
          zIndex={1}
        >
          <Typography
            onClick={() => navigateTo('/users/account-settings')}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
              width: 1,
            }}
            color={'common.black'}
            variant={'body'}
          >
            <UserIcon />
            My Account
          </Typography>
          <Divider flexItem />
          <Typography
            sx={{cursor: 'pointer', width: 1}}
            color={'common.black'}
            variant={'body'}
            onClick={logout}
          >
            Log Out
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
