import { Box, Button, Stack, Typography } from "@mui/material";

import { ReactComponent as CommissionIcon } from "../assets/icons/commissions-icon.svg";
import { ReactComponent as SupportIcon } from "../assets/icons/support-icon.svg";
import { ReactComponent as TrackerIcon } from "../assets/icons/report-icon.svg";

export const AffiliatesPage = () => {

    const firstBlockImg = require('../assets/images/affiliates-image-one.png');
    const affiliatesProgramImg = require('../assets/images/affiliates-program.png');

    return(
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            width={1}>

            <Stack
                width={1}
                alignItems={'center'}>
                <Stack
                    width={1}
                    maxWidth={'120rem'}
                    direction={'row'}
                    spacing={'4.8rem'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Box
                        width={'60rem'}
                        minWidth={'60rem'}
                        borderRadius={'2rem'}
                        >
                        <Box
                            width={1}
                            component={'img'}
                            src={firstBlockImg} />
                    </Box>
                    <Typography variant={'s1'}>
                        ProposalWriter.ai is the leading AI-powered proposal writing software that helps businesses streamline the proposal writing process and win more deals. Our innovative software uses advanced algorithms to help businesses create professional and effective proposals in a fraction of the time.
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                width={1}
                alignItems={'center'}
                bgcolor={'primary.main'}>
                <Stack
                    width={1}
                    mt={'7.2rem'}
                    maxWidth={'120rem'}
                    direction={'row'}
                    spacing={'4.8rem'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography variant={'s1'} textAlign={'right'} color={'common.white'}>
                        As a ProposalWriter.ai affiliate, you will have the opportunity to earn commission by promoting our software to your network. By joining our affiliate program, you will be able to tap into a lucrative market and provide your followers with a valuable solution that can help them grow their businesses.
                    </Typography>
                    <Box
                        width={'60rem'}
                        minWidth={'60rem'}
                        borderRadius={'2rem'}>
                        <Box
                            width={1}
                            component={'img'}
                            src={affiliatesProgramImg} />
                    </Box>
                </Stack>
            </Stack>
            <Stack
                width={1}
                alignItems={'center'}>
                <Stack
                    width={1}
                    mt={'7.2rem'}
                    maxWidth={'100rem'}
                    direction={'column'}
                    spacing={'4.8rem'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Typography variant={'h3'} textAlign={'center'}>
                        Here's what you can expect from our affiliate program:
                    </Typography>
                    <Stack
                        width={1}
                        direction={'row'}
                        justifyContent={'space-between'}>
                        <Stack
                            width={1}
                            maxWidth={'30rem'}
                            alignItems={'center'}
                            spacing={'1.2rem'}>
                            <Box
                                width={'15rem'}
                                minWidth={'15rem'}
                                height={'15rem'}
                                borderRadius={'2rem'}
                                color={'primary.main'} >
                                <CommissionIcon />
                            </Box>
                            <Typography variant={'body'} fontWeight={600} textAlign={'center'}>
                                Competitive Commission:
                            </Typography>
                            <Typography variant={'bodyLight'} textAlign={'center'} whiteSpace={'pre-line'}>
                                You will earn a commission for every sale made through your unique affiliate link. Our commission structure is designed to reward our affiliates for their hard work and success.
                            </Typography>
                        </Stack>

                        <Stack
                            width={1}
                            maxWidth={'30rem'}
                            alignItems={'center'}
                            spacing={'1.2rem'}>
                            <Box
                                width={'15rem'}
                                minWidth={'15rem'}
                                height={'15rem'}
                                borderRadius={'2rem'}
                                color={'primary.main'} >
                                <SupportIcon />
                            </Box>
                            <Typography variant={'body'} fontWeight={600} textAlign={'center'}>
                                Dedicated Support:
                            </Typography>
                            <Typography variant={'bodyLight'} textAlign={'center'} whiteSpace={'pre-line'}>
                                Our affiliate management team is here to support you every step of the way. We will provide you with all the tools and resources you need to succeed, including promotional materials, email templates, and more.
                            </Typography>
                        </Stack>

                        <Stack
                            width={1}
                            maxWidth={'30rem'}
                            alignItems={'center'}
                            spacing={'1.2rem'}>
                            <Box
                                width={'15rem'}
                                minWidth={'15rem'}
                                height={'15rem'}
                                borderRadius={'2rem'}
                                color={'primary.main'} >
                                <TrackerIcon />
                            </Box>
                            <Typography variant={'body'} fontWeight={600} textAlign={'center'}>
                                Robust Tracking and Reporting:
                            </Typography>
                            <Typography variant={'bodyLight'} textAlign={'center'} whiteSpace={'pre-line'}>
                                Our affiliate platform provides real-time tracking and reporting so you can monitor your performance and earnings.
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                width={1}
                maxWidth={'100rem'}
                alignItems={'center'}
                >
                <Stack
                    width={1}
                    mt={'7.2rem'}
                    justifyContent={'space-between'}
                    direction={'row'}
                    spacing={'2.4rem'}>
                    <Box
                        width={'30rem'}
                        minWidth={'30rem'}
                        height={'30rem'}
                        bgcolor={'white'}
                        border={'.2rem solid'}
                        borderColor={'primary.main'}
                        borderRadius={'50%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        textAlign={'center'}
                        position={'relative'}
                        sx={{
                            '&::after': {
                                content: '" "',
                                position: 'absolute',
                                display: 'block',
                                width: '3rem',
                                minWidth: '3rem',
                                height: '3rem',
                                borderRadius: '50%',
                                bgcolor: 'primary.main',
                                left: '2rem',
                                top: '3.5rem',
                                zIndex: 99,
                            },
                            '&::before': {
                                content: '" "',
                                position: "absolute",
                                display: 'block',
                                width: '26rem',
                                minWidth: '26rem',
                                height: '26rem',
                                borderRadius: '50%',
                                bgcolor: 'grey.offWhite',
                                top: '1.8rem',
                                left: '1.8rem'
                            }
                        }}>
                            <Stack
                                width={'70%'}
                                zIndex={2}
                                spacing={'1.2rem'}>
                                <Typography variant={'body'}>
                                    Sign up easily
                                </Typography>
                                <Typography variant={'bodyLight'}>
                                    Get into the details with our application. It’s quick, easy & free.
                                </Typography>
                            </Stack>
                    </Box>
                    <Box
                        width={'30rem'}
                        minWidth={'30rem'}
                        height={'30rem'}
                        bgcolor={'white'}
                        border={'.2rem solid'}
                        borderColor={'primary.main'}
                        borderRadius={'50%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        textAlign={'center'}
                        position={'relative'}
                        sx={{
                            '&::after': {
                                content: '" "',
                                position: 'absolute',
                                display: 'block',
                                width: '3rem',
                                minWidth: '3rem',
                                height: '3rem',
                                borderRadius: '50%',
                                bgcolor: 'primary.main',
                                right: '2rem',
                                bottom: '3.5rem',
                                zIndex: 99,
                            },
                            '&::before': {
                                content: '" "',
                                position: "absolute",
                                display: 'block',
                                width: '26rem',
                                minWidth: '26rem',
                                height: '26rem',
                                borderRadius: '50%',
                                bgcolor: 'grey.offWhite',
                                top: '1.8rem',
                                left: '1.8rem'
                            }
                        }}>
                            <Stack
                                width={'70%'}
                                zIndex={2}
                                spacing={'1.2rem'}>
                                <Typography variant={'body'}>
                                    High Conversation Rates
                                </Typography>
                                <Typography variant={'bodyLight'}>
                                    Spread the word & cash in on every qualifying purchase made through your links.
                                </Typography>
                            </Stack>
                    </Box>
                    <Box
                        width={'30rem'}
                        minWidth={'30rem'}
                        height={'30rem'}
                        bgcolor={'white'}
                        border={'.2rem solid'}
                        borderColor={'primary.main'}
                        borderRadius={'50%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        textAlign={'center'}
                        position={'relative'}
                        sx={{
                            '&::after': {
                                content: '" "',
                                position: 'absolute',
                                display: 'block',
                                width: '3rem',
                                minWidth: '3rem',
                                height: '3rem',
                                borderRadius: '50%',
                                bgcolor: 'primary.main',
                                right: '2rem',
                                top: '3.5rem',
                                zIndex: 99,
                            },
                            '&::before': {
                                content: '" "',
                                position: "absolute",
                                display: 'block',
                                width: '26rem',
                                minWidth: '26rem',
                                height: '26rem',
                                borderRadius: '50%',
                                bgcolor: 'grey.offWhite',
                                top: '1.8rem',
                                left: '1.8rem'
                            }
                        }}>
                            <Stack
                                width={'70%'}
                                zIndex={2}
                                spacing={'1.2rem'}>
                                <Typography variant={'body'}>
                                    Earn up to 20%
                                </Typography>
                                <Typography variant={'bodyLight'}>
                                    Spread the word & cash in on every qualifying purchase made through your links.
                                </Typography>
                            </Stack>
                    </Box>
                </Stack>
                <Stack
                    width={1}
                    my={'4.8rem'}
                    padding={'4.8rem'}
                    bgcolor={'primary.main'}
                    borderRadius={'2.4rem'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Typography variant={'h2'} color={'common.white'}>
                        Join the program & become a Target Partner
                    </Typography>
                    <Button
                        fullWidth
                        component={'a'}
                        variant={'secondaryThin'}
                        target={'_blank'}
                        href={'https://proposalwriter.firstpromoter.com/'}
                        sx={{
                            maxWidth: '15rem',
                            color: 'primary.main',
                            borderColor: 'transparent',
                            fontSize: '1.8rem',
                            mt: '2.4rem'
                            }}>
                        Join Now
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}