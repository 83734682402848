//REACT IMPORTS
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

//COMPONENT IMPORTS
import { useUserStore } from '../store/userStore';
import SpinningLoader from '../components/helpers/SpinningLoader';
import { Box, Grid, Typography } from '@mui/material';

export const AdminLayout = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const group = useUserStore((state) => state.group);

  useEffect(() => {
    const checkAdmin = async () => {
      if (!group || !group.includes('admin')) {
        navigate('/users/my-proposals');
      }
    };
    setLoading(true);
    checkAdmin();
    setLoading(false);
  }, []);

  if (loading) {
    return <SpinningLoader />;
  }

  return (
    <>
        <Grid
          container
          spacing={2}
          width={1}
          maxWidth={'127rem'}
          mt={0}
          px={{xs: '1.6rem', md: '4.8rem'}}
        >
            <Grid item>
                <Link
                  color={'grey.main'}
                  style={{textDecoration: 'none', cursor: 'pointer'}}
                  to={'/users/admin/accounts'}>
                    <Typography
                      display={{md: 'flex'}}
                      color={'grey.main'}
                      variant={'bodyLight'}>
                        {'Accounts'}
                    </Typography>
                </Link>
            </Grid>
            <Grid item>
                <Link
                  color={'grey.main'}
                  style={{textDecoration: 'none', cursor: 'pointer'}}
                  to={'/users/admin/companies'}>
                    <Typography
                      display={{md: 'flex'}}
                      color={'grey.main'}
                      variant={'bodyLight'}>
                        {'Companies'}
                    </Typography>
                </Link>
            </Grid>
        </Grid>
      <Outlet />
    </>
  );
};

