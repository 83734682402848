//REACT IMPORTS
import { Box, Stack, Typography } from "@mui/material"
import { FieldHelper } from "../../../components/helpers/FieldHelper"

//COMPONENT IMPORTS
import { TagsInput } from "../../../components/input/TagsInput"

export const SettingsKeywords = ({keywords, setKeywords}) => {

    return(
        <Box
            width={1}
            maxWidth={'45rem'}
            display={'flex'}
            flexDirection={'column'}
            gap={'2.4rem'}>
            <Stack
                width={1}
                direction={'column'}
                spacing={'1.2rem'}
                justifyContent={'start'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Corporate Capabilities (Up to 20) </Typography>
                    <FieldHelper
                        suggestion={'Input corporate capabilities such as Help Desk, Software Development, Cybersecurity, etc. Hit enter after each capability. '} />
                </Stack>
                <TagsInput
                    placeholder={'Add Skill'}
                    maxTags={20}
                    inputType={'text'}
                    tags={keywords}
                    setTags={setKeywords} />
                <Typography color={'grey.grey3'} variant={'s4'}>
                    {'<Enter> to confirm Capabilities'}
                </Typography>
            </Stack>
        </Box>
    )
}