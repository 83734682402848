import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShredDocumentsStepOne } from './components/ShredDocumentsStepOne';
import { ShredDocumentsStepTwoRFP } from './components/ShredDocumentsStepTwoRFP';

import { API, Storage } from 'aws-amplify';
import { GET_CLIENT_LIST_API, SHRED_DOCUMENT_API, SHRED_RFI_DOCUMENT_API } from '../../const';
import { useUserStore } from '../../store/userStore';
import { LoadingButton } from '@mui/lab';
import { ShredDocumentsFirstStep } from './components/ShredDocumentsFirstStep';
import { ShredDocumentsStepTwoRFI } from '@/views/users/components/ShredDocumentsStepTwoRFI';


export const ShredDocuments = () => {

  const navigate = useNavigate();

  const company = useUserStore(state => state.company);
  const [step, setStep] = useState(0);
  const [shreddingDocs, setShreddingDocs] = useState(false);

  const [files, setFiles] = useState([]);
  const [sectionLTitle, setSectionLTitle] = useState('');
  const [sectionLTitleFile, setSectionLTitleFile] = useState('');
  const [sectionLPage, setSectionLPage] = useState('');
  const [sectionMTitle, setSectionMTitle] = useState('');
  const [sectionMTitleFile, setSectionMTitleFile] = useState('');
  const [sectionMPage, setSectionMPage] = useState('');
  const [sectionCTitle, setSectionCTitle] = useState('');
  const [sectionCTitleFile, setSectionCTitleFile] = useState('');
  const [sectionCPage, setSectionCPage] = useState('');
  const [primaryKeywords, setPrimaryKeywords] = useState(['will', 'must', 'shall', 'should', 'include', 'includes',
    'ensure', 'ensures', 'insure', 'insures', 'assure', 'assures', 'provide', 'provides', 'submit', 'cannot',
    'indicate', 'describe', 'illustrate', 'show', 'document', 'list']);
  const [secondaryKeywords, setSecondaryKeywords] = useState(['apply', 'applies', 'commit', 'commits', 'commitment',
    'compel', 'compels', 'compelled', 'compliant', 'compulsion', 'compulsory', 'consent', 'consents', 'contractor',
    'duty', 'enforce', 'enforces', 'enforced', 'fail', 'fails', 'failure', 'incorporate', 'incorporates',
    'in any event', 'in the event', 'necessitate', 'necessitates', 'necessitated', 'notwithstanding', 'oblige',
    'obliges', 'obligate', 'obligated', 'obligation', 'onus', 'prohibit', 'prohibits', 'prohibited', 'provision',
    'request', 'requests', 'requested', 'responsibility', 'responsible', 'require', 'requires', 'requirement',
    'required', 'unable', 'offeror']);
  const [additionalKeywords, setAdditionalKeywords] = useState(['may', 'intend', 'intends', 'anticipate', 'anticipates',
    'assume', 'assumes', 'plan', 'plans', 'hope', 'hopes', 'might']);
  const [customSections, setCustomSections] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [proposalName, setProposalName] = useState('');
  const [proposalType, setProposalType] = useState('RFP');
  const [primaryPurpose, setPrimaryPurpose] = useState('Product');
  const [proposalDescription, setProposalDescription] = useState('');

  const [loadingClientList, setLoadingClientList] = useState(true);
  const [proposalTasks, setProposalTasks] = useState([]);

  const [sectionCError, setSectionCError] = useState(false);
  const [sectionLMError, setSectionLMError] = useState(false);
  const [clientList, setClientList] = useState([]);


  const nextButton = () => {
    if (step === 0) {
      if (proposalType === 'RFP') {
        setStep(step + 1);
      } else {
        setStep(step + 2);
      }
    } else {
      setStep(step + 1);
    }
  };
  const backButton = () => {
    if (step > 0) {
      if (proposalType === 'RFP') {
        setStep(step - 1);
      } else {
        setStep(step - 2);
      }
    }
  };

  const confirmStepTwo = () => {
    let hasError = false;
    setSectionLMError(false);
    setSectionCError(false);

    if (sectionCTitle === '' || sectionCPage === '' || sectionCTitleFile === '') {
      setSectionCError(true);
      hasError = true;
    }

    if ((sectionLTitle !== '' || sectionLPage !== '' || sectionLTitleFile !== '') &&
      (sectionLTitle === '' || sectionLPage === '' || sectionLTitleFile === '')) {
      setSectionLMError(true);
      hasError = true;
    }

    if ((sectionMTitle !== '' || sectionMPage !== '' || sectionMTitleFile !== '') &&
      (sectionMTitle === '' || sectionMPage === '' || sectionMTitleFile === '')) {
      setSectionLMError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }
    nextButton();
  };

  const generateUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  const getRFIBody = async () => {
    const objForFormData = {
      company_id: company.company_id,
      proposal_name: proposalName,
      proposal_type: proposalType,
      primary_purpose: primaryPurpose,
      proposal_description: proposalDescription,
      organization_name: organizationName,
    };

    for (const file of files) {
      const index = files.indexOf(file);
      const fileName = generateUuid();
      const fileResult = await Storage.put(fileName, file, {
        level: 'public',
        customPrefix: {
          public: 'uploads/'
        },
        contentType: file.type
      });

      objForFormData[`file_url_${index + 1}`] = `uploads/${fileResult.key}`;
      objForFormData[`file_name_${index + 1}`] = file.name;
    }

    return objForFormData;
  }

  const getRFPBody = async () => {
    const objForFormData = {
      company_id: company.company_id,
      primary_keywords: primaryKeywords.toString(),
      secondary_keywords: secondaryKeywords.toString(),
      optional_keywords: additionalKeywords.toString(),
      organization_name: organizationName,
      proposal_name: proposalName,
      proposal_type: proposalType,
      primary_purpose: primaryPurpose,
      proposal_description: proposalDescription,
      section_l_title: sectionLTitle,
      section_l_pages: sectionLPage,
      section_l_filename: sectionLTitleFile,
      section_m_title: sectionMTitle,
      section_m_pages: sectionMPage,
      section_m_filename: sectionMTitleFile,
      section_c_title: sectionCTitle,
      section_c_pages: sectionCPage,
      section_c_filename: sectionCTitleFile
    };

    for (const file of files) {
      const index = files.indexOf(file);
      const fileName = generateUuid();
      const fileResult = await Storage.put(fileName, file, {
        level: 'public',
        customPrefix: {
          public: 'uploads/'
        },
        contentType: file.type
      });

      objForFormData[`file_url_${index + 1}`] = `uploads/${fileResult.key}`;
      objForFormData[`file_name_${index + 1}`] = file.name;
    }

    return objForFormData;
  }

  const shred = async () => {
    setShreddingDocs(true);
    // append this to the formData:

    const body = proposalType === 'RFP' ? (await getRFPBody()) : (await getRFIBody());

    const myInit = {
      body: body,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (proposalType === 'RFP') {
      myInit.body.section_customs = customSections;
      API.post('pyBackend', SHRED_DOCUMENT_API, myInit);
    } else {
      myInit.body.proposal_tasks = proposalTasks;
      API.post('pyBackend', SHRED_RFI_DOCUMENT_API, myInit);
    }

    setShreddingDocs(false);
    navigate('/users/shredding-confirmation');
  };

  const fetchClientList = async () => {
    setLoadingClientList(true);
    try {
      let response = await API.get('pyBackend', `${GET_CLIENT_LIST_API}${company.company_id}`);
      setClientList(response);
    } catch (error) {
      console.log(error);
    }
    setLoadingClientList(false);
  };

  useState(() => {
    if (company) {
      fetchClientList();
    }
  }, [company]);


  let stepLabels = ['Client', ...(proposalType === 'RFP' ? ['Shred Documents'] : []),
    proposalType === 'RFP' ? 'Custom Fields' : 'Custom Tasks'];

  return (
    <Box
      width={1}
      mt={{ xs: 0, md: '-4rem' }}
      maxWidth={'80rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      mb={'7.2rem'}>
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          mb: '3.2rem'
        }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step === 0 &&
        <Stack
          width={1}
          direction={'column'}
          spacing={'4.8rem'}>
          <Typography variant={'h2'} textAlign={'center'}>
            Tell us about the RFP/RFI
          </Typography>
          <ShredDocumentsFirstStep
            organizationName={organizationName}
            setOrganizationName={setOrganizationName}
            proposalName={proposalName}
            setProposalName={setProposalName}
            proposalType={proposalType}
            setProposalType={setProposalType}
            primaryPurpose={primaryPurpose}
            setPrimaryPurpose={setPrimaryPurpose}
            proposalDescription={proposalDescription}
            setProposalDescription={setProposalDescription} />
        </Stack>}

      {step === 1 && <ShredDocumentsStepOne props={{
        files, setFiles,
        sectionLTitle, setSectionLTitle,
        sectionLTitleFile, setSectionLTitleFile,
        sectionLPage, setSectionLPage,
        sectionMTitle, setSectionMTitle,
        sectionMTitleFile, setSectionMTitleFile,
        sectionMPage, setSectionMPage,
        sectionCTitle, setSectionCTitle,
        sectionCTitleFile, setSectionCTitleFile,
        sectionCPage, setSectionCPage,
        primaryKeywords, setPrimaryKeywords,
        secondaryKeywords, setSecondaryKeywords,
        additionalKeywords, setAdditionalKeywords
      }} />}
      {step === 2 && proposalType === 'RFP' &&
        <ShredDocumentsStepTwoRFP
          fileList={files}
          customSections={customSections}
          setCustomSections={setCustomSections} />
      }
      {step === 2 && proposalType === 'RFI' &&
        <ShredDocumentsStepTwoRFI clientList={clientList} proposalTasks={proposalTasks}
          setProposalTasks={setProposalTasks} />
      }
      <Stack width={1} spacing={'1.2rem'} sx={{ mt: '4.8rem', textAlign: 'center' }}>
        {step === 1 && sectionCError &&
          <Typography color={'common.error'} variant={'s1'}>Section C is required!</Typography>}
        {step === 1 && sectionLMError &&
          <Typography color={'common.error'} variant={'s3'}>Section M or L are missing some data</Typography>}
        {step === 2 && <LoadingButton loading={shreddingDocs} fullWidth variant={'primaryMain'}
          onClick={shred}>{proposalType === 'RFI' ? 'Answer to Questions' : 'Shred'}</LoadingButton>}
        {step === 1 &&
          <Button fullWidth variant={'primaryMain'} onClick={confirmStepTwo}>Next</Button>}
        {step === 0 && <Button fullWidth variant={'primaryMain'} onClick={nextButton}>Next</Button>}
        <Button fullWidth variant={'secondaryMain'} sx={{ display: step > 0 ? 'flex' : 'none' }}
          onClick={backButton}>Back</Button>
      </Stack>
    </Box>
  );
};
