import { Document, Paragraph, TextRun } from 'docx';

// take an array of entries and generate a new section with a title and an entry
// for each entry in the array
// eslint-disable-next-line no-unused-vars
const generateSection = (title, entries) => {
  const section = [];
  section.push(new Paragraph({ children: [new TextRun(title)] }));
  entries.forEach((entry) => {
    section.push(new Paragraph({ children: [new TextRun(entry)] }));
  });
  return section;
};

export const proposalDocGenerator = ({ proposal, proposalDetails }) => {
  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: `Proposal Name: ${proposal.proposalName}\n`,
                size: 40,
                bold: true,
              }),
              new TextRun({
                text: `Proposal Owner: ${proposal.proposalOwner}\n\n`,
                size: 24,
              }),
            ],
          }),

          ...proposalDetails.map(
            ({ task_name, ai_response }) =>
              new Paragraph({
                spacing: {
                  before: 200,
                },
                children: [
                  new TextRun({
                    text: `${task_name}\n`,
                    size: 24,
                    bold: true,
                  }),

                  new TextRun({
                    text: `${ai_response}\n\n`,
                    size: 24,
                  }),
                ],
              })
          ),
        ],
      },
    ],
  });

  return doc;
};
