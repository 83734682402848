//REACT IMPORTS
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import { FieldHelper } from "../helpers/FieldHelper";

export const PerformanceObjetivesCard = ({allTasks, setAllTasks, clientList, capabilityList}) =>   {

    const [ taskName, setTaskName ] = useState('');
    const [ taskDescription, setTaskDescription ] = useState('');
    const [ client, setClient ] = useState('placeholder');
    const [ clientDescription, setClientDescription ] = useState('');
    const [ taskToAdd, setTaskToAdd ] = useState({})
    const [ relevantCapabilities, setRelevantCapabilities ] = useState([]);
    const [ taskTools, setTaskTools ] = useState('');

    useEffect(() => {
        setTaskToAdd({
            task_name: taskName,
            task_description: taskDescription,
            client_id: client === 'placeholder' ? "-1" : client,
            client_description: clientDescription.length === 0 ? "" : clientDescription,
            task_tools: taskTools,
            task_capabilities: relevantCapabilities.toString()
        })
    }, [taskName, taskDescription, client, clientDescription, taskTools, relevantCapabilities])

    const pushToArray = () => {
        if(taskName.length > 0 && taskDescription.length > 0){
            setAllTasks([...allTasks, taskToAdd]);
            setTaskName('');
            setTaskDescription('');
            setClient('placeholder');
            setClientDescription('');
            setRelevantCapabilities([]);
            setTaskTools('');
        }
    }

    const handleChange = (event) => {
        const { target: { value } } = event;
        if(relevantCapabilities.length < 5){
            setRelevantCapabilities(typeof value === 'string' ? value.split(',') : value);
        }
    };

    return(
        <Box
            width={1}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            p={'2.4rem 1.6rem'}
            gap={'2.4rem'}
            borderRadius={'.8rem'}
            bgcolor={'grey.grey1'}>
            <Stack width={1} direction={'column'} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Task </Typography>
                    <FieldHelper
                        suggestion={'Input the name of the task you want to generate content for'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={taskName}
                    inputProps={{ maxLength: 256 }}
                    onChange={(event) => setTaskName(event.target.value)}
                    placeholder={'Enter task'} />
            </Stack>
            <Stack
                width={1}
                mt={'2,4rem'}
                direction={'column'}
                spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Task Description </Typography>
                    <FieldHelper
                        suggestion={'Input a description of the services to be performed under this task, process/methodologies to be used as well as any end users to be supported'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows={5}
                    value={taskDescription}
                    inputProps={{ minLength: 400, maxLength: 1500 }}
                    onChange={(event) => setTaskDescription(event.target.value)}
                    placeholder={'Enter description about task'} />
                <FormHelperText>{`Min 400 characters, ${taskDescription.length}/${1500} characters`}</FormHelperText>
            </Stack>

            <Stack width={1} direction={'column'} spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Task Tools </Typography>
                    <FieldHelper
                        suggestion={'Include tools that will used to complete this task'} />
                </Stack>
                <OutlinedInput
                    fullWidth
                    value={taskTools}
                    inputProps={{ maxLength: 128 }}
                    onChange={(event) => setTaskTools(event.target.value)}
                    placeholder={'Enter task tools'} />
            </Stack>

            <Stack
                width={1}
                direction={'column'}
                spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Relevant Capabilities </Typography>
                    <FieldHelper
                        suggestion={'Enter corporate capabilities related to this opportunity'} />
                </Stack>
                <FormControl>
                    <InputLabel id={'relevant-capabilities-label'} sx={{fontSize: '1.6rem'}}>Select</InputLabel>
                    <Select
                        multiple
                        labelId={'relevant-capabilities-label'}
                        inputProps={{ maxLength: 256 }}
                        label={'--------------'}
                        value={relevantCapabilities}
                        onChange={handleChange}>
                        {capabilityList && capabilityList.map((cap, index) => (
                            <MenuItem key={index} value={cap}>{cap}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>

            <Stack
                width={1}
                mt={'2,4rem'}
                direction={'column'}
                spacing={'1.2rem'}>
                <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'s3'}> Relevant Past Performance(s) </Typography>
                    <FieldHelper
                        suggestion={'Select the past performance that is relevant to this task area from the drop down'} />
                </Stack>
                <Select value={client} onChange={(event) => setClient(event.target.value)}>
                    <MenuItem value={'placeholder'} disabled>Select</MenuItem>
                    {clientList && clientList.map((client, index) => (
                        <MenuItem key={index} value={client.client_id}>{`${client.client_name} - ${client.client_industry}`}</MenuItem>
                    ))}
                </Select>
            </Stack>

            <Stack
                height={client === 'placeholder' ? 0 : 'auto'}
                overflow={'hidden'}
                width={1}
                mt={'2,4rem'}
                direction={'column'}
                spacing={'1.2rem'}
                sx={{ transition: 'all ease 1s', opacity: client === 'placeholder' ? 0 : 1 }}>
                    <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                        <Typography color={'common.black'} variant={'s3'}> Past Performance Description </Typography>
                        <FieldHelper
                            suggestion={'Input the work you performed on this project that is relevant to this task area'} />
                    </Stack>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows={5}
                    value={clientDescription}
                    inputProps={{ minLength: 400, maxLength: 1500 }}
                    onChange={(event) => setClientDescription(event.target.value)}
                    placeholder={'Enter description about past performances'} />
                <FormHelperText>{`Min 400 characters, ${clientDescription.length}/${1500} characters`}</FormHelperText>
            </Stack>

            <Button
                fullWidth
                onClick={pushToArray}
                variant={'primaryNavbar'}>
                Save task
            </Button>
        </Box>
    )
};
