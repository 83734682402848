import { Box, Typography } from '@mui/material';
import { ShredCustomFieldsCard } from '../../../components/cards/ShredCustomFieldsCard';

export const ShredDocumentsStepTwoRFP = ({fileList, customSections, setCustomSections}) => {
  return (
    <Box
      width={1}
      display={'flex'}
      flexDirection={'column'}
      spacing={'4.8rem'}
      gap={'2.4rem'}
    >
      <Typography variant={'h2'} textAlign={'center'} sx={{mb: '4.8rem'}}>Custom Fields</Typography>
      <ShredCustomFieldsCard
        type={'fixed'}
        fileList={fileList}
        customSections={customSections}
        setCustomSections={setCustomSections} />
    </Box>
  );
};
