
import { DeleteOutline, EditOutlined } from "@mui/icons-material"
import { Box, Button, IconButton, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { FieldHelper } from "../helpers/FieldHelper";

export const ShredCustomFieldsCard = ({fileList, customSections, setCustomSections}) => {

    const [sectionName, setSectionName] = useState('');
    const [sectionPages, setSectionPages] = useState('');
    const [sectionTitleFile, setSectionTitleFile] = useState('placeholder');

    const [newField, setNewField] = useState({});

    useEffect(() => {
        setNewField({
            title: sectionName,
            filename: sectionTitleFile,
            pages: sectionPages,
        })
    }, [sectionPages, sectionName, sectionTitleFile])

    const addFields = () => {
        if(sectionName !== '' && sectionPages !== '' && sectionTitleFile !== 'placeholder'){
            setCustomSections([...customSections, newField])

            setSectionName('');
            setSectionPages('');
            setSectionTitleFile('placeholder');
        }else{return}
    }

    const editField = (field, index) => {
        setSectionName(field.title);
        setSectionPages(field.pages);
        setSectionTitleFile(field.filename);

        setCustomSections(customSections.filter((field, i) => i !== index))
    }

    const deleteField = (index) => {
        setCustomSections(customSections.filter((field, i) => i !== index))
    }

    return(
        <Box
            width={1}
            bgcolor={'grey.grey1'}
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            borderRadius={'.8rem'}
            display={'flex'}
            flexDirection={'column'}
            gap={'1.6rem'}
            p={'2.4rem'}>
            <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                <Typography variant={'h6'}>
                    Create a Custom Section
                </Typography>
                <FieldHelper
                    suggestion={'give sections that want to be captured out outside of section L, M and C'} />
            </Stack>

            {customSections && customSections.map((field, index) => (
                <Stack spacing={'1.2rem'} key={index} border={'.1rem solid'} borderColor={'grey.subtle'} borderRadius={'.8rem'} padding={'1.2rem'}>
                    <Stack direction={'row'} spacing={'1.2rem'}>
                        <Typography variant={'h5'} width={1}>
                            {field.title}
                        </Typography>
                        <Stack direction={'row'} spacing={'1.2rem'}>
                            <IconButton onClick={() => editField(field, index)} sx={{ border: '.1rem solid', borderColor: 'grey.mainGrey', bgcolor: 'common.white', minWidth: '3.6rem', maxWidth: '3.6rem', height: '3.6rem'}}>
                                <EditOutlined fontSize={'large'} sx={{ color: 'grey.mainGrey' }} />
                            </IconButton>
                            <IconButton onClick={() => deleteField(index)} sx={{ border: '.1rem solid', borderColor: 'common.error', bgcolor: 'common.white', minWidth: '3.6rem', maxWidth: '3.6rem', height: '3.6rem'}}>
                                <DeleteOutline fontSize={'large'} sx={{ color: 'common.error' }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} spacing={'1.2rem'}>
                        <Typography variant={'bodyLight'} width={1}>
                            {field.pages}
                        </Typography>
                        <Typography variant={'s3'}>
                            {field.filename}
                        </Typography>
                    </Stack>
                </Stack>
            ))}

            <Stack spacing={'1.2rem'}>
                <Stack direction={'row'} spacing={'1.2rem'}>
                    <OutlinedInput
                        fullWidth
                        placeholder={'Write a custom section title here:'}
                        value={sectionName}
                        onChange={(e) => setSectionName(e.target.value)} />
                </Stack>
                <Stack direction={'row'} spacing={'1.2rem'}>
                    <OutlinedInput
                        fullWidth
                        placeholder={'Example: 12-20'}
                        value={sectionPages}
                        onChange={(e) => setSectionPages(e.target.value)} />
                    <Select
                      fullWidth
                      sx={{
                      maxWidth: '50%',
                      height: '6.4rem',
                      borderRadius: '1.5rem',
                      borderColor: 'grey.grey3',
                      backgroundColor: 'common.white',
                      color: 'grey.main',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.03)'
                      }
                    }}
                    value={sectionTitleFile}
                    onChange={(event) => setSectionTitleFile(event.target.value)}
                  >
                    <MenuItem value={'placeholder'} disabled>Select Doc</MenuItem>
                    {fileList && fileList.map((file, index) => (
                      <MenuItem key={index} value={file.name}>{file.name}</MenuItem>
                    ))}
                  </Select>
                </Stack>
            </Stack>

            <Button variant={'primaryMain'} onClick={addFields}>
                Create Custom Section
            </Button>

        </Box>
    )
}
