import { Box, FormHelperText, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { NBOnlyLogo } from '@/components/navbar/NBOnlyLogo';
import { Link as RouterLink } from 'react-router-dom';
import Calendly from '@/components/demo/Calendly';

import { API } from 'aws-amplify';

export const Demo = () => {
  const [userName, setUserName] = useState('');
  const [solvingReason, setSolvingReason] = useState('');
  const [error, setError] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [userError, setUserError] = React.useState('');
  const [solvingReasonError, setSolvingReasonError] = React.useState('');

  const [demoType, setDemoType] = React.useState('video');

  const [step, setStep] = useState(0);

  const goToNextStep = async () => {
    if (step === 1) {
      setStep(2);

      try {
        await API.post('api2e29c07a', '/demo', {
          body: {
            firstName,
            lastName,
            email: userName,
            company: companyName,
            phone,
            message: solvingReason
          }
        });
      } catch (e) {
        console.log(e);
      }

      return;
    }

    setError('');
    setUserError('');
    setSolvingReasonError('');

    if (userName === '') {
      setUserError('Please enter a username');
      return;
    }

    // username must be email, check with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userName)) {
      setUserError('Please enter a valid email address');
      return;
    }

    if (solvingReason === '') {
      setSolvingReasonError('Please enter a reason');
      return;
    }

    setStep(1);
  };

  if (step === 1 && demoType === 'meeting') {
    return (
      <Calendly height={'95vh'} minWidth={'95vw'}
        url={`https://calendly.com/proposalwriter_ai/60min?a1=${solvingReason}&email=${userName}`} />
    );
  }

  if (step === 2) {
    return (
      <Box
        width={'100vw'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={step === 1 && demoType === 'meeting' ? { xs: '1rem' } : { xs: '2.2rem', md: '8rem' }}>
        <NBOnlyLogo />
        <Box
          width={1}
          // maxWidth={'60rem'}
          px={{ xs: '2.4rem', md: 0 }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Stack
            direction={'column'}
            textAlign={'center'}
            mb={{ xs: '3.2rem', md: '5rem' }}
          >
            <Typography
              variant={'h1'}
              color={'common.black'}
            >
              Thank you
            </Typography>
            <Typography
              variant={'body'}
              color={'grey.main'}
            >
              Thank you for your interest in Proposal Writer.
            </Typography>
          </Stack>

          <Box sx={{maxHeight: '800px'}}>
            <video width='100%' height='100%' controls src={'/video/Demo.mp4'} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={step === 1 && demoType === 'meeting' ? { xs: '1rem' } : { xs: '2.2rem', md: '8rem' }}>
      <NBOnlyLogo />
      <Box
        width={1}
        maxWidth={'60rem'}
        px={{ xs: '2.4rem', md: 0 }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >

        {step === 0 &&
          (
            <>
              <Stack
                direction={'column'}
                textAlign={'center'}
                mb={{ xs: '3.2rem', md: '5rem' }}
              >
                <Typography
                  variant={'h1'}
                  color={'common.black'}
                >
                  What's your business
                </Typography>
                <Typography
                  variant={'body'}
                  color={'grey.main'}
                >
                  Tell us a little bit about yourself so we can tailor your experience.
                </Typography>
              </Stack>
              <ToggleButtonGroup
                sx={{
                  mb: 2
                }}
                color='primary'
                value={demoType}
                exclusive
                onChange={(_, newVal) => newVal ? setDemoType(newVal) : null}
                aria-label='Platform'
              >
                <ToggleButton value='video'>Video</ToggleButton>
                <ToggleButton value='meeting'>Web Meeting</ToggleButton>
              </ToggleButtonGroup>

              <Stack
                width={1}
                mb={'1.5rem'}>
                <TextField
                  variant={'outlined'}
                  fullWidth
                  placeholder={'Business email'}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  error={userError !== ''}
                  helperText={userError}
                />
                <TextField
                  variant={'outlined'}
                  fullWidth
                  type={'text'}
                  placeholder={'What is your current proposal writing process?'}
                  sx={{
                    mt: '1.6rem'
                  }}
                  value={solvingReason}
                  onChange={(e) => setSolvingReason(e.target.value)}
                  helperText={solvingReasonError}
                />
              </Stack>
            </>
          )
        }

        {step === 1 && (
          <>
            <Stack
              direction={'column'}
              textAlign={'center'}
              mb={{ xs: '3.2rem', md: '5rem' }}
            >
              <Typography
                variant={'h1'}
                color={'common.black'}
              >
                You're nearly there
              </Typography>
              <Typography
                variant={'body'}
                color={'grey.main'}
              >
                Tell us a little bit about yourself so we can tailor your experience.
              </Typography>
            </Stack>

            <Stack
              width={1}
              mb={'1.5rem'}>
              <Stack direction={'row'} spacing={'1.2rem'}>
                <TextField
                  variant={'outlined'}
                  fullWidth
                  placeholder={'First name'}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  variant={'outlined'}
                  fullWidth
                  placeholder={'Last name'}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  sx={{
                    mt: '1.6rem'
                  }}
                />
              </Stack>
              <TextField
                variant={'outlined'}
                fullWidth
                placeholder={'Phone number'}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{
                  mt: '1.6rem'
                }}
              />
              <TextField
                variant={'outlined'}
                fullWidth
                placeholder={'Company name'}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{
                  mt: '1.6rem'
                }}
              />
            </Stack>
          </>
        )}

        <Typography
          variant={'body'}
          textAlign={'center'}
          mb={'1.5rem'}
          color={'grey.main'}
        >
          By submitting my personal data, I consent to proposalwriter.ai collecting, processing and storing my
          information in accordance with the
          <RouterLink
            to={'/legal/privacy-policy'}
            style={{ textDecoration: 'none' }}
            target={'_blank'}
          >
            <Typography
              variant={'body'}
              color={'common.link'}
            >
              {` Privacy Policy`}
            </Typography>
          </RouterLink>
        </Typography>

        <LoadingButton
          fullWidth
          variant={'primaryMain'}
          onClick={goToNextStep}
          disabled={!userName || !solvingReason || !demoType}
        >
          {step === 0 ? 'Next' : 'Submit'}
        </LoadingButton>

        <FormHelperText error>
          {error}
        </FormHelperText>
      </Box>
    </Box>
  );
};
