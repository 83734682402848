import { Box, Button, Stack, Typography } from '@mui/material';

export const CompanyCard = ({ companyName, companyId, currentCompany }) => {

  const changeCompanyHandler = () => {
    localStorage.setItem('localCompanyId', companyId);
    window.location.reload();
  };

  return (
    <Box
      width={1}
      maxWidth={'52rem'}
      padding={'2.4rem'}
      border={'.1rem solid'}
      borderColor={'grey.subtle'}
      borderRadius={'1.5rem'}>
      <Stack
        width={1}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Typography variant={'h4'}>
          {companyName ? companyName : 'Account Name'}
        </Typography>
        {currentCompany === companyId
          ? <Typography
            variant={'body'}
            padding={'.4rem 1rem'}
            borderRadius={'.5rem'}
            fontWeight={600}
            bgcolor={'primary.main'}>
            Current
          </Typography>
          : <Button
            variant={'secondaryThin'}
            onClick={changeCompanyHandler}>
            Switch to this account
          </Button>}
      </Stack>
    </Box>
  );
};
