import { Box, Button, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useUserStore } from '../../store/userStore';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export const SignIn = () => {
  const oauthError = useUserStore((state) => state.oauthError);
  const setOauthError = useUserStore((state) => state.setOauthError);

  const lastUsername = useUserStore((state) => state.lastUsername);
  const setLastUsername = useUserStore((state) => state.setLastUsername);

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState(lastUsername ?? '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [userError, setUserError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  const navigate = useNavigate();

  const signIn = async () => {
    setError('');
    setUserError('');
    setPasswordError('');
    setOauthError('');

    if (userName === '') {
      setUserError('Please enter a username');
      return;
    }

    // username must be email, check with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userName)) {
      setUserError('Please enter a valid email address');
      return;
    }

    if (password === '') {
      setPasswordError('Please enter a password');
      return;
    }

    // check password length
    if (password.length < 8) {
      setPasswordError('Must include at least 8 characters');
      return;
    }

    setLoading(true);
    try {
      setLastUsername(userName);
      await Auth.signIn(userName, password);

      navigate('/users/my-proposals');
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        navigate('/email-confirmation');
      }
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <Box
      width={1}
      maxWidth={'50rem'}
      px={{ xs: '2.4rem', md: 0 }}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Stack
        direction={'column'}
        textAlign={'center'}
        mb={{ xs: '3.2rem', md: '5rem' }}>
        <Typography
          variant={'body'}
          color={'grey.main'}>Welcome!</Typography>
        <Typography
          variant={'h1'}
          color={'common.black'}>
          Log In
        </Typography>
        <Typography variant={'s4'} textAlign={'center'} mt={'2.4rem'}>
          <Typography fontWeight={700} variant={'s4'}>Disclaimer:</Typography> Please note that <Link
          href={'https://www.proposalwriter.ai'} variant={'s4'} color={'common.link'}
          underline={'hover'}>Proposalwriter.ai</Link> is currently in a beta phase.
          While we have made every effort to ensure the accuracy and functionality of the service,
          there may still be some issues or limitations.
          We welcome any feedback or suggestions you may have to help us improve the service.
          Thank you for your understanding and patience as we continue to develop and refine <Link variant={'s4'}
          href={'https://www.proposalwriter.ai'} color={'common.link'} underline={'hover'}>Proposalwriter.ai</Link>.
          For login issues please talk with the chatbot or send an email to <Typography component={'a'} variant={'s4'}
          color={'common.link'} sx={{ cursor: 'pointer' }}
          href={'mailto:tech-support@proposalwriter.ai'}>tech-support@proposalwriter.ai</Typography>
        </Typography>
      </Stack>

      <Stack
        width={1}
        mb={'5.7rem'}>
        <TextField
          variant={'outlined'}
          fullWidth
          placeholder={'Business email'}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          error={userError !== ''}
          helperText={userError}
        />
        <TextField
          variant={'outlined'}
          fullWidth
          type={'password'}
          placeholder={'Password'}
          sx={{
            mt: '1.6rem'
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError !== ''}
          helperText={passwordError}
        />
        <Typography
          variant={'body'}
          textAlign={'center'}
          mt={'3.2rem'}
          color={'common.black'}
        >
          Forgot your password?
          <RouterLink
            to={'/forgot-password'}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant={'body'}
              color={'common.link'}
            >
              {` Recover password`}
            </Typography>
          </RouterLink>
        </Typography>
      </Stack>

      <LoadingButton
        fullWidth
        variant={'primaryMain'}
        onClick={signIn}
        loading={loading}
      >
        Log In
      </LoadingButton>

      <FormHelperText error>
        {error || oauthError}
      </FormHelperText>
    </Box>
  );
};
