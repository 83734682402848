//REACT IMPORTS
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';

//DATA IMPORTS
import { FieldHelper } from '../helpers/FieldHelper';
import { InputWithBubbles } from '../input/InputWithBubbles';
import { RewriteClientDescriptionModal } from '../modals/RewriteClientDescriptionModal';

export const ContractCard = ({
                               companyName,
                               myClients, setMyClients,
                               roleOnContract, setRoleOnContact,
                               organizationName, setOrganizationName,
                               contractName, setContractName,
                               skillsUsed, setSkillsUsed,
                               metricsUsed, setMetricsUsed,
                               clientDescription, setClientDescription,
                               showDescription, setShowDescription
                             }) => {

  const [addClient, setAddClient] = useState({});

  const [loadingDescription, setLoadingDescription] = useState(false);
  const [rewriting, setRewriting] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setAddClient({
      client_name: organizationName,
      role_on_contract: roleOnContract,
      skills_used: skillsUsed.toString(),
      contract_name: contractName,
      metrics_used: metricsUsed.toString(),
      client_description: clientDescription.length > 0 ? clientDescription : ''
    });
  }, [clientDescription, contractName, metricsUsed, organizationName, roleOnContract, skillsUsed]);

  const PushToArray = () => {
    if (organizationName.length > 0) {
      setMyClients([...myClients, addClient]);
      setOrganizationName('');
      setRoleOnContact('');
      setSkillsUsed([]);
      setContractName('');
      setMetricsUsed([]);
      setClientDescription('');
      setShowDescription(false);
    }
  };

  const GenerateDescription = async () => {
    setLoadingDescription(true);
    try {
      const response = await API.post('generateClientDescription', '', {
        body: {
          company_name: companyName,
          client_name: organizationName,
          role_on_contract: roleOnContract,
          contract_name: contractName,
          skills_used: skillsUsed.toString(),
          metrics_used: metricsUsed.toString()
        }
      });
      setClientDescription(response);
      setShowDescription(true);
    } catch (e) {
      console.log(e);
      // setClientDescription('There was an error in your request. Please try again.');
      // setShowDescription(true)
    }
    setLoadingDescription(false);
  };

  const RewriteDescription = async () => {
    setRewriting(true);
    setOpenModal(false);
    try {
      const result = await API.post('regenerateClientDescription', '', {
        body: {
          prompt: promptMessage,
          company_name: companyName,
          client_name: organizationName,
          role_on_contract: roleOnContract,
          contract_name: contractName,
          skills_used: skillsUsed.toString(),
          metrics_used: metricsUsed.toString()
        }
      });
      setClientDescription(result);
    } catch (e) {
      console.log(e);
      // setClientDescription('There was an error in your request. Please try again.');
      // setShowDescription(true)
    }
    setRewriting(false);
  };

  const modalHandler = () => setOpenModal(!openModal);

  return (
    <Box
      width={1}
      bgcolor={'grey.grey1'}
      border={'.1rem solid'}
      borderColor={'grey.subtle'}
      borderRadius={'.8rem'}>
      <Modal open={openModal} onClose={modalHandler}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          px={{ xs: '1.6rem', md: 0 }}>
          <RewriteClientDescriptionModal
            modalHandler={modalHandler}
            rewriteDescription={RewriteDescription}
            promptMessage={promptMessage}
            setPromptMessage={setPromptMessage} />
        </Box>
      </Modal>

      <Stack
        width={1}
        p={'2.4rem'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        boxShadow={'inset 0rem -.1rem 0rem #B8BAC6'}>
        <Typography variant={'s2'}>Contract Information</Typography>
      </Stack>

      <Stack width={1} p={'1.6rem'} spacing={'1.2rem'}>
        <Stack width={1} spacing={'1.2rem'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Role on Contract </Typography>
            <FieldHelper
              suggestion={'Check the role your company played on the contract or is this internal experience'} />
          </Stack>
          <RadioGroup
            value={roleOnContract}
            onChange={(event) => setRoleOnContact(event.target.value)}
            defaultValue={roleOnContract}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: 1,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <FormControlLabel value='Prime' control={<Radio size={'medium'} />} label='Prime' />
            <FormControlLabel value='Subcontractor' control={<Radio size={'medium'} />} label='Subcontractor' />
            <FormControlLabel value='Corporate Experience' control={<Radio size={'medium'} />}
              label='Corporate Experience' />
          </RadioGroup>
        </Stack>

        <Stack width={1} spacing={'1.2rem'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Customer Organization </Typography>
            <FieldHelper
              suggestion={'Give the name of the organization your company supported'} />
          </Stack>
          <OutlinedInput
            fullWidth
            value={organizationName}
            inputProps={{ maxLength: 256 }}
            onChange={(event) => setOrganizationName(event.target.value)}
            placeholder={'Customer Organization'} />
        </Stack>

        <Stack width={1} spacing={'1.2rem'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Contract Name </Typography>
            <FieldHelper
              suggestion={'Provide the name of the contract'} />
          </Stack>
          <OutlinedInput
            fullWidth
            value={contractName}
            inputProps={{ maxLength: 256 }}
            onChange={(event) => setContractName(event.target.value)}
            placeholder={'Jon Doe'} />
        </Stack>

        <Stack width={1} spacing={'1.2rem'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Insert Hardware, Tools, Skilled Used </Typography>
            <FieldHelper
              suggestion={'Give keyword terms and phrases to describe tool, methodologies or hardware to support this contract'} />
          </Stack>
          <InputWithBubbles
            placeholder={'Add skill'}
            maxTags={20}
            tags={skillsUsed}
            setTags={setSkillsUsed} />
          <Typography color={'grey.grey3'} variant={'s4'}>
            {'<Enter> to confirm skills, minimum one skill'}
          </Typography>
        </Stack>

        <Stack
          width={1}
          mt={'6.4rem'}
          direction={'column'}
          spacing={'1.2rem'}
          justifyContent={'start'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}>
              Provide Metrics
            </Typography>
            <FieldHelper
              suggestion={'Provide metrics that were collected.  Example: Supported 100 users'} />
          </Stack>
          <InputWithBubbles
            placeholder={'Add metric'}
            maxTags={20}
            tags={metricsUsed}
            setTags={setMetricsUsed} />
          <Typography color={'grey.grey3'} variant={'s4'}>
            {'<Enter> to confirm metrics, minimum one metric'}
          </Typography>
        </Stack>

        <Stack width={1} spacing={'1.2rem'} display={showDescription ? 'flex' : 'none'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Past Performance Description </Typography>
            <FieldHelper
              suggestion={'This information was generated based on the inputs above. Press rewrite below to regenerate.'} />
          </Stack>
          <Typography
            bgcolor={'common.white'}
            borderRadius={'1.5rem'}
            padding={'1.2rem'}
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            variant={'s3'}
            fontWeight={300}
            whiteSpace={'pre-word'}>{clientDescription}</Typography>
        </Stack>

        <LoadingButton
          fullWidth
          loading={rewriting}
          variant={'secondaryMain'}
          onClick={modalHandler}
          sx={{
            display: !showDescription ? 'none' : 'flex'
          }}
          disabled={metricsUsed.length === 0 || skillsUsed.length === 0}
        >
          Rewrite
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={loadingDescription}
          variant={'primaryMain'}
          disabled={metricsUsed.length === 0 || skillsUsed.length === 0}
          onClick={!showDescription ? GenerateDescription : PushToArray}>
          {!showDescription ? 'Write Description' : 'Save'}
        </LoadingButton>

        <Button
          fullWidth
          sx={{ mt: '2.4rem', display: 'none' }}
          variant={'primaryMain'}
          onClick={PushToArray}>
          Add Client
        </Button>
      </Stack>
    </Box>
  );
};
