//REACT & MUI IMPORTS
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Box, CircularProgress, Divider, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

//COMPONENT IMPORTS
import { useUserStore } from '../../../store/userStore';
import { API } from 'aws-amplify';
import { GET_ALL_PROPOSALS_API } from '../../../const';
import { CreateProposalItem } from './CreateProposalItem';

export const ProposalFromDocStepOne = ({tasks, clientList, selectedTasks, setSelectedTasks}) => {
  const company = useUserStore(state => state.company);


  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const proposalsPerPage = 10;
  const totalPages = Math.ceil(tasks.length / proposalsPerPage);

  const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <Box
      width={1}
      maxWidth={'127rem'}
      mt={{xs: 0, md: '-4rem'}}
      mb={totalPages > 1 ? 0 : '7.2rem'}
      px={{xs: '1.6rem', md: '4.8rem'}}
      display={'flex'}
      flexDirection={'column'}
      gap={'4.8rem'}>
      <Typography
        color={'common.black'}
        textAlign={'center'}
        variant={'h3'}>
        Select The Task Sections To Be Added Into Your Technical Volume With your Relevant Past Performance:
      </Typography>
      <Box
        width={1}>
        <Stack
          display={{xs: 'none', md: 'flex'}}
          width={1}
          height={'4.8rem'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Box
            width={'10%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Selection
            </Typography>
          </Box>
          <Box
            width={'15%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography
              textAlign={'left'}
              color={'common.black'}
              variant={'s2'}>
              Task Sub-Title
            </Typography>
          </Box>
          <Box
            width={'15%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography
              textAlign={'left'}
              color={'common.black'}
              variant={'s2'}>
              Task Sub-Sub-Title
            </Typography>
          </Box>
          <Box
            width={'30%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Task Activity
            </Typography>
          </Box>
          <Box
            width={'30%'}
            height={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              color={'common.black'}
              variant={'s2'}>
              Select your Relevant Task Performance
            </Typography>
          </Box>
        </Stack>
        <Divider flexItem sx={{display: {xs: 'none', md: 'flex'}}} />

        <Box
          width={1}
          display={'flex'}
          flexDirection={'column'}
          gap={'1.2rem'}
          py={{xs: 0, md: '2.4rem'}}>
          {tasks.length > 0 && tasks.slice((currentPage - 1) * proposalsPerPage, currentPage * proposalsPerPage).map((task, index) => (
            <CreateProposalItem
              key={task.index}
              taskIndex={task.index}
              taskSubTitle={task.subtitle}
              taskSubSubTitle={task.subsubtitle}
              taskActivity={task.text}
              clientList={clientList}
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks} />
          ))}
        </Box>
      </Box>

      <Box
        width={1}
        display={totalPages > 1 ? 'flex' : 'none'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1.2rem'}
        p={'1.6rem'}
        mb={'5rem'}>
        <IconButton
          disabled={currentPage === 1 ? true : false}
          onClick={prevButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main',
          }}>
          <ArrowBackIosNewRounded />
        </IconButton>
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'small'}
          sx={{display: {xs: 'flex', md: 'none'}}}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <Pagination
          page={currentPage}
          count={totalPages}
          shape={'rounded'}
          size={'large'}
          sx={{display: {xs: 'none', md: 'flex'}}}
          onChange={(event, value) => setCurrentPage(value)}
          hideNextButton
          hidePrevButton />
        <IconButton
          disabled={currentPage === totalPages ? true : false}
          onClick={nextButton}
          sx={{
            width: '3.6rem',
            height: '3.6rem',
            color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
            border: '.1rem solid',
            borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
          }}>
          <ArrowForwardIosRounded />
        </IconButton>
      </Box>
    </Box>
  );
};
