//REACT IMPORTS
import { Box, Button, CircularProgress, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

//DATA IMPORTS
import { GET_CLIENT_LIST_API, GET_RELEVANT_CAPABILITIES_LIST_API, POST_CREATE_FULL_PROPOSAL_API } from '../../const';

//COMPONENT IMPORTS
import { CreateProposalStepOne } from './components/CreateProposalStepOne';
import { CreateProposalStepTwo } from './components/CreateProposalStepTwo';
import { ProposalDetail } from './ProposalDetail';
import { useUserStore } from '../../store/userStore';
import { API } from 'aws-amplify';

//LOCAL DATA
const stepLabels = ['Client', 'Content', 'Proposal'];

export const CreateProposal = () => {

  const [clientList, setClientList] = useState([]);
  const [isSendingData, setIsSendingData] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [step, setStep] = useState(0);
  const [capabilityList, setCapabilityList] = useState([])

  const company = useUserStore(state => state.company);

  const fetchClientList = async () => {
    try {
      let response = await API.get('pyBackend', `${GET_CLIENT_LIST_API}${company.company_id}`);
      setClientList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRelevantCapabilities = async () => {
    try {
      // let response = await fetch(`${GET_RELEVANT_CAPABILITIES_LIST_API}${company.company_id}`);
      // response = await response.json();
      let response = await API.get('pyBackend', `${GET_RELEVANT_CAPABILITIES_LIST_API}${company.company_id}`)
      setCapabilityList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await fetchClientList();
    await fetchRelevantCapabilities();
    setLoading(false);
  };

  useState(() => {
    if (company) {
      fetchData();
    }
  }, [company]);

  //States
  //STEP 1
  const [clientName, setClientName] = useState('');
  const [proposalTitle, setProposalTitle] = useState('');
  const [proposalType, setProposalType] = useState('placeholder');
  const [primaryPurpose, setPrimaryPurpose] = useState('Product');
  const [companyDescription, setCompanyDescription] = useState('');

  //STEP 2
  const [allTasks, setAllTasks] = useState([]);

  const sendProposalData = async () => {
    fetch(POST_CREATE_FULL_PROPOSAL_API, {
      method: 'POST',
      body: JSON.stringify({
        company_id: company.company_id,
        proposal_name: proposalTitle,
        proposal_owner: clientName,
        rfp_rfi: proposalType === 'placeholder' ? 'RFI' : proposalType,
        purpose: primaryPurpose,
        short_description: companyDescription,
        proposal_tasks: allTasks
      }),
    })
      .then(response => response.json());
    // await API.post(POST_CREATE_FULL_PROPOSAL_API, {
    //   body: {
    //     company_id: company.company_id,
    //     proposal_name: proposalTitle,
    //     proposal_owner: clientName,
    //     rfp_rfi: proposalType === 'placeholder' ? 'RFI' : proposalType,
    //     purpose: primaryPurpose,
    //     short_description: companyDescription,
    //     proposal_tasks: allTasks
    //   }
    // })
  };

  const nextButton = () => {
    if (step < 1) {
      setStep(step + 1);
    } else if (step === 1) {
      setIsSendingData(true);
      sendProposalData();
      setStep(step + 1);
      setIsSendingData(false);
    }
  };

  const backButton = () => {
    setStep(step - 1);
  };

  if (isLoading) {
    return (
      <Box
        width={1}
        height={1}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      width={1}
      mt={{xs: 0, md: '-4rem'}}
      maxWidth={'55rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          mb: '3.2rem',
        }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step === 0 && <CreateProposalStepOne
        clientName={clientName}
        setClientName={setClientName}
        proposalTitle={proposalTitle}
        setProposalTitle={setProposalTitle}
        proposalType={proposalType}
        setProposalType={setProposalType}
        primaryPurpose={primaryPurpose}
        setPrimaryPurpose={setPrimaryPurpose}
        companyDescription={companyDescription}
        setCompanyDescription={setCompanyDescription} />}
      {step === 1 && <CreateProposalStepTwo
        allTasks={allTasks}
        setAllTasks={setAllTasks}
        sendProposalData={sendProposalData}
        clientList={clientList}
        capabilityList={capabilityList}
      />}
      {step === 2 && <ProposalDetail />}
      <Stack
        width={1}
        mt={'6.4rem'}
        mb={'16rem'}
        direction={'column'}
        spacing={'1.6rem'}>
        <LoadingButton
          fullWidth
          loading={isSendingData}
          variant={'primaryMain'}
          onClick={nextButton}
          sx={{display: step === 2 ? 'none' : 'block'}}>
          {step < 1 ? 'Next' : step === 1 ? 'Generate proposal' : 'Save Proposal'}
        </LoadingButton>
        <Button
          fullWidth
          variant={'secondaryMain'}
          onClick={backButton}
          sx={{display: step === 1 ? 'block' : 'none'}}>
          Back
        </Button>
      </Stack>
    </Box>
  );
};
