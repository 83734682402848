//REACT IMPORTS
import { Avatar, Box, IconButton, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';

//LOCAL DATA IMPORT
import IndustryList from '../../../assets/localData/IndustryList.json';
import { FieldHelper } from '../../../components/helpers/FieldHelper';
import { PhotoCamera } from '@mui/icons-material';
import { useState } from 'react';
import { Storage } from 'aws-amplify';
import { useUserStore } from '../../../store/userStore';

export const SettingsCompany = ({
                                  companyName,
                                  companyImage,
                                  companyIndustry,
                                  pointOfContact,
                                  setCompanyName,
                                  setCompanyImage,
                                  setCompanyIndustry,
                                  setPointOfContact,
                                }) => {

  const companyImageStore = useUserStore((state) => state.companyImage);
  const [image, setImage] = useState(companyImageStore);

  const selectImage = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setCompanyImage(e.target.files[0])
    }
  };

  return (
    <Box
      width={1}
      maxWidth={'45rem'}
      display={'flex'}
      flexDirection={'column'}
      gap={'2.4rem'}>
      <Stack spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Company Image </Typography>
        </Stack>
        <Box>

          <Avatar src={image} alt={'Company Image'} />
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input hidden accept="image/*" type="file" onChange={selectImage} />
            <PhotoCamera />
          </IconButton>
        </Box>
      </Stack>
      <Stack spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Company Name </Typography>
          <FieldHelper
            suggestion={'Input full name of your company'} />
        </Stack>
        <OutlinedInput
          fullWidth
          type={'text'}
          value={companyName}
          inputProps={{ maxLength: 32 }}
          onChange={(event) => setCompanyName(event.target.value)}
          placeholder={'Company Name'} />
      </Stack>
      <Stack spacing={'1.2rem'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Company Primary Industry </Typography>
          <FieldHelper
            suggestion={'Select the primary industry to sell to'} />
        </Stack>
        <Select
          value={companyIndustry}
          sx={{textTransform: 'capitalize'}}
          onChange={(event) => setCompanyIndustry(event.target.value)}>
          <MenuItem value={'placeholder'} disabled>Select</MenuItem>
          {IndustryList && IndustryList.map((industry, index) => (
            <MenuItem key={index} value={industry} sx={{textTransform: 'capitalize'}}>{industry}</MenuItem>
          ))}
        </Select>
      </Stack>

      <Box
        width={1}
        display={'flex'}
        flexDirection={'row'}
        gap={'1.2rem'}>
        <Stack width={1} spacing={'1.2rem'}>
          <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
            <Typography color={'common.black'} variant={'s3'}> Point of Contact </Typography>
            <FieldHelper
              suggestion={'Name of person completing this information'} />
          </Stack>
          <OutlinedInput
            fullWidth
            type={'text'}
            value={pointOfContact}
            onChange={(event) => setPointOfContact(event.target.value)}
            placeholder={'Point of Contact'} />
        </Stack>
      </Box>
    </Box>
  );
};
