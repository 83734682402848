import {Box, CircularProgress, Divider, IconButton, Pagination, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {ArrowBackIosNewRounded, ArrowForwardIosRounded} from '@mui/icons-material';
import {CompanyListItem} from './components/CompanyListItem';

export const Companies = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    //const [company_id, setCompanyId] = useState(17);

    const rowsPerPage = 10;
    const totalPages = Math.ceil(companies.length / rowsPerPage);

    const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
    const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    const fetchCompanies = async () => {
        setLoading(true)
        const response = await API.get('api2e29c07a', '/admin/companies', {});
        setCompanies(response.data);
        setLoading(false)
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <Box
            width={1}
            maxWidth={'127rem'}
            mt={{xs: 0, md: '-4rem'}}
            mb={totalPages > 1 ? 0 : '7.2rem'}
            px={{xs: '1.6rem', md: '4.8rem'}}
            display={'flex'}
            flexDirection={'column'}
            gap={'4.8rem'}>
            <Typography
                color={'common.black'}
                textAlign={{xs: 'center', md: 'left'}}
                variant={'h3'}>
                Companies
            </Typography>
            <Box
                width={1}>
                <Stack
                    display={{xs: 'none', md: 'flex'}}
                    width={1}
                    height={'4.8rem'}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Box
                        width={'15%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            ID
                        </Typography>
                    </Box>
                    <Box
                        width={'25%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            textAlign={'left'}
                            color={'common.black'}
                            variant={'s2'}>
                            Email
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Name
                        </Typography>
                    </Box>
                    <Box
                        width={'15%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Size
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Proposals
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Regenerations
                        </Typography>
                    </Box>
                    <Box
                        width={'10%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Type
                        </Typography>
                    </Box>
                    <Box
                        width={'5%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                        </Typography>
                    </Box>
                </Stack>
                <Divider flexItem sx={{display: {xs: 'none', md: 'flex'}}}/>
                {isLoading ? (
                        <Box sx={{m: 4}} width={1} display={'flex'} justifyContent={'center'}><CircularProgress/></Box>) :
                    <Box
                        width={1}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'1.2rem'}
                        py={{xs: 0, md: '2.4rem'}}>
                        {companies.length &&
                            companies.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
                                return <CompanyListItem
                                    key={index}
                                    businessSize={row.business_size}
                                    companyId={row.company_id}
                                    email={row.email}
                                    name={row.name}
                                    proposalsLeft={row.proposals_left}
                                    regenerationsLeft={row.regenerations_left}
                                    typeAccount={row.type_account}
                                    timeUsed={row.time_used}
                                    fetchData={fetchCompanies}
                                />;
                            })}
                    </Box>
                };
            </Box>
            <Box
                width={1}
                display={totalPages > 1 ? 'flex' : 'none'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'1.2rem'}
                p={'1.6rem'}
                mb={'5rem'}>
                <IconButton
                    disabled={currentPage === 1 ? true : false}
                    onClick={prevButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main',
                    }}>
                    <ArrowBackIosNewRounded/>
                </IconButton>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'small'}
                    sx={{display: {xs: 'flex', md: 'none'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'large'}
                    sx={{display: {xs: 'none', md: 'flex'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <IconButton
                    disabled={currentPage === totalPages ? true : false}
                    onClick={nextButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
                    }}>
                    <ArrowForwardIosRounded/>
                </IconButton>
            </Box>
        </Box>
    );
};
