//REACT IMPORTS
import { Box, FormControl, FormControlLabel, FormHelperText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material"
import { FieldHelper } from "../../../components/helpers/FieldHelper"

export const CreateProposalStepOne = ({
                                        clientName,
                                        setClientName,
                                        proposalTitle,
                                        setProposalTitle,
                                        proposalType,
                                        setProposalType,
                                        primaryPurpose,
                                        setPrimaryPurpose,
                                        companyDescription,
                                        setCompanyDescription,
                                    }) => {

    return(
        <Box
            width={1}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack direction={'column'} textAlign={'center'} mb={{xs: '3.2rem', md: '6rem'}}>
                <Typography variant={'body'} color={'grey.main'}> Let’s start with the basics! </Typography>
                <Typography color={'common.black'} textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Tell us about the RFP/RFI
                </Typography>
            </Stack>

            <Box width={1}>
                <Typography color={'common.black'} variant={'h4'}> General </Typography>
                <Stack mt={'2.4rem'} spacing={'1.2rem'}>
                    <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                        <Typography color={'common.black'} variant={'s3'}> Procuring Entity/Customer (Organization Name) </Typography>
                        <FieldHelper
                            suggestion={'Input the name of the procuring entity/customer'} />
                    </Stack>
                    <OutlinedInput
                        fullWidth
                        value={clientName}
                        inputProps={{ maxLength: 256 }}
                        onChange={(event) => setClientName(event.target.value)}
                        placeholder={'Type organization Name'} />
                </Stack>
                <Stack mt={'2.4rem'} spacing={'1.2rem'}>
                    <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                        <Typography color={'common.black'} variant={'s3'}> Title of Proposal </Typography>
                        <FieldHelper
                            suggestion={'Input the full name of the proposal'} />
                    </Stack>
                    <OutlinedInput
                        fullWidth
                        value={proposalTitle}
                        inputProps={{ maxLength: 256 }}
                        onChange={(event) => setProposalTitle(event.target.value)}
                        placeholder={'Type title'} />
                </Stack>

                <Stack mt={'2.4rem'} spacing={'1.2rem'} width={1}>
                    <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                        <Typography color={'common.black'} variant={'s3'}> Proposal type </Typography>
                        <FieldHelper
                            suggestion={'Select from the drop down'} />
                    </Stack>
                    <FormControl
                        sx={{width: '100%'}}>
                        <Select
                            value={proposalType}
                            onChange={(event) => setProposalType(event.target.value)}>
                            <MenuItem value={'placeholder'} disabled>Select</MenuItem>
                            <MenuItem value={'RFI'}>RFI Questionnaire / Capabilities</MenuItem>
                            <MenuItem value={'RFP'}>RFP</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <Stack mt={'2.4rem'}  width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                    <Typography color={'common.black'} variant={'h4'}> Primary Purpose </Typography>
                    <FieldHelper
                        suggestion={'Select product or service'} />
                </Stack>

                <RadioGroup
                    value={primaryPurpose}
                    onChange={(event) => setPrimaryPurpose(event.target.value)}
                    defaultValue={primaryPurpose}>
                    <FormControlLabel value="Product" control={<Radio size={'medium'} />} label="Product Type" />
                    <FormControlLabel value="Service" control={<Radio size={'medium'} />} label="Service Type" />
                </RadioGroup>

                <Stack mt={'3.4rem'} spacing={'1.2rem'}>
                    <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
                        <Typography color={'common.black'} variant={'s3'}> RFP/RFI Overview Summary </Typography>
                        <FieldHelper
                            suggestion={'Provide a detailed overview of the type of product/service being requested in the RFP/RFI.'} />
                    </Stack>
                    <OutlinedInput
                        fullWidth
                        multiline
                        rows={5}
                        value={companyDescription}
                        onChange={(event) => setCompanyDescription(event.target.value)}
                        inputProps={{ maxLength: 1500 }}
                        placeholder={'Enter description about RFP/RFI'} />
                    <FormHelperText>
                        {`Max ${1500 - companyDescription.length} characters`}
                    </FormHelperText>
                </Stack>
            </Box>
        </Box>
    )
}
