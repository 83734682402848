//REACT IMPORTS
import {Box, Button, FormControl, InputAdornment, OutlinedInput, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {API} from 'aws-amplify';

import {ReactComponent as CloseIcon} from '../../assets/icons/close-icon.svg';
import {LoadingButton} from '@mui/lab';

//API IMPORTS

export const EditTimeUsedModal = ({
                                      fetchData,
                                      setTimeUsedModal,
                                      companyId,
                                      timeUsed
                                  }) => {

    const [newTimeUsed, setNewTimeUsed] = useState(timeUsed);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useState(() => {
        setNewTimeUsed(timeUsed)
    }, [companyId])

    const updateTimeUsed = async () => {
        setIsLoading(true)
        try {
            await API.post('api2e29c07a', `/admin/companies/${companyId}/time_used`, {
                body: {
                    company_id: companyId,
                    time_used: newTimeUsed, // time in minutes
                },
            });
            fetchData()
            setTimeUsedModal(false)
            setShowErrorMessage(false)
        } catch {
            setShowErrorMessage(true)
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            overflow={'hidden'}
            maxHeight={'90vh'}
            sx={{
                overflowY: 'scroll'
            }}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon/>}
                    onClick={() => setTimeUsedModal(false)}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Stack
                    width={1}
                    spacing={'.8rem'}>
                    <Typography variant={'s3'}>
                        Time Used
                    </Typography>
                    <FormControl variant='outlined'>
                        <OutlinedInput
                            value={newTimeUsed}
                            onChange={(event) => {
                                setNewTimeUsed(event.target.value);
                            }}
                            id='outlined-number'
                            type='number'
                            inputLabelProps={{
                                shrink: true,
                            }}
                            endAdornment={<InputAdornment position='end'><Typography variant={'s3'}>Minutes</Typography></InputAdornment>}
                        />
                    </FormControl>
                </Stack>
                {showErrorMessage && <Stack><Typography variant={'s3'} sx={{color: 'red'}}>An error occurred while updating your data</Typography> </Stack>}
                <Stack spacing={'.8rem'} width={1}>
                    <Stack
                        width={1}
                        spacing={'1.6rem'}
                        direction={{xs: 'column-reverse', md: 'row'}}>
                        <Button
                            fullWidth
                            variant={'secondaryMain'}
                            onClick={() => setTimeUsedModal(false)}>
                            Cancel
                        </Button>
                        <LoadingButton
                            fullWidth
                            variant={'primaryMain'}
                            onClick={updateTimeUsed}
                            loading={isLoading}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}
