//REACT IMPORTS
import { Box, Button, Stack, Typography } from "@mui/material";
import { API } from "aws-amplify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";

//API IMPORTS
import { POST_DELETE_CLIENT_API } from "../../const";

export const DeleteClientModal = ({modalHandler, clientId, companyId, setPastPerformancesList}) => {

    const deleteHandler = async () => {
        await API.post('pyBackend', POST_DELETE_CLIENT_API, {
            body: {
                company_id: companyId,
                client_id: clientId
            }
        })
        modalHandler();
    }

    return(
        <Box
            width={1}
            maxWidth={'66rem'}
            bgcolor={'#FFF'}
            borderRadius={'2rem'}
            gap={'1.6rem'}
            padding={'2.4rem 2.4rem 4.8rem'}>
            <Box width={1} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant={'textButton'}
                    endIcon={<CloseIcon />}
                    onClick={() => modalHandler()}>
                    Close
                </Button>
            </Box>
            <Box
                width={1}
                display={'flex'}
                flexDirection={'column'}
                gap={'4.8rem'}
                alignItems={'center'}
                px={{xs: 0, md: '2.4rem'}}>
                <Typography textAlign={'center'} sx={{ typography: {xs: 'h3', md: 'h1'} }}>
                    Are you sure you want to delete this Past Performance?
                </Typography>
                <Stack
                    width={1}
                    spacing={'1.6rem'}
                    direction={{xs: 'column-reverse', md: 'row'}}>
                    <Button
                        fullWidth
                        variant={'secondaryMain'}
                        onClick={() => modalHandler()}>
                        Cancel
                    </Button>
                    <Button
                        fullWidth
                        variant={'errorButton'}
                        onClick={deleteHandler}>
                        Delete
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
