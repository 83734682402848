import {ArrowBackIosNewRounded, ArrowForwardIosRounded} from '@mui/icons-material';
import {Box, Button, CircularProgress, Divider, IconButton, Pagination, Stack, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ShreddedDocItem} from './components/ShreddedDocItem';
import {API} from 'aws-amplify';
import {useUserStore} from '../../store/userStore';
import {GET_ALL_PROPOSALS_API} from '../../const';

export const MyShreddedDocuments = () => {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [shreddedDocsList, setShreddedDocsList] = useState([]);
    const [proposals, setProposals] = useState([]);

    const company = useUserStore(state => state.company);

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const docsPerPage = 10;
    const totalPages = Math.ceil(shreddedDocsList.length / docsPerPage);

    const nextButton = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
    const prevButton = () => currentPage > 1 && setCurrentPage(currentPage - 1);


    const fetchInfo = async () => {
        setLoading(true)
        let proposals = await API.get('pyBackend', `${GET_ALL_PROPOSALS_API}${company.company_id}`, {});
        setProposals(proposals);
        const documents = await API.get('pyBackend', `/shred/get-all-shreded-docs?company_id=${company.company_id}`);
        setShreddedDocsList(documents);
        setLoading(false)
    };

    const getProposal = (proposalID) => {
        return proposals.find(proposal => proposal.proposal_id === proposalID);
    };

    useEffect(() => {
        fetchInfo();
    }, [company]);

    return (
        <Box
            width={1}
            maxWidth={'127rem'}
            mt={{xs: 0, md: '-4rem'}}
            mb={totalPages > 1 ? 0 : '7.2rem'}
            px={{xs: '1.6rem', md: '4.8rem'}}
            display={'flex'}
            flexDirection={'column'}
            gap={'4.8rem'}>
            <Typography
                color={'common.black'}
                textAlign={{xs: 'center', md: 'left'}}
                variant={'h3'}>
                My Shredded Documents
            </Typography>
            <Button
                variant={'primaryThin'}
                onClick={() => navigate('/users/new-proposal')}
                sx={{
                    display: {xs: 'block', md: 'none'}
                }}>
                Create proposal
            </Button>
            <Box
                width={1}>
                <Stack
                    display={{xs: 'none', md: 'flex'}}
                    width={1}
                    height={'4.8rem'}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Box
                        width={'25%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Typography
                            textAlign={'center'}
                            color={'common.black'}
                            variant={'s2'}>
                            Name
                        </Typography>
                    </Box>
                    <Box
                        width={'15%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            textAlign={'left'}
                            color={'common.black'}
                            variant={'s2'}>
                            Status
                        </Typography>
                    </Box>
                    <Box
                        width={'60%'}
                        height={1}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}/>
                </Stack>
                <Divider flexItem sx={{display: {xs: 'none', md: 'flex'}}}/>
                {isLoading && (<Box width={1} display={'flex'} justifyContent={'center'}><CircularProgress/></Box>)};

                <Box
                    width={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'2.4rem'}
                    py={{xs: 0, md: '2.4rem'}}>
                    {shreddedDocsList.length &&
                        shreddedDocsList.slice((currentPage - 1) * docsPerPage, (currentPage - 1) * docsPerPage + docsPerPage)
                            .map((doc, index) => {
                                return <ShreddedDocItem key={index} proposalName={doc.proposal_name}
                                                        documentId={doc.document_id}
                                                        documentStatus={doc.shreded_status}
                                                        proposal={getProposal(doc.proposal_id)}/>;
                            })}
                </Box>
            </Box>

            <Box
                width={1}
                display={totalPages > 1 ? 'flex' : 'none'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'1.2rem'}
                p={'1.6rem'}
                mb={'5rem'}>
                <IconButton
                    disabled={currentPage === 1 ? true : false}
                    onClick={prevButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === 1 ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === 1 ? 'grey.subtle' : 'primary.main'
                    }}>
                    <ArrowBackIosNewRounded/>
                </IconButton>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'small'}
                    sx={{display: {xs: 'flex', md: 'none'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    shape={'rounded'}
                    size={'large'}
                    sx={{display: {xs: 'none', md: 'flex'}}}
                    onChange={(event, value) => setCurrentPage(value)}
                    hideNextButton
                    hidePrevButton/>
                <IconButton
                    disabled={currentPage === totalPages ? true : false}
                    onClick={nextButton}
                    sx={{
                        width: '3.6rem',
                        height: '3.6rem',
                        color: currentPage === totalPages ? 'grey.subtle' : 'primary.main',
                        border: '.1rem solid',
                        borderColor: currentPage === totalPages ? 'grey.subtle' : 'primary.main'
                    }}>
                    <ArrowForwardIosRounded/>
                </IconButton>
            </Box>
        </Box>
    );
};
