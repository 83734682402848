import { Box, Stack, Typography } from "@mui/material"

export const ConfirmationCard = ({sectionTitle, contentList, setStep, step}) => {
    return(
        <Box
            width={1}
            border={'.1rem solid'}
            borderColor={'grey.subtle'}
            borderRadius={'1.5rem'}
            p={'2.4rem'}>

            <Stack
                direction={'row'}
                justifyContent={'space-between'}>
                <Typography variant={'body'}>
                    {sectionTitle ? sectionTitle : 'Section Title'}
                </Typography>
                <Typography
                    variant={'s3'}
                    bgcolor={'primary.main'}
                    borderRadius={'.5rem'}
                    padding={'.4rem 1rem'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setStep(step)}>
                    EDIT
                </Typography>
            </Stack>

            <Box
                width={1}
                mt={'3.2rem'}
                display={'flex'}
                flexDirection={'column'}
                gap={'2.4rem'}>
                {contentList && contentList.map((item, index) => (
                    <Stack
                        key={index}
                        width={1}>
                        <Typography
                            color={'grey.main'}
                            variant={'bodySmall'}>
                            {item.title || item.client_name}
                        </Typography>
                        <Typography variant={'bodySmall'}>
                            {item.subtitle || item.contract_name}
                        </Typography>
                    </Stack>
                ))}
            </Box>

        </Box>
    )
}