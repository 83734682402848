import {useEffect, useState} from 'react';
import {Amplify, Hub, Logger} from 'aws-amplify';

import awsExports from '../aws-exports';
import {useUserStore} from '../store/userStore';
import SpinningLoader from '../components/helpers/SpinningLoader';

const logger = new Logger('My-Logger');

const PageLayout = ({children}) => {
    const setOauthError = useUserStore((state) => state.setOauthError);
    const setGroup = useUserStore((state) => state.setGroup);
    const setCompany = useUserStore((state) => state.setCompany);
    const setUser = useUserStore((state) => state.setUser);
    const setProduct = useUserStore((state) => state.setProduct);
    const setCompanyImage = useUserStore((state) => state.setCompanyImage);
    const setLastUsername = useUserStore((state) => state.setLastUsername);
    const setLoggedIn = useUserStore((state) => state.setLoggedIn);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const listener = (data) => {
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signOut':
                    setGroup(undefined)
                    setLastUsername(null)
                    setLoggedIn(false)
                    setCompany(undefined)
                    setCompanyImage('/placeholder.png')
                    setUser(undefined)
                    setProduct(undefined)
                    setOauthError(undefined)
                    localStorage.clear();
                    break;
                case 'signIn':
                    break;
                case 'signIn_failure':
                    if (data.payload.data.message.indexOf('Already+found+an+entry+for+username') > -1) {
                        setOauthError('Please try again. If the problem persists, please contact support.');
                    } else {
                        setOauthError(data.payload.message);
                    }
                    logger.error('user sign in failed');
                    break;
            }
        };

        const hubListener = Hub.listen('auth', listener);

        Amplify.configure({
            ...awsExports,
            aws_cloud_logic_custom: [...awsExports.aws_cloud_logic_custom, {
                name: 'pyBackend',
                endpoint: process.env.REACT_APP_SERVER_URL,
                region: 'us-east-1'
            },
                {
                    name: 'pyBackendRegenerate',
                    endpoint: process.env.REACT_APP_REGENERATE_URL,
                    region: 'us-east-1',
                    service: 'lambda'
                },
                {
                    name: 'generateClientDescription',
                    endpoint: process.env.REACT_APP_GENERATE_CLIENT_DESCRIPTION_URL,
                    region: 'us-east-1',
                    service: 'lambda'
                },
                {
                    name: 'regenerateClientDescription',
                    endpoint: process.env.REACT_APP_REGENERATE_CLIENT_DESCRIPTION_URL,
                    region: 'us-east-1',
                    service: 'lambda'
                }
            ],
            oauth: {
                ...awsExports.oauth,
                redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN_URL,
                redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT_URL,
            }
        });

        setLoading(false);
        return hubListener;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <SpinningLoader/>;
    }

    return children;
};

export default PageLayout;
