import { DeleteOutline } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { PerformanceObjetivesCard } from "../../../components/cards/PerformanceObjetivesCard";

export const CreateProposalStepTwo = ({allTasks, setAllTasks, clientList, capabilityList}) => {

    const deleteItem = (index) => {
        setAllTasks(allTasks.filter((task, i) => i !== index))
    }

    return(
        <Box
            width={1}
            px={{xs: '2.4rem', md: 0}}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Stack
                direction={'column'}
                textAlign={'center'}
                mb={{xs: '3.2rem', md: '6rem'}}>
                <Typography
                    color={'common.black'}
                    textAlign={'center'}
                    sx={{
                        typography: {xs: 'h3', md: 'h1'}
                    }}>
                    Task/Performance Objectives
                </Typography>
            </Stack>

            <Box
                width={1}
                mt={'4.8rem'}
                display={'flex'}
                flexDirection={'column'}
                gap={'2.4rem'}>
                {allTasks && allTasks.map((task, index) => (
                    <Stack
                        width={1}
                        position={'relative'}
                        spacing={'1.2rem'}
                        borderRadius={'.8rem'}
                        bgcolor={'grey.grey1'}
                        border={'.1rem solid'}
                        borderColor={'grey.subtle'}
                        key={index}
                        p={'2.4rem'}
                        sx={{
                            transition: 'all ease .5s',
                            '&:hover': {
                                '& .delete-button': {
                                    opacity: 1
                                }
                            }
                        }}>
                        <Stack
                            width={1}
                            spacing={'.8rem'}>
                            <Typography color={'common.black'} variant={'h5'} textTransform={'capitalize'}>{task.task_name}</Typography>
                            <Typography
                                color={'grey.main'}
                                variant={'body'}
                                sx={{
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2
                                }}>{task.task_description}</Typography>
                        </Stack>
                        {task.client_id !== "-1" && <Divider flexItem />}
                        {clientList && clientList.map((client, index) => {
                            return((task.client_id !== "-1" && (task.client_id === client.client_id)) &&
                                <Stack
                                    key={index}
                                    width={1}
                                    spacing={'.8rem'}>
                                    <Typography color={'common.black'} variant={'h5'} textTransform={'capitalize'}>{`${client.client_name} - ${client.client_industry}`}</Typography>
                                    <Typography
                                        color={'grey.main'}
                                        variant={'body'}
                                        sx={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2
                                        }}>{task.client_description}</Typography>
                                </Stack>
                                )
                        })}
                        <IconButton
                            className={'delete-button'}
                            onClick={() => deleteItem(index)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: '1.2rem',
                                opacity: 0
                            }}>
                            <DeleteOutline fontSize={'large'} />
                        </IconButton>
                    </Stack>
                ))}
            </Box>

            <Stack
                width={1}
                my={'2.4rem'}
                spacing={'2.4rem'}>
                <Typography
                    color={'common.black'}
                    variant={'h5'}>
                    Performance & Task Objectives
                </Typography>
                <PerformanceObjetivesCard
                    allTasks={allTasks}
                    setAllTasks={setAllTasks}
                    clientList={clientList}
                    capabilityList={capabilityList} />
            </Stack>
        </Box>
    )
}