import { Autocomplete, Box, Popper, Stack, TextField, Typography } from '@mui/material';
import { FieldHelper } from '../../../components/helpers/FieldHelper';

import { TagsInput } from '../../../components/input/TagsInput';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const StepTwo = ({
                          naicsCodes,
                          companyPrimaryNaicsCode,
                          setCompanyPrimaryNaicsCode,
                          companySecondaryNaicsCode,
                          setCompanySecondaryNaicsCode
                        }) => {

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      fontSize: '1.6rem',
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0
      }
    }
  });

  return (
    <Box
      width={1}
      maxWidth={'50rem'}
      px={{ xs: '2.4rem', md: 0 }}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Typography color={'common.black'} textAlign={'center'} sx={{ typography: { xs: 'h3', md: 'h1' } }}>
        Tell us about your NAICS Code
      </Typography>

      <Stack
        width={1}
        mt={'2.4rem'}
        direction={'column'}
        spacing={'1.2rem'}
        justifyContent={'start'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}>
            Primary NAICS Code <Typography color={'common.error'} variant={'s3'}>*</Typography>
          </Typography>
          <FieldHelper
            suggestion={'Primary NAICS you do business under'} />
        </Stack>
        <Autocomplete
          options={naicsCodes}
          getOptionLabel={(option) => option}
          value={companyPrimaryNaicsCode}
          PopperComponent={StyledPopper}
          onChange={(event, newValue) => setCompanyPrimaryNaicsCode(newValue)}
          renderInput={(params) => (
            <TextField variant={'outlined'} fullWidth {...params} />
          )}
        />
      </Stack>

      <Stack
        width={1}
        mt={'2.4rem'}
        direction={'column'}
        spacing={'1.2rem'}
        justifyContent={'start'}>
        <Stack width={1} direction={'row'} spacing={'.8rem'} alignItems={'flex-start'}>
          <Typography color={'common.black'} variant={'s3'}> Secondary NAICS Code </Typography>
          <FieldHelper
            suggestion={'Secondary NAICS you do business under'} />
        </Stack>

        <Autocomplete
          multiple
          options={naicsCodes}
          getOptionLabel={(option) => option}
          value={companySecondaryNaicsCode}
          PopperComponent={StyledPopper}
          sx={{ '& .MuiAutocomplete-tag': { fontSize: '1.6rem' } }}
          onChange={(event, newValue) => {
            if (newValue.length > 5) {
              return;
            }
            setCompanySecondaryNaicsCode(newValue)
          }}
          renderInput={(params) => (
            <TextField variant={'outlined'} fullWidth multiline {...params} />
          )}
        />
        <Typography color={'grey.grey3'} variant={'s4'}>
          {'Max 5 codes'}
        </Typography>
      </Stack>
    </Box>
  );
};
